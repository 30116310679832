<template>
    <div>
        <p><a :href="'/en/media/?sha1=' + result">Search with {{ result }}</a><hr></p>
    </div>
</template>

<script>
export default {
  props: {
    result: {
      type: String
    }
  }
}
</script>
