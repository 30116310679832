<template>
  <h1 class="site-index__heading">
    <span class="u-fontsize-sm">
      {{ pgettext('Chat timeline heading', 'Chat timeline from') }}
    </span>
    <b class="u-mt-xs u-display-block u-break">
      <a
        class="link--search"
        :href="`${messageSearchUrl}?filters=site_name:${siteName}`"
      >
        {{ siteName }}
      </a>
      <note-buttons
        :noteId="siteId"
        :noteName="siteName"
        :noteItem="'chat site'"
        :noteSiteId="siteId"
        :noteSiteName="siteName"
        :hasNotes="Boolean(parseInt(hasChatSiteNotes))"
        :indexType="'chat'"
        :watchlistMessage="{
          'url':siteUrl,
          'site_watchlist': inWatchlist,
          '_source': {
            'site_id': siteId,
            'site_name': siteName,
          }
        }"
        :siteLink="getSiteLink(siteName)"
      />
    </b>
    <notes-legend />
  </h1>
</template>

<script>
import NoteButtons from '../notes/NoteButtons'
import NotesLegend from '../notes/NotesLegend'

export default {
  name: 'ChatContextSiteHeader',
  components: {
    NoteButtons,
    NotesLegend,
  },
  props: {
    inWatchlist: {
      type: Boolean,
      required: true
    },
    messageSearchUrl: {
      type: String,
      required: true
    },
    siteName: {
      type: String,
      required: true
    },
    siteId: {
      type: String,
      required: true
    },
    siteUrl: {
      type: String,
      default: ''
    },
    hasChatSiteNotes: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      note: '',
      noteType: '',
      noteCategory: '',
      all_chat_messages: [],
      limit: 10,
      loading: false,
      timeframe: '1209600',
      siteWatchlist:  ''
    }
  },
  methods: {
    getSiteLink(siteName) {
      return new URL('/en/message-search/chat-sites/' + siteName + '/', document.baseURI).href
    },
  },
}
</script>

<style scoped>

.site-index__heading .note-buttons {
  position: absolute;
  font-weight: normal;
}

</style>
