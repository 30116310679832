<template>
    <div v-if="file_type === 'csv' && batch_search === false">
      <result-area-for-csv/>
    </div>
    <div v-else>
      <section id="batch-info" v-if="batch_search">
        <h1 class="h2 u-weight-bold u-text-accent">Results</h1>
        <section id="batch-info-partial" v-if="batch_partial_fail.length">
          <p>We are unable to process the following file(s):</p>
          <li v-for="item in this.batch_partial_fail" :key="item"> <b>{{item.filename}}</b> - {{item.reason_for_fail}}</li>
          <br>
        </section>
        <p>Media has been uploaded successfully. The search is being performed and the results will be sent to {{ user_email }}.</p>
        <p>If you encounter any issues please <a href="../contact/">contact us</a></p>
      </section>

      <section id="sha-info" v-else>
        <h1 v-if="no_results" class="h2 u-weight-bold u-text-accent">No Matches Found</h1>
        <h1 v-if="show_header" class="h2 u-weight-bold u-text-accent">Results</h1>
        <h3 v-if="sighted_images.length > 0">Images with sightings</h3>
        <p v-if="current_filter === 'sightings' && sighted_images.length === 0 && has_hashes">No images with sightings found</p>
        <result-item-sightings
            v-for="simage in sighted_images"
            :result="simage"
            :key="simage.sha1"
            :forumUrl="forum_url"
          />

        <h3 v-if="unsighted_images.length > 0">Images without sightings</h3>
        <p v-if="current_filter === 'hashonly' && unsighted_images.length === 0 && has_hashes">No images without sightings found</p>
        <result-item :items="unsighted_images" @add-data-watchlist="addThisToWatchlist"/>
        <div v-if="sighted_images.length === 0 && has_hashes">
          <h5 style="margin-bottom: 2px">Would you like us to do a more in-depth search?</h5>
          <p>We may be able to identify other results through another search.</p>
          <in-depth-search-request-popup :search_type="search_type" :search_hash="search_hash"/>
        </div>
      </section>
    </div>
</template>

<script>
import ResultItemSightings from './ResultItemSightings'
import ResultItem from './ResultItem'
import ResultAreaForCsv from './ResultAreaForCsv'
import AddMediaLabel from "../watchlist/AddMediaLabel";

import InDepthSearchRequestPopup from './InDepthSearchRequestPopup'

export default {
  name: 'ImageSearchResultArea',
  emits: ['add-data-watchlist'],
  components: {
    AddMediaLabel,
    ResultItem,
    ResultItemSightings,
    ResultAreaForCsv,
    InDepthSearchRequestPopup
  },
  props: {
    results_sighted_images: {
      type: Array
    },
    results_unsighted_images: {
      type: Array
    },
    results_forum_sightings: {
      type: Array
    },
    search_hash: {
      type: String
    },
    search_type: {
      type: String
    },
    forum_url: {
        type: String,
        required: true
    },
    current_filter: {
      type: String
    },
    batch_search: {
        type: Boolean,
        required: true
    },
    user_email: {
        type: String
    },
    has_hashes: {
      type: Boolean
    },
    batch_partial_fail: {
      type: Array
    },
    action: {
      type: String
    },
    pdna_val: {
      type: String
    },
    pdna_in_watchlist: {
      type: Boolean
    },
    pdna_label: {
      type: String
    },
    file_type: {
      type: String,
    },
  },

  data(){
    return {
      sighted_images: [],
      unsighted_images: [],
      forum_sightings: [],
    }
  },
  computed: {
    show_header: function() {
      if (this.has_hashes) {
        if (this.sighted_images.length > 0 || this.unsighted_images.length > 0) {
          return true
        } else if (this.current_filter === 'sightings' && this.sighted_images.length === 0) {
          return true
        } else if (this.current_filter === 'hashonly' && this.unsighted_images.length === 0) {
          return true
        } else {
          return false
        }
      }
    },
    no_results: function() {
      if (
        this.sighted_images.length === 0 &&
        this.unsighted_images.length === 0 &&
        this.current_filter === 'all' &&
        this.has_hashes
      ) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    results_sighted_images(value) {
      this.sighted_images = value
    },
    results_unsighted_images(value) {
      this.unsighted_images = value
    },
    results_forum_sightings(value) {
      this.forum_sightings = value
    }
  },

  methods: {
    addThisToWatchlist(args) {
      this.$emit('add-data-watchlist', args)
    },
    shortenLabel(label) {
      if (label.length > 50) {
        return label.slice(0, 50) + '...'
      } else {
        return label
      }
    }
  }
}

</script>
