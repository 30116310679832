<template>
  <details class="u-px-md media-details" :class="{ 'u-pb-sm': mediaItemOpen }">
    <summary @click="mediaItemOpen = !mediaItemOpen"><span class="media-details-title">{{ shortenPDNA() }}</span> <span class="watchlist-see-details">{{ seeDetails }}</span>
      <span class="photo-dna-badge" v-if="is_pdna">PhotoDNA</span>
      <span class="new-sighting" v-if="newSighting"><img src="/static/images/icons/new.svg">New sighting</span>
      <confirm-remove-popup
          @remove-item="$emit('remove-item', entry)"
        />
    </summary>
    <title-popup
      @edit-label="edit_label"
      :label="label"
    />
    <div class="full-photoDNA" v-if="is_pdna"><strong>Full PhotoDNA:</strong> {{ entry }}</div>
    <div v-if="(noLists && label!=='' && !is_pdna)"><strong>SHA1:</strong> {{ entry }}</div>

    <div v-if="noLists" class="no-sightings">No new sightings</div>
    <div v-if="noLists && is_pdna">
      <a :href="'/en/media/?photodna=' + entry">All Sightings</a>
    </div>
    <div v-else-if="noLists && !is_pdna">
      <a :href="'/en/media/?sha1=' + entry">All Sightings</a>
    </div>

    <div v-if="entry === dataEntry">
      <div v-for="(simage, index) in sightingsList" :key="index">
        <sightings-item-details
          :result="simage"
          :forum_url="''"
          :media_item="entry"
        />
      </div>
      <no-sightings-items
          :items="nosightingsList"
        />
    </div>

  </details>
</template>

<script>
import SightingsItemDetails from './SightingsItemDetails'
import NoSightingsItems from './NoSightingsItems'
import TitlePopup from "./TitlePopup";
import ConfirmRemovePopup from "./ConfirmRemovePopup";
import Cookies from 'js-cookie'
import axios from 'axios'

export default {
  name: 'MediaDetails',
  emits: ['update-label', 'remove-item'],
  components: {
    SightingsItemDetails,
    NoSightingsItems,
    TitlePopup,
    ConfirmRemovePopup,
  },
  props: {
    is_pdna: {
      type: Boolean
    },
    entry: {
      type: String
    },
    is_new: {
      type: Boolean
    },
    label: {
      type: String
    },
    latest_sighting_date: {
      type: String
    },
  },
  data() {
    return {
      sightingsList: null,
      nosightingsList: null,
      dataEntry: null,
      noLists: true,
      seeDetails: 'See Details',
      mediaItemOpen: false,
      newSighting: this.is_new
    }
  },
  methods: {
    edit_label(newLabel) {
      if (newLabel !== "cancel") {
        var media_data = {
        hash_val: this.entry,
        new_label: newLabel
      }

      const csrftoken = Cookies.get('csrftoken')
      axios.post('/en/watchlist/change_label/', media_data, {'headers': {'X-CSRFToken': csrftoken}}).then(
            resp => {console.log("got a response! %o", resp)}
          ).catch(
            err => {console.log("something went wrong! %o", resp)}
          )
      this.$emit('update-label', {hash_val: this.entry, new_label: newLabel})
      }
    },

    shortenPDNA() {
      if (this.label !== '') {
        return this.label
      } else {
        if (this.is_pdna === true) return this.entry.slice(0, 10) + '...' + this.entry.slice(-10)
        else return this.entry
      }
    },
    get_details(val) {
      // get details of specific media entry in the watchlist, send the hash to a views' method that returns the
      // sightings and no sightings list for that hash
      var media_data = {hash_val: val}
      const csrftoken = Cookies.get('csrftoken')
      axios.post('/en/watchlist/item_details/', media_data, {'headers': {'X-CSRFToken': csrftoken}}).then(
          r => {
            this.sightingsList = r.data.sightings_list,
            this.nosightingsList = r.data.nosightings_list,
            this.dataEntry = r.data.data_entry
            this.noLists = this.sightingsList.length === 0 && this.nosightingsList.length === 0
          }
      ).catch(
          err => {console.log("something went wrong! %o", resp)}
      )
    },
  },
  watch: {
    mediaItemOpen(newValue) {
      if (newValue === true) {
        this.seeDetails = 'Close'
      } else {
        this.seeDetails = 'See Details'
        this.get_details(this.entry)
      }

      if (this.newSighting === true) {
        var media_data = {hash_val: this.entry}
        const csrftoken = Cookies.get('csrftoken')
        axios.post('/en/watchlist/mark_sighting_as_seen/', media_data, {'headers': {'X-CSRFToken': csrftoken}}).then(
            resp => {console.log("got a response! %o", resp)}
          ).catch(
            err => {console.log("something went wrong! %o", resp)}
          )
        this.newSighting = false
      }

    }
  },
  mounted: function() {
    this.get_details(this.entry)
  }
}
</script>

<style>
.media-details {
  border: 2px solid #9e9b9a;
  border-radius: 6px;
  background-color: #e6e5e5;
  position: relative;
}
.media-details-title {
  max-width: 55%;
  display: inline-block;
}
.full-photoDNA {
  overflow-wrap: break-word;
  padding: 10px 20px;
  margin: 5px 0px;
  border: 2px solid #ccc;
  font-size: 14px;
  background: #fff;
}
.media-details .result-box {
  border-width: 2px;
  margin-top: 5px;
}
.no-lists::marker {
  content: '';
}
.photo-dna-badge {
  font-size: 11px;
  background: rgb(53, 50, 49);
  padding: 0 3px;
  border-radius: 6px;
  border: 2px solid #000;
  position: absolute;
  top: 4px;
  right: 30px;
  color: #fff;
}
.watchlist-see-details {
  font-size:14px;
  color: #c22c3f;
  text-decoration: underline;
  font-weight: bold;
  position: relative;
  top: -2px;
  padding-left: 35px;
}
.new-sighting {
  background: #c22c3f;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding: 1px 5px 1px 0;
  border-radius: 6px;
  position: absolute;
  top: 3px;
  right: 100px;
}
.new-sighting img {
  width: 20px;
  height: 20px;
  position: relative;
  top: -1px;
}
.watchlist-page-btn-remove {
  width: 23px;
  height: 23px;
  position: absolute;
  top: 3px;
  right: 3px;
  background: url(/static/images/icons/stop-watching.svg);
  background-size: 23px 23px;
}
.watchlist-page-btn-remove:hover {
  background: url(/static/images/icons/stop-watching-hover.svg);
  background-size: 23px 23px;
}
@media screen and (max-width:1250px) {
  .media-details-title {
    word-break: break-word;
    max-width: 75%;
  }
}
</style>
