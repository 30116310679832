<template>
  <div>
    <p><a :href="'/en/message-search/?filters=&posted_date=desc&query=' + result[1] + '&limit=25&start=0'">Search {{ result[0] }} with {{ result[1] }}</a></p>
    <hr>
  </div>
</template>

<script>
export default {
  props: {
    result: {
      type: Array,
      default: () => []
    }
  }
}
</script>
