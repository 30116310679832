<template>
  <div class="wallets-container">
    <div class="currency-types-select">
      <button
        v-for="(currencyType, index) in currencyTypes"
        :key="index"
        class="currency-type-btn"
        :class="{'selected': currencyType === selectedCurrencyType}"
        @click.prevent="setFilter({type: 'currency_type', term: currencyType})"
      >
        {{ currencyType }}
      </button>
    </div>
    <div class="wallet-filters-container">
      <div class="wallet-search">
        <input
          v-model="query"
          type="text"
          placeholder="Search ..."
          @keyup.enter="searchWallets"
        >
        <button
          class="btn btn--primary"
          @click.prevent="searchWallets"
        >
          <img
            class="search-icon"
            src="/static/images/icons/icon-search-white.svg"
            width="24"
            alt="Search"
          >
        </button>
      </div>
    </div>
    <message-search-page-nav
      :state="searchState"
      :initCurrentPage="start"
      :totalPages="totalPages"
      @page-nav="handlePageNav"
      @select-page="updateCurrentPage"
    />
    <div class="wallet-headers">
      <div class="wallet-col">
        Address
      </div>
      <div class="wallet-col">
        Messages
      </div>
      <div class="wallet-col">
        <a
          href="#"
          :class="{'asc': (orderBy === 'currency_type' && orderDirection === 'asc'), 'desc': (orderBy === 'currency_type' && orderDirection === 'desc')}"
          @click.prevent="setOrderBy('currency_type')"
        >
          Currency Type
        </a>
      </div>
      <div class="wallet-col">
        <a
          href="#"
          :class="{'asc': (orderBy === 'number_of_transactions' && orderDirection === 'asc'), 'desc': (orderBy === 'number_of_transactions' && orderDirection === 'desc')}"
          @click.prevent="setOrderBy('number_of_transactions')"
        >
          Transactions
        </a>
      </div>
      <div class="wallet-col">
        <a
          href="#"
          :class="{'asc': (orderBy === 'last_updated' && orderDirection === 'asc'), 'desc': (orderBy === 'last_updated' && orderDirection === 'desc')}"
          @click.prevent="setOrderBy('last_updated')"
        >
          Last Updated
        </a>
      </div>
    </div>
    <div
      v-if="searchState === 'retrieved' && walletData.length > 0"
      id="wallets"
    >
      <wallet
        v-for="wallet in walletData"
        :key="wallet.address"
        :wallet="wallet"
        @copy-address="copyToClipboard"
      />
    </div>
    <div
      v-else-if="searchState === 'retrieved' && walletData.length === 0"
      class="alert alert--info u-text-centre"
    >
      <p>No wallets found.</p>
    </div>
    <div
      v-else-if="searchState === 'loading'"
      class="alert alert--info u-text-centre"
    >
      {{ gettext('Loading ...') }}
    </div>
    <div
      v-else-if="searchState === 'e'"
      class="alert alert--error u-text-centre"
    >
      {{ gettext('Error') }}
    </div>
    <message-search-page-nav
      :state="searchState"
      :initCurrentPage="start"
      :totalPages="totalPages"
      @page-nav="handlePageNav"
      @select-page="updateCurrentPage"
    />
    <div
      v-if="copySuccess"
      ref="copySuccessMsg"
      class="copy-success"
      :style="{ top: copySuccessY + 'px', left: copySuccessX + 'px' }"
    >
      <p>Copied to clipboard</p>
    </div>
    <section>
      <back-to-top-button />
    </section>
  </div>
</template>

<script>
import axios from 'axios'
import MessageSearchPageNav from '../message_search/MessageSearchPageNav.vue'
import BackToTopButton from '../message_search/BackToTopButton.vue'
import Wallet from './Wallet.vue'

export default {
  name: 'CryptoWallets',
  components: {
    Wallet,
    MessageSearchPageNav,
    BackToTopButton
  },
  props: {
    initLimit: {
      type: Number,
      required: true
    },
    initStart: {
      type: Number,
      required: true
    },
    initQuery: {
      type: String,
      required: true
    },
    initFilters: {
      type: String,
      required: true
    },
    initCurrencyTypes: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      walletData: null,
      debouncedUpdate: null,
      selectedCurrencyType: 'All Wallets',
      searchState: 'loading',
      searchParams: null,
      totalPages: 0,
      hasNextPage: false,
      hasPrevPage: false,
      refreshStart: 1,
      refreshLimit: 25,
      refreshQuery: '',
      refreshFilters: '',
      refreshedOrderBy: '',
      copySuccess: false,
      copySuccessX: null,
      copySuccessY: null,
      orderBy: 'last_updated',
      orderDirection: 'desc',
      params: {},
      replaceState: true
    }
  },
  computed: {
    start: { // aka 'page'
      get() {
        return this.$store.state.start
      },
      set(newStart) {
        this.$store.commit('start', newStart)
        return newStart
      },
    },
    limit: { // aka 'paginateBy'
      get() {
        return this.$store.state.limit
      },
      set(newLimit) {
        this.$store.commit('limit', newLimit)
        return newLimit
      }
    },
    query: { // aka 'searchInput'
      get() {
        return this.$store.state.query
      },
      set(newQuery) {
        this.$store.commit('query', newQuery)
        return newQuery
      },
    },
    filters: {
      get() {
        return this.$store.state.filters
      },
      set(filters) {
        this.$store.commit('filters', filters)
        return filters
      }
    },
    currencyTypes() {
      return JSON.parse(this.initCurrencyTypes)
    },
    dataUpdate() {
      const {
        limit,
        start,
        filters,
        orderBy,
        orderDirection
      } = this
      return {
        limit,
        start,
        filters,
        orderBy,
        orderDirection
      }
    },
  },
  watch: {
    dataUpdate: {
      handler: function(value) {
        this.searchState = 'loading'
        this.$nextTick(() => this.updateSearch())
        // console.log('dataupdate')
      },
      deep: true
    },
  },
  created() {
    if (this.initFilters) {
      let initFiltersArray = this.handleInitialFilters(this.initFilters)
      if (initFiltersArray.length > 0) {
        this.setFilter(initFiltersArray[0])
      }
    }
    this.query = this.initQuery
    this.limit = this.initLimit
    this.start = this.initStart
  },
  mounted() {
    window.addEventListener('popstate', (e) => {
      if (e.state) {
        this.refreshStart = e.state.start
        this.refreshLimit = e.state.limit
        this.refreshQuery = e.state.query
        this.refreshFilters = e.state.filters
        this.refreshedOrderBy = e.state.order_by
        if (e.state.filters && e.state.filters.split(':')[1] !== this.selectedCurrencyType) {
          this.selectedCurrencyType = e.state.filters.split(':')[1]
        } else if (e.state.filters === '') {
          this.selectedCurrencyType = 'All Wallets'
          this.refreshFilters = ''
        }
      }
      this.refreshStore({
        start: this.refreshStart,
        limit: this.refreshLimit,
        query: this.query,
        filters: this.handleInitialFilters(this.refreshFilters),
        orderBy: this.refreshedOrderBy.split(':')[0],
        orderDirection: this.refreshedOrderBy.split(':')[1]
      })
      this.replaceState = true
    })
  },
  methods: {
    handleInitialFilters(filters) {
      if (filters) {
        let splitFilters = filters.split(',')
        return splitFilters.reduce((acc, term) => {
          // Set split's limit to 2 so it stops after the first colon
          let splits = term.split(':', 2)
          // Current filter type must be in filterTypes,
          // and current filter term must have a value of some kind
          if (splits[0] !== '' && splits[1] !== '') {
            splits[1] = decodeURIComponent(splits[1])
            acc.push({
              type: splits[0],
              term: splits[1],
            })
          }
          return acc
        }, [])
      }
    },
    setFilter(filter) {
      if (filter.type && filter.term) {
        // the only existing filters is the currency type
        // so it's ok to empty the filters and then set it
        this.$store.commit('emptyFilters')
        this.$store.commit('addFilters', [filter])
        this.selectedCurrencyType = filter.term
      }
    },
    setOrderBy(orderBy) {
      if (orderBy === this.orderBy) {
        this.orderDirection = (this.orderDirection === 'desc') ? 'asc' : 'desc'
      } else {
        this.orderBy = orderBy
        this.orderDirection = 'desc'
      }
    },
    refreshStore(updatedParams) {
      this.$store.commit('emptyFilters')
      if (updatedParams.filters) {
        this.$store.commit('addFilters', updatedParams.filters)
      }
      if (updatedParams.limit) {
        this.limit = updatedParams.limit
      }
      if (updatedParams.start) {
        this.start = updatedParams.start
      }
      if (updatedParams.query) {
        this.query = updatedParams.query
      }
      if (updatedParams.orderBy) {
        this.orderBy = updatedParams.orderBy
      }
      if (updatedParams.orderDirection) {
        this.orderDirection = updatedParams.orderDirection
      }
    },
    updateSearch(searchURL = null) {
      let url = searchURL || new URL(window.location)
      let currentQuery = url.searchParams.get('query')
      let urlFilters = url.searchParams.get('filters')
      let urlOrderBy = url.searchParams.get('order_by')

      // reset start if query or filters have changed
      if (urlFilters) {
        let filtersArray = this.handleInitialFilters(urlFilters)
        if (JSON.stringify(filtersArray) !== JSON.stringify(this.filters)) {
          this.start = 1
        }
      }
      if (currentQuery !== this.query) {
        this.start = 1
      }
      if (urlOrderBy) {
        let orderBySplits = urlOrderBy.split(':', 2)
        let currentOrderBy = orderBySplits[0]
        let currenctOrderDirection = orderBySplits[1]
        if (currentOrderBy !== this.orderBy || currenctOrderDirection !== this.orderDirection) {
          this.start = 1
        }
      }

      this.params = this.getUpdatedParams()

      this.saveURLHistory()
      this.fetchWallets()
    },
    fetchWallets() {
      // perform a search for wallets
      this.searchState = 'loading'
      axios.get('/en/crypto_wallets/', {params: this.params})
        .then(response => {
          this.walletData = response.data.wallet_data
          this.searchState = 'retrieved'
          this.totalPages = response.data.num_pages
          this.hasPrevPage = response.data.has_prev
          this.hasNextPage = response.data.has_next
        })
        .catch(e => {
          console.error(e)
          this.searchState = 'error'
        })
    },
    searchWallets() {
      this.searchState = 'loading'
      this.updateSearch()
    },
    saveURLHistory() {
      // go through the current params, add them to the url,
      // and then save the new url to the browser history
      let url = new URL(window.location)
      for (let key in this.params) {
        if (this.params.hasOwnProperty(key)) {
          url.searchParams.set(key, this.params[key])
        }
      }
      if (this.replaceState === false) {
        window.history.pushState(this.params, 'Wallet search', url)
      } else {
        window.history.replaceState(this.params, 'Wallet search', url)
        this.replaceState = false
      }
    },
    getUpdatedParams(currentParams) {
      // retrieve the new search params and combine them with the existing ones
      let encodedFiltersString = ''
      if (this.filters && this.filters.length) {
        encodedFiltersString = this.filters.reduce((filterString, term, index) => {
          let termString = encodeURIComponent(term.term)
          if (filterString.length > 0) {
            return `${filterString},${term.type}:${termString}`
          } else {
            return `${filterString}${term.type}:${termString}`
          }
        }, '')
      }

      return {
        start: this.start,
        limit: this.limit,
        query: this.query,
        filters: encodedFiltersString,
        order_by: `${this.orderBy}:${this.orderDirection}`,
        ...currentParams
      }
    },
    updateCurrentPage(page) {
      this.start = parseInt(page)
    },
    handlePageNav(navType) {
      if (navType === 'prev') { this.getPrevPage() }
      if (navType === 'next') { this.getNextPage() }
    },
    getPrevPage() {
      if (this.hasPrevPage) {
        this.scrollToTopControls()
        this.start -= 1
      }
    },
    getNextPage() {
      if (this.hasNextPage) {
        this.scrollToTopControls()
        this.start += 1
      }
    },
    scrollToTopControls() {
      let elBounds = document.getElementById('wallets').getBoundingClientRect()

      if (elBounds.top < 0) {
        window.scrollTo({
          top: window.pageYOffset + elBounds.top,
          left: 0,
          behavior: 'smooth'
        })
      }
    },
    copyToClipboard(e, value) {
      this.copySuccess = true
      this.copySuccessX = e.clientX + 20
      this.copySuccessY = e.clientY + 20
      navigator.clipboard.writeText(value)
      this.$nextTick(() => {
        this.$refs.copySuccessMsg.addEventListener('animationend', () => {
          this.copySuccess = false
        })
      })
    },
  },
}
</script>

<style>
.currency-type-btn {
  background: #d5d2d1;
  border-radius: 6px;
  padding: 5px;
  color: #474443;
  margin-right: 10px;
  text-transform: capitalize;
}
.currency-type-btn.selected {
  background: #474443;
  color: #fff;
}
.currency-types-select {
  margin-bottom: 20px;
}
.wallet-filters-container {
  margin-bottom: 30px;
}
.wallet-search {
  display: flex;
  flex-direction: row;
  width: 500px;
}
.search-icon {
  display: block;
}
.wallet-headers {
  font-weight: bold;
  text-transform: capitalize;
  background: #fff;
  border-bottom: 2px solid #f5f5f4;

  position: sticky;
  top: 0;
  z-index: 10;
}
.wallet-item,
.wallet-headers {
  display: flex;
  flex-direction: row;
}
.wallet-col {
  padding: 10px;
  flex-basis: 50%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
div.wallet-col:nth-child(1) {
  max-width: 400px;
}
div.wallet-col:nth-child(2) {
  max-width: 400px;
}
div.wallet-col:nth-child(3) {
  max-width: 175px;
}
div.wallet-col:nth-child(4) {
  max-width: 175px;
}
div.wallet-col:nth-child(5) {
  max-width: 175px;
}
div.wallet-item:nth-child(2n) {
  background: #f5f5f4;
}
.copy-success {
  position: fixed;
  background: #e2ce66;
  border: 1px solid #000;
  padding: 10px 20px;
  animation-name: fadeOutSuccess;
  animation-duration: 1s;
  animation-delay: 3s;
}
.copy-success p {
  margin-bottom: 0;
}

@keyframes fadeOutSuccess {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.desc:after {
  content: '▼';
  display: inline-block;
  color: black;
  text-decoration: none;
}
.asc:after {
  content: '▲';
  display: inline-block;
  color: black;
  text-decoration: none;
}
</style>
