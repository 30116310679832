<template>
  <ul v-if="items.length > 0" class="list--simple result-grid sightings-grid">
    <li v-for="usimage in items" :key="usimage.sha1" class="result-grid__item sha1">
      <p>SHA1: <span class="sha1-number">{{ usimage.sha1 }}</span></p>
      <add-media-label :hash="usimage.sha1" :is_sha1="true" :in_watchlist="usimage.in_watchlist" :sha1_label="usimage.label" @add-data-watchlist="addThisToWatchlist"/>
    </li>
  </ul>
</template>

<script>
import AddMediaLabel from "../watchlist/AddMediaLabel";

export default {
  name: 'ResultItem',
  components: {
    AddMediaLabel
  },
  emits: ['add-data-watchlist'],
  props: {
    items: {
      type: Array
    },
  },
  methods: {
    addThisToWatchlist(args) {
      this.$emit('add-data-watchlist', args)
    }
  }
}
</script>

<style>
.result-grid__item.sha1 {
  width: 48%;
  padding-bottom: 20px;
}
.sha1-number {
  width: 88%;
  display: inline-block;
  border: 1px solid;
  padding: 5px;
  background: #fff;
}
</style>
