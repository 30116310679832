<template>
  <h1 class="site-index__heading">
    <span class="u-fontsize-sm">
      Forums on
    </span>
    <b class="u-mt-xs u-display-block u-break">
      <a
        class="link--search"
        :href="`${messageSearchUrl}?filters=site_name:${siteName}`"
      >
        {{ siteName }}
      </a>
      <note-buttons
        :watchlistMessage="{
          'url':siteUrl,
          'site': {
            'site_name': siteName,
            'site_id': siteId
          },
          'site_name': siteName,
          'site_watchlist': inWatchlist,
        }"
        :noteId="siteId"
        :noteName="siteName"
        :noteItem="'site'"
        :noteSiteId="siteId"
        :noteSiteName="siteName"
        :hasNotes="Boolean(parseInt(hasSiteNotes))"
        :indexType="'forum'"
        :siteLink="getSiteLink(siteName)"
      />
    </b>
    <notes-legend />
  </h1>
</template>

<script>
import NoteButtons from '../notes/NoteButtons'
import NotesLegend from '../notes/NotesLegend'

export default {
  name: 'ForumContextSiteHeader',
  components: {

    NoteButtons,
    NotesLegend,
  },
  props: {
    inWatchlist: {
      type: Boolean,
      required: true
    },
    messageSearchUrl: {
      type: String,
      default: ''
    },
    siteName: {
      type: String,
      default: ''
    },
    siteId: {
      type: String,
      default: ''
    },
    hasSiteNotes: {
      type: String,
      default: ''
    },
    siteUrl: {
      type: String,
      default: ''
    },
    message: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      note: '',
      noteType: '',
      noteCategory: '',
      forumsData: [],
      siteWatchlist: ''
    }
  },
  methods: {
    getSiteLink(siteName) {
      return new URL('/en/message-search/forum-sites/' + siteName + '/', document.baseURI).href
    },
  },
}
</script>

<style scoped>

.site-index__heading .note-buttons {
  position: absolute;
  font-weight: normal;
}

</style>
