// Default store values
// https://tkacz.pro/vue-vuex-reset-store-state-to-default-initial/
export default {
  query: '',
  filters: [],
  limit: 25,
  start: 0,
  sortPreference: 'relevance',
  watchlist: {},
  site_watchlist: {},
  forum_watchlist: {},
  topic_watchlist: {},
}
