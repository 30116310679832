<template>
  <div>
    <h2>Supported file format for CSV Media File:</h2>
    <ul>
      <li>Comma-separated values (CSV)</li>
      <li>Use column headers "pdna" or "photodna"</li>
      <li>Use column headers "hash" or "sha1"</li>
      <li>Use column header "md5"</li>
      <li>A file can have a hash column, pdna column, md5 column, or a variation of them</li>
      <li>If a row has hash entry, use next row for pdna entry with nothing in hash entry</li>
      <li>Examples:
        <ul>
          <li>
            <table style="font-size: medium; width: 50%">
              <tr>
                <th>sha1</th>
                <th>photodna</th>
                <th>md5</th>
              </tr>
              <tr>
                <td>QKNH6...CBCBF</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td>03B10...C2103</td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>55127...50F2A</td>
              </tr>
              <tr>
                <td>ECKJ7...6GH5J</td>
                <td></td>
                <td></td>
              </tr>
            </table>
          </li>
          <li>
            <table style="font-size: medium; width: 50%">
              <tr>
                <th>sha1</th>
              </tr>
              <tr>
                <td>QKNH6...CBCBF</td>
              </tr>
              <tr>
                <td>YKN46...08GE0</td>
              </tr>
              <tr>
                <td>WA4H6...C0FTF</td>
              </tr>
              <tr>
                <td>ECKJ7...6GH5J</td>
              </tr>
            </table>
          </li>
          <li>
            <table style="font-size: medium; width: 50%">
              <tr>
                <th>photodna</th>
              </tr>
              <tr>
                <td>102A3...A3001</td>
              </tr>
              <tr>
                <td>B2133...07TE0</td>
              </tr>
              <tr>
                <td>W4G96...0F00A</td>
              </tr>
              <tr>
                <td>A8HJ5...6110Q</td>
              </tr>
            </table>
          </li>
          <li>
            <table style="font-size: medium; width: 50%">
              <tr>
                <th>md5</th>
              </tr>
              <tr>
                <td>A2133...662D8</td>
              </tr>
              <tr>
                <td>55127...50F2A</td>
              </tr>
              <tr>
                <td>73B29...280DB</td>
              </tr>
              <tr>
                <td>3D1B4...E3B78</td>
              </tr>
            </table>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'ResultAreaForCsv'
}
</script>
