<template>
  <div>
    <div class="form-group">
      <label
        for="search_photodna"
        class="u-weight-bold"
      >
        Search by PhotoDNA
      </label>
      <div class="inline-btn-group">
        <input
          id="pdna"
          v-model="pdna"
          type="search"
          name="pdna"
        >
        <button
          :disabled="togglePDNASearch()"
          class="btn btn--primary btn--inline"
          @click="searchPDNA"
        >
          <img
            :src="searchicon"
            width="24"
            alt="Search"
            style="display:block"
          >
        </button>
      </div>
      <p
        v-if="pdna_error != ''"
        class="form-error"
      >
        {{ pdna_error }}
      </p>
    </div>
    <div class="watchlist-pdna-add" v-if="pdna_val">
      <add-media-label
        :hash="pdna_val"
        :is_sha1="false"
        :in_watchlist="pdna_in_watchlist"
        :pdna_label="pdna_label"
        @add-data-watchlist="addThisToWatchlist"
      />
    </div>
    <div class="form-group">
      <label
        for="search_sha1"
        class="u-weight-bold"
      >
        Search by SHA1
      </label>
      <div class="inline-btn-group">
        <input
          id="sha1"
          v-model="sha1"
          type="search"
          name="sha1"
        >
        <button
          :disabled="toggleSHA1Search()"
          class="btn btn--primary btn--inline"
          @click="searchSHA1"
        >
          <img
            :src="searchicon"
            width="24"
            alt="Search"
            style="display:block"
          >
        </button>
      </div>
      <p
        v-if="sha1_error !== ''"
        class="form-error"
      >
        {{ sha1_error }}
      </p>
    </div>
    <div class="form-group">
      <label
        for="search_sha1"
        class="u-weight-bold"
      >
        Search by MD5
      </label>
      <div class="inline-btn-group">
        <input
          id="md5"
          v-model="md5"
          type="search"
          name="md5"
        >
        <button
          :disabled="toggleMD5Search()"
          class="btn btn--primary btn--inline"
          @click="searchMD5"
        >
          <img
            :src="searchicon"
            width="24"
            alt="Search"
            style="display:block"
          >
        </button>
      </div>
      <p
        v-if="md5_error !== ''"
        class="form-error"
      >
        {{ md5_error }}
      </p>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import AddMediaLabel from "./watchlist/AddMediaLabel";

export default {
  name: 'ImageSearchForm',
  emits: ['add-data-watchlist'],
  components: {
    AddMediaLabel,
  },
  props: {
    action: {
      type: String,
      required: true,
    },
    searchicon: {
      type: String,
      required: true
    },
    photoDNA: {
      type: String
    },
    sha1qs: {
      type: String
    },
    md5qs: {
      type: String
    },
    pdna_val: {
      type: String
    },
    pdna_in_watchlist: {
      type: Boolean
    },
    pdna_label: {
      type: String
    },
  },
  data() {
    return {
      sha1: '',
      pdna: '',
      md5: '',
      errors: {},
      showSuccess: false,
      pdna_error: '',
      sha1_error: '',
      md5_error: '',
      pdna_button_disabled: true,
      sha1_button_disabled: true,
      md5_button_disabled: true,
      serverURL: '/en/media/get_images',
      addToWatchlist: false
    }
  },
  watch: {
    photoDNA: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal && newVal !== '') {
          this.pdna = newVal
          this.sha1 = ''
          this.md5 = ''
          this.checkQS('pdna', this.pdna.trim())
        }
      }
    },
    sha1qs: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal && newVal !== '') {
          this.sha1 = newVal
          this.pdna = ''
          this.md5 = ''
          this.checkQS('sha1', this.sha1.trim())
        }
      }
    },
    md5qs: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal && newVal !== '') {
          this.md5 = newVal
          this.pdna = ''
          this.sha1 = ''
          this.checkQS('md5', this.md5.trim())
        }
      }
    },
  },
  created() {
    // Checking if the initial load of the page includes a hash, from a bookmark perhaps?
    let uri = window.location.search.substring(1)
    let params = new URLSearchParams(uri)
    let photoDNA = params.get('photodna')
    let sha1qs = params.get('sha1')
    let md5qs = params.get('md5')
    if (photoDNA) {
      this.pdna = photoDNA
      this.doSearch('pdna', this.pdna.trim())
    } else if (sha1qs) {
      this.sha1 = sha1qs
      this.doSearch('sha1', this.sha1.trim())
    } else if (md5qs) {
      this.md5 = md5qs
      this.doSearch('md5', this.md5.trim())
    }
  },
  mounted() {
    //We want to be able to call the doSearch function from other components (namely MediaSearchSightingsTable for now).
    // This isn't very intuitive in Vue. Have to make kind of an inbetween function (here named "callDoSearch") and let
    // the root component know about it. Other components can then "emit" to the root, telling it that they want to run
    // callDoSearch.
    this.$root.$on('callDoSearch', (params) => {
      this.doSearch(params.searchType, params.hash, params.expanded);
    })
  },
  methods: {
    addThisToWatchlist(args) {
      this.$emit('add-data-watchlist', args)
    },
    togglePDNASearch() {
      if (this.pdna !== '') {
        return false
      } else {
        return true
      }
    },
    toggleSHA1Search() {
      if (this.sha1 !== '') {
        return false
      } else {
        return true
      }
    },
    toggleMD5Search() {
      return this.md5 === ''
    },
    searchPDNA() {
      this.sha1 = ''
      this.md5 = ''
      this.$emit('manualPDNASearchStart')
      this.checkQS('pdna', this.pdna.trim())
      if (this.addToWatchlist) this.add_to_watchlist()
    },
    searchSHA1() {
      this.pdna = ''
      this.md5 = ''
      this.$emit('manualSHA1SearchStart')
      this.checkQS('sha1', this.sha1.trim())
    },
    searchMD5() {
      this.pdna = ''
      this.sha1 = ''
      this.$emit('manualMD5SearchStart')
      this.checkQS('md5', this.md5.trim())
    },
    checkQS(searchType, hash) {
      let uri = window.location.search.substring(1)
      let params = new URLSearchParams(uri)
      let qs_hash = ''
      let qs_var_name = ''
      switch (searchType) {
        case 'pdna': {
          qs_var_name = 'photodna'
          break
        }
        case 'sha1': {
          qs_var_name = 'sha1'
          break
        }
        case 'md5': {
          qs_var_name = 'md5'
          break
        }
        default: {}
      }
      qs_hash = params.get(qs_var_name)
      if (qs_hash !== hash) {
        // I want to make sure I only have either pdna or sha1 as params in the
        // querystring -- for now.  If we add paging or anything else into the QS
        // this will need to be more precise than this "scorched earth" method.
        params.forEach(function(value, key) {
          params.delete(key)
        })
        params.set(qs_var_name, hash)
        let new_qs = '?' + params.toString()
        let current_url_path = window.location.pathname
        let new_url = current_url_path + new_qs
        // so we're simulating a page refresh since we're using vue and we don't actually want to do that
        // as it's super heavy.  Just trying to preserve the ability to bookmark/copypasta URLs with hashes included
        // to make searches somewhat portable.  Maybe a v3 of this will be a full on vue app...
        var page_title = document.getElementsByTagName('title')[0].innerHTML
        window.history.pushState('object or string', page_title, new_url)
        this.doSearch(searchType, hash)
      }
    },
    doSearch(searchType, hash, expanded) {
      this.$emit('startSearch')
      let payload = {
        searchType: searchType,
        hash: hash,
      }

      //If the expanded parameter is set to true, then we want to get all sightings, not just 5, so add to the payload.
      if (expanded) {
        payload.expanded = hash
      }

      axios.get(this.serverURL, {params: payload})
        .then(r => {
          var searchType = payload.searchType
          var err_msg = ''
          switch (r.data.status) {
            case 'success': {
              this.results = r.data.images
              this.$emit('childToParent', this.results)
              this.pdna_button_disabled = true
              this.sha1_button_disabled = true
              this.md5_button_disabled = true
              this.sha1_error = ''
              this.pdna_error = ''
              this.md5_error = ''
              break
            }
            case 'invalid_input': {
              err_msg = 'Invalid value. Please check and re-enter.'
              this.$emit('invalidInput', err_msg)
              break
            }
            case 'hash_not_found': {
              err_msg = 'This hash does not have any sightings in C3P Database.'
              this.$emit('noResults', err_msg)
              break
            }
            default: {
              console.log('Unknown status encountered')
            }
          }
          this.addToWatchlist = false
          if (err_msg !== '') {
            if (searchType === 'pdna') {
              this.pdna_error = err_msg
              this.sha1_error = ''
              this.md5_error = ''
            } else if (searchType === 'sha1') {
              this.sha1_error = err_msg
              this.pdna_error = ''
              this.md5_error = ''
            } else if (searchType === 'md5') {
              this.sha1_error = ''
              this.pdna_error = ''
              this.md5_error = err_msg
            }
          }
        })
        .catch(e => {
          console.log('ERROR!!')
          console.log(e)
        })
    }
  }
}
</script>
