<template>
  <div>
    <div class="media-sha1s" :class="{'truncated-message-search': hideMedia}">
      <ul>
        <li v-for="sha1 in mediaSha1s">
          <a :href="mediaSearchLink(sha1)">
            <code>{{ sha1 }}</code>
          </a>
        </li>
      </ul>
    </div>
      <button class="note-item-btn" v-if="mediaSha1s.length > 5" @click="hideMedia = !hideMedia">{{ btnText }}</button>
  </div>
</template>

<script>
export default {
  name: "MessageSearchArchiveMedia",
  props: ["mediaSha1s", "mediaSearchUrl"],
  data() {
    return {
      hideMedia: true,
    }
  },
  methods: {
    mediaSearchLink(imgHash) {
      return `${this.mediaSearchUrl}?sha1=${imgHash}`
    }
  },
  computed: {
    btnText: function() {
      return (hideMedia) ? "[Show More +]" : "[Show Less -]";
    }
  }
}
</script>

<style>
.media-sha1s {
  overflow: auto;
  max-height: 13rem;
  scrollbar-width: thin;
}
.media-sha1s ul li {
  list-style: none;
}
.truncated-message-search {
  overflow: hidden;
  max-height: 6.25rem;
}
</style>