import { render, staticRenderFns } from "./UserDetailPosts.vue?vue&type=template&id=3793f86c&scoped=true&"
import script from "./UserDetailPosts.vue?vue&type=script&lang=js&"
export * from "./UserDetailPosts.vue?vue&type=script&lang=js&"
import style0 from "./UserDetailPosts.vue?vue&type=style&index=0&id=3793f86c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3793f86c",
  null
  
)

export default component.exports