<template>
    <div>
        <p><a :href="file_path + result" download>Sample Image {{ fileindex + 1 }}</a><hr></p>
    </div>
</template>
<script>
export default {
  name: 'FileItem',
  props: {
    result: {
      type: String
    },
    file_path: {
      type: String
    },
    fileindex: {
      type: Number
    }
  }
}
</script>
