<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <div data-app>
    <v-dialog
      v-model="loading"
      transition="fade-transition"
      fullscreen
    >
      <v-container
        fluid
        fill-height
        style="background-color: rgba(255, 255, 255, 0.75);"
      >
        <v-row>
          <v-col cols="12">
            <v-row
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="#a42334"
                size="70"
                width="10"
              />
            </v-row>
            <v-row
              align="center"
              justify="center"
            >
              <div class="u-mt-md loading-text">
                <strong>Loading ...</strong>
              </div>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
    <ul
      v-if="siteData.length > 0"
      class="site-index"
    >
      <notes-legend />
      <li
        v-for="(site, index) in siteData"
        :key="index"
        class="site-index__row"
      >
        <p class="site-index__name">
          <a :href="siteUrl(site.site_name)">{{ site.site_name }}</a>
          <note-buttons
            :noteId="site.site_id"
            :noteName="site.site_name"
            :noteLinked="site.uuid"
            :noteItem="siteType"
            :noteSiteId="site.site_id"
            :noteSiteName="site.site_name"
            :hasNotes="site.has_site_notes"
            :indexType="indexType"
            :siteLink="getSiteLink(site.site_name, siteType)"
            :watchlistMessage="site"
            @refresh="refreshed"
          />
        </p>
        <div class="site-index__icon">
          <!-- Brian suggested and approved the removal of the url check for chats -->
          <!-- Under the assumption that all sites that show up here are onion -->
          <img
            v-if="(indexType === 'chat') || (site.url && site.url.includes('.onion'))"
            width="50"
            src="/images/logos/onion.png"
            title="This is a site found on the Tor network"
            alt=".onion site"
          >
        </div>
        <div class="site-index__info">
          <i class="u-fontsize-sm">
            Last message:
          </i>
          <br>
          {{ formatDate(site.posted_date) }}
        </div>
      </li>
    </ul>
    <p
      v-if="!loading && siteData.length == 0"
      class="alert alert--info"
    >
      No site data available
    </p>
  </div>
</template>

<script>
import NoteButtons from './notes/NoteButtons'
import NotesLegend from './notes/NotesLegend'
import axios from 'axios'
import Cookies from 'js-cookie'
export default {
  name: 'SiteIndex',
  components: {
    NoteButtons,
    NotesLegend
  },
  props: {
    siteType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      siteData: [],
      site_data_url: '',
      indexType: '',
      siteLink: null,
      loading: false
    }
  },
  mounted() {
    const csrftoken = Cookies.get('csrftoken')
    if (this.siteType === 'site') {
      this.site_data_url = '/en/indices/site_index/forum/'
    } else {
      this.site_data_url = '/en/indices/site_index/chat/'
    }
    this.loading = true
    axios.get(this.site_data_url, {'headers': {'X-CSRFToken': csrftoken}})
      .then(response => {
        this.siteData = response.data
        this.loading = false
      })
      .catch(error => {
        console.log(error)
        this.siteData = []
        this.loading = false
      })

    if (this.siteType.toLowerCase() === 'chat site') {
      this.indexType = 'chat'
    } else {
      this.indexType = 'forum'
    }
  },
  methods: {
    getSiteLink(siteName, siteType) {
      if (siteType.toLowerCase() === 'chat site') {
        return new URL('/en/message-search/chat-sites/' + siteName + '/', document.baseURI).href
      } else {
        return new URL('/en/message-search/forum-sites/' + siteName + '/', document.baseURI).href
      }
    },
    siteUrl(url) {
      return window.location.href + url
    },
    refreshed() {
      const csrftoken = Cookies.get('csrftoken')
      let siteTypeObj = {'site_type': this.siteType}
      if (this.siteType === 'Site') {
        this.site_data_url = '/en/message-search/refresh_site_index/forum/'
      } else {
        this.site_data_url = '/en/message-search/refresh_site_index/chat/'
      }
      axios.get('/en/message-search/refresh_site_index/', {params: siteTypeObj}, {'headers': {'X-CSRFToken': csrftoken}}).then(response => {
        this.siteData = response.data.data
      })
    },
    formatDate(date) {
      const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      }
      return new Date(date * 1000).toLocaleString('en-US', options)
    }
  }
}
</script>

<style scoped>
.v-progress-circular,
.loading-text {
  margin: auto;
}
</style>
