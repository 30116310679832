<template>
  <div>
    <note-buttons
      :noteId="mediaData.sha1"
      :noteName="null"
      :noteLinked="messageSource._id"
      :noteItem="'media'"
      :hasNotes="mediaData.has_media_notes"
      :indexType="indexType"
      :userNoteSiteName="messageSource._source.site_name"
      :siteLink="absMediaLink(mediaData.sha1)"
    />
    <dl class="dl-table dl-table--dense u-mb-xs">
      <div class="dl-table__row">
        <dt>Media SHA1:</dt>
        <dd>
          <a :href="mediaSearchLink(mediaData.sha1)">
            <code>{{ mediaData.sha1 }}</code>
          </a>
          <img
            v-if="mediaData.in_archive"
            class="archive-linked-icon"
            src="/static/images/icons/archive_linked.svg"
            :title="getArchiveLinkTitle()"
          />
        </dd>
      </div>
      <div
        v-if="mediaData.classification"
        class="dl-table__row"
      >
        <dt>Classification:</dt>
        <dd>{{ mediaData.classification }}</dd>
      </div>
      <div
        v-if="mediaData.series"
        class="dl-table__row"
      >
        <dt>Series:</dt>
        <dd v-if="mediaData.series.length > 0">
          {{ mediaData.series.join(", ") }}
        </dd>
        <dd v-else>
          No series
        </dd>
      </div>
      <div class="dl-table__row">
        <dt>Victim Identified:</dt>
        <dd class="u-tt-cap">
          {{ victimIdentified() }}
          <img
            width="12"
            :title="victimIdentifiedToolTip()"
            alt="info"
            class="info-tooltip"
            src="/static/images/icons/icon-attention.svg"
          >
        </dd>
      </div>
      <div v-if="mediaData.in_watchlist !== null && mediaData.label !== null">
        <add-media-label
          :hash="mediaData.sha1"
          :is_sha1="true"
          :in_watchlist="mediaData.in_watchlist"
          :sha1_label="mediaData.label"
          :pdna_label="''"
          @add-data-watchlist="add_data_watchlist"
        />
      </div><br/>
    </dl>
  </div>
</template>

<script>
import NoteButtons from '../notes/NoteButtons'
import AddMediaLabel from '../watchlist/AddMediaLabel'
import MessageSearchArchiveMedia from './MessageSearchArchiveMedia'
import Cookies from 'js-cookie'
import axios from 'axios'

export default {
  name: 'MessageResultMediaDetails',
  components: {
    NoteButtons,
    AddMediaLabel,
    MessageSearchArchiveMedia
  },
  props: {
    mediaData: {
      type: Object,
      required: true
    },
    mediaSearchUrl: {
      type: String,
      required: true
    },
    messageSource: {
      type: Object,
      default: null
    },
    indexType: {
      type: String,
      required: true
    },
    parentType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      note: '',
      noteType: '',
      noteCategory: '',
    }
  },
  methods: {
    absMediaLink(imgHash) {
      return new URL(this.mediaSearchUrl + '?sha1=' + imgHash, document.baseURI).href
    },
    mediaSearchLink(imgHash) {
      return `${this.mediaSearchUrl}?sha1=${imgHash}`
    },
    add_data_watchlist(args) {
      // add a media entry to the watchlist
      let labelInput = args['label']
      let dataVal = args['data_val']
      let mediaData = {}
      let isPdna

      if (dataVal != null) {
        if (dataVal.trim().length === 288) {
          isPdna = 'True'
          mediaData = {
            label: labelInput,
            pdna_key: dataVal
          }
        } else if (dataVal.trim().length === 32) {
          isPdna = 'False'
          mediaData = {
            label: labelInput,
            sha1_key: dataVal
          }
        }

        if (Object.keys(mediaData).length !== 0) {
          const csrftoken = Cookies.get('csrftoken')
          axios.post('/en/watchlist/add/', mediaData, {'headers': {'X-CSRFToken': csrftoken}}).then(
            resp => { console.log('got a response! %o', resp) }
          ).catch(
            err => { console.log('something went wrong! %o', err) }
          )
        }
        dataVal = ''
      }
    },
    getArchiveLinkTitle() {
      if (this.parentType === 'archive') {
        return 'Media is also found separately on message'
      } else {
        return 'Media is also found in archive on message'
      }
    },
    victimIdentified() {
      /**
       * Determines if the media has the victim identified.
       * @returns 'No information available' if the victim identification is null. Otherwise it returns True or False.
       */
      // attempt to use the 'victim_identified' variable first, but sometimes it doesn't exist
      if (this.mediaData.victim_identified !== undefined) {
        if (this.mediaData.victim_identified === null) {
          return 'No information available'
        } else {
          return this.mediaData.victim_identified
        }
      }

      // find the 'victim_identified'  from the matches in the media data
      if (this.mediaData.matches && this.mediaData.matches.length > 0) {
        if (this.mediaData.matches.some((match) => match.victim_identified === true)) {
          return true
        } else if (this.mediaData.matches.every((match) => match.victim_identified == null)) {
          return 'No information available'
        } else {
          return false
        }
      }
      return false // no matches means no victim was identified
    },
    victimIdentifiedToolTip() {
      let vi = this.victimIdentified()
      if (vi === 'No information available') {
        return 'C3P does not have any data indicating whether the victim(s) in the media has been identified.'
      } else if (vi === true) {
        return 'The media may include an identified victim(s), based on data supplied by law enforcement and/or another source.'
      } else {
        return 'The media may not include an identified victim(s), based on data supplied by law enforcement and/or another source.'
      }
    }
  }
}
</script>

<style>
.archive-details[open] > summary {
  margin-bottom: 0.5rem;
  padding-bottom: 0.25rem;
  border-bottom: 1px solid hsl(11, 3%, 90%);
}
.archive-details ul {
  margin: 0;
}
.details_hidden {
  overflow: hidden;
  max-height: 0;
}
.archive-linked-icon {
  width: 22px;
  height: auto;
}
</style>
