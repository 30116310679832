<template>
  <div class="note-contact-container">
    <div class="note-contact-form">
      <p>
        <label for="email">Your email:</label>
        <input :value="userEmail" disabled>
      </p>
      <p>
        <label>Send message to <strong>{{noteUserName}}</strong>:</label>
        <textarea :value="contactMessage" name="contactMessage" disabled></textarea>
      </p>
      <p>
        <button class="contact-send-btn" type="button" @click="contact_user(userId)">Send</button>
        <button class="contact-cancel-btn" @click="$emit('closeContact')">Cancel</button>
      </p>
    </div>
    <div v-if="emailSent" class="email-sent-message">
      <p>{{ responseMessage }}</p>
      <p>
        <button class="contact-close-btn" @click="$emit('closeContact')">Close</button>
      </p>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import Cookies from 'js-cookie'

export default {
  name: 'UserNoteContactForm',
  props: {
    userId: null,
    userEmail: null,
    noteUserName: null,
    indexType: null,  // chat or forum
    noteSiteName: null,
    itemType: null,  // note_on
    noteItemContent: null,  // if itemType is media, then item_id, otherwise item_name
    noteContent: null,  // original note
    siteLink: null,
    noteDisplayType: null,
  },

  data() {
    return {
      email: '',
      message: '',
      errors: {},
      showSuccess: false,
      indexTypeStr: this.indexType,
      contactMessage: '',
      emailSent: false,
      responseMessage: ''
    }
  },
  mounted() {  // to avoid duplication of same siteName and noteItemContent in message body
    if (this.indexType === 'chat') {  // for proper format of the message
      this.indexTypeStr = 'Chat'
    } else if (this.indexType === 'forum') {
      this.indexTypeStr = 'Forum'
    } else if (this.indexType === null) {
      this.indexTypeStr = ''
    }

    this.contactMessage = 'An Arthropod user (' + this.userEmail + ') is requesting contact with you regarding the public note you left on:\n\n'
    // note on site
    if (this.noteItemContent === this.noteSiteName) {
      this.contactMessage += this.indexTypeStr + ' Site: ' + this.noteSiteName

      // note on SHA1 media in media search
    } else if (this.noteSiteName === undefined || this.noteSiteName === null) {
      this.contactMessage += this.itemType + ': ' + this.noteItemContent

    } else {  // note on everything else
      this.contactMessage += this.indexTypeStr + ' Site: ' + this.noteSiteName + '\n' + this.itemType + ': ' + this.noteItemContent
    }

    if (this.siteLink !== null && this.siteLink !== undefined) {  // only add link if available
      this.contactMessage += ' ( ' + this.siteLink + ' )'
    }
    this.contactMessage += '\nNote: ' + this.noteContent  // actual note
  },

  methods: {
    contact_user(pk) {
      let note = {
        'pk': pk,
        'message': this.contactMessage,  // message is non-editable
        'note_display_type': this.noteDisplayType
      }
      const csrftoken = Cookies.get('csrftoken')
      axios
        .post('/en/message-search/notes/contact/', note, {'headers': {'X-CSRFToken': csrftoken}})
        .then((response) => {
          console.log(response.status, response.data.response_text)
          if (response.status === 200) {
            this.emailSent = true
            this.responseMessage = response.data.response_text
          }
        })
    }
  },
}
</script>

<style>
.note-contact-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.note-contact-form {
  position: absolute;
  top: 45px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  background: #fff;
  padding: 20px;
  border: 2px solid #cecbca;
  border-radius: 6px;
}
textarea[name="contactMessage"] {
  resize: none;
  height: 170px;
}
.contact-send-btn {
  background: #c22c3f;
  color: #fff;
  padding: 5px 8px;
  border-radius: 5px;
  margin-right: 5px;
}
.contact-send-btn:active {
  background: #a42334;
}
.contact-cancel-btn,
.contact-close-btn {
  background: #fff;
  color: #c22c3f;
  padding: 5px 8px;
  border: 1px solid #c22c3f;
  border-radius: 5px;
}
.email-sent-message {
  width: 200px;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border: 2px solid #cecbca;
  border-radius: 6px;
  padding: 10px;
}
.email-sent-message p {
  text-align: center;
  margin: 8px;
}
.email-sent-message p:first-child {
  text-transform: capitalize;
}
</style>
