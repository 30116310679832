<template>
  <span>
    <span v-if="showShortNote">{{ shortenNote(originalNote) }} <button class="note-item-btn" v-if="expandBtn" @click="expandNote">[Expand +]</button></span>
    <span v-else class="note-item-full-note">{{ originalNote }} <button class="note-item-btn" @click="hideNote">[Hide -]</button></span>
  </span>
</template>

<script>
export default {
  name: 'NoteItem',
  props: ['originalNote', 'noteLength'],
  data() {
    return {
      expandBtn: false,
      showShortNote: true
    }
  },
  watch: {
    originalNote(newVal, oldVal) {  // immediately update the note
      this.note = newVal
    }
  },
  methods: {
    shortenNote(note) {
      if (note.length >= this.noteLength) {
        this.expandBtn = true
        return note.substring(0, this.noteLength) + '...'
      } else {
        this.expandBtn = false
        return note
      }
    },
    expandNote() {
      this.showShortNote = false
    },
    hideNote() {
      this.showShortNote = true
    }
  }
}
</script>

<style>
.note-item-btn {
  font-size: 12px;
  color: #000091;
}
.note-item-full-note {
  white-space: pre-wrap;
}
</style>
