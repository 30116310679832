<template>
  <span>
    <button @click="showConfirm = true" type="button" class="watchlist-page-btn-remove" title="Stop Watching"></button>
      <div class="confirm-close" v-if="showConfirm">
        <p>Remove?</p>
        <p>
          <button class="confirm-close-yes" @click="showConfirm = false; $emit('remove-item')">Yes</button>
          <button class="confirm-close-no" @click="showConfirm = false">No</button>
        </p>
      </div>
  </span>
</template>

<script>

export default {
  emits: ['remove-item'],
  props: ['is_pdna', 'entry', 'org'],
  data() {
    return {
      showConfirm: false
    }
  },
  methods: {
  },
}
</script>

<style>
.contact-form p:first-child {
  margin-bottom: 20px;
}
.contact-form input:disabled {
  background: #eee;
}
.contact-form label {
  margin-bottom: 8px;
}
.contact-form textarea {
  width: 100%;
  height: 150px;
  resize: none;
}
@keyframes fadeText {
  0% { opacity: 1; }
  100% { opacity: 0; }
}
.confirm-close {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  border: 2px solid #9e9b9a;
  background: #fff;
  width: 300px;
  padding: 20px;
  box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.2);
}
.confirm-close p {
  text-align: center;
}
</style>
