<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <div data-app>
    <v-dialog
      v-model="loading"
      transition="fade-transition"
      fullscreen
    >
      <v-container
        fluid
        fill-height
        style="background-color: rgba(255, 255, 255, 0.75);"
      >
        <v-row>
          <v-col cols="12">
            <v-row
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="#a42334"
                size="70"
                width="10"
              />
            </v-row>
            <v-row
              align="center"
              justify="center"
            >
              <div class="u-mt-md loading-text">
                <strong>Loading ...</strong>
              </div>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
    <div v-if="totalPages">
      <div class="display-items">
        <div class="display-items-header">
          <div>
            {{
              filterType === 'username' ? 'Username' : (filterType === 'site' ? 'Site Name' : filterType === 'forum' ? 'Forum Name' : (filterType === 'topic' ? 'Topic Name' : 'Item Name'))
            }}
          </div>
          <div>
            <span v-if="filterType != 'site'">Site Name</span>
          </div>
          <div>
            <span>Latest Sighting</span>
          </div>
          <div />
          <div />
        </div>
        <div
          v-for="(item, index) in displayedItem"
          :key="index"
          class="display-item"
        >
          <div>
            <a :href="nameLink(item)"> {{ item.item_name }}</a>
            <img
              v-if="item.is_tor && filterType == 'site'"
              class="watchlist-tor"
              width="10"
              src="/images/logos/onion.png"
              title="This is a site found on the Tor network"
              alt=".onion site"
            >
          </div>
          <div>
            <a
              v-if="filterType != 'site'"
              :href="generateSiteLink(item)"
            >
              {{ item.site_name }}
            </a>
            <img
              v-if="item.is_tor && filterType != 'site'"
              class="watchlist-tor"
              width="10"
              src="/images/logos/onion.png"
              title="This is a site found on the Tor network"
              alt=".onion site"
            >
          </div>
          <div v-if="item.new_sighting_date">{{ item.new_sighting_date }}</div>
          <div v-else>
            ------
          </div>
          <div>
            <button
              class="btn btn-danger"
              @click="showRemoveConfirmation(item)"
            >
              Remove
            </button>
          </div>
          <div>
            <button
              :class="item.new_sightings>0 ? 'btn btn-data' : 'btn btn-danger'"
              @click="viewSightings(item)"
            >
              {{ 'New Posts (' +item.new_sightings+')' }}
            </button>
            <!--<div
                v-if="!item.showDetail"
                class="sightings-section-container"
                @click.self="viewSightings(item)"
              >
              <div class="sightings-section">
                <button
                  type="button"
                  class="close"
                  title="Close"
                  @click="viewSightings(item)"
                >
                  &#x2716;
                </button>
                <p class="sightings-info-header">
                  Sightings Data to be shown here for <strong>{{ item.item_name }}.</strong>
                </p>
              </div>
            </div>-->
          </div>
        </div>
      </div>
      <div
        v-if="showRemoveConfirmationModal"
        class="modal"
        tabindex="-1"
        role="dialog"
      >
        <div
          class="modal-dialog modal-dialog-centered"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">
                Remove Item
              </h5>
              <button
                type="button"
                class="close"
                @click="hideRemoveConfirmationModal "
              >
                <span aria-hidden="true">X</span>
              </button>
            </div>
            <div class="modal-body">
              <p>Are you sure you want to remove <strong>{{ currentItem.item_name }}</strong> from your watchlist?</p>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                @click="hideRemoveConfirmationModal"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-danger"
                @click="removeUserConfirmed"
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      </div>
      <message-search-page-nav
        :initCurrentPage="currentPage"
        :totalPages="totalPages"
        @page-nav="handlePageNav"
        @select-page="updateCurrentPage"
      />
    </div>
    <div v-else-if="!loading && !totalPages">
      <h2>No data to show</h2>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import {removeFromWatchlist} from '../message_search/store/actions'
import MessageSearchPageNav from '../message_search/MessageSearchPageNav.vue'

export default {
  name: 'UserWatchlistIndex',
  components: {MessageSearchPageNav},
  data() {
    return {
      perPageMax: 100,
      perPageMin: 1,
      watchlist: [],
      sighting_list: [],
      showEditModal: false,
      showRemoveConfirmationModal: false,
      currentItem: '',
      currentPage: 1,
      itemsPerPage: 5,
      filterType: null,
      loading: false
    }
  },
  computed: {
    displayedItem() {
      const startIdx = (this.currentPage - 1) * this.itemsPerPage
      const endIdx = startIdx + this.itemsPerPage
      return this.watchlist.slice(startIdx, endIdx)
    },
    totalPages() {
      return Math.ceil(this.watchlist.length / this.itemsPerPage)
    },
    activeFilter() {
      const urlParams = new URLSearchParams(window.location.search)
      return urlParams.get('watchlist_type') || 'username'
    },
  },
  created() {
    const activeFilterType = this.activeFilter
    this.filterType = this.activeFilter
    this.loading = true
    const apiUrl = `/en/watchlist/all/?watchlist_type=${activeFilterType}`
    axios
      .get(apiUrl)
      .then(response => {
        this.watchlist = response.data['data']
        this.watchlist = response.data['data'].map(item => ({...item, showDetail: true}))
        this.loading = false
      })
      .catch(error => {
        console.error('something went wrong! %o', error)
        this.loading = false
      })
  },
  methods: {
    viewSightings(item) {
      // item.showDetail = !item.showDetail
      // console.log(item)
      let params = {
        site_id: item.site_id,
        site_name: item.site_name,
        item_name: item.item_name
      }
      axios.get('/en/watchlist/sighting/read/', {params: params})
        .then(response => {
          console.log(response.data['data'])
        }).catch(e => {
          this.searchState = 'error'
          console.error(e)
        }).then(
          window.location.href = this.nameLink(item)
        )
    },
    nameLink(item) {
      if (item.type === 'username') {
        return new URL('/en/message-search/?filters=site_name:' + item.site_name + ',username:' + item.item_name, document.baseURI).href
      } else if (item.type === 'topic') {
        return new URL('/en/message-search/?filters=site_name:' + item.site_name + ',topic:' + item.item_name, document.baseURI).href
      } else if (item.type === 'forum') {
        return new URL('/en/message-search/forum-sites/' + item.site_name + '/' + item.item_name + '/', document.baseURI).href
      } else if (item.type === 'site') {
        return new URL('/en/message-search/?filters=site_name:' + item.site_name, document.baseURI).href
      }
    },
    generateSiteLink(item) {
      const siteName = item.site_name
      if (item.item_type === 'forum_posts') {
        return new URL('/en/message-search/forum-sites/' + siteName + '/', document.baseURI).href
      }
      if (item.item_type === 'chat_messages') {
        return new URL('/en/message-search/chat-sites/' + siteName + '/', document.baseURI).href
      }
    },
    handlePageNav(data) {
      if (data === 'prev') this.prevPage()
      if (data === 'next') this.nextPage()
    },

    updateCurrentPage(page) {
      this.$store.commit('start', (this.limit * parseInt(page)) - 1)
    },
    showRemoveConfirmation(item) {
      this.currentItem = item
      this.showRemoveConfirmationModal = true
    },
    async removeUserConfirmed() {
      const response = await removeFromWatchlist(this.$store, this.currentItem, this.currentItem.item_name,
        null, null, this.currentItem.site_name, null, this.currentItem.site_id,
        this.currentItem.type)
      if (response) {
        const index = this.watchlist.indexOf(this.currentItem)
        if (index !== -1) {
          this.watchlist.splice(index, 1)
        }
        this.hideRemoveConfirmationModal()
      }
    },
    hideRemoveConfirmationModal() {
      this.showRemoveConfirmationModal = false
      this.showRemoveConfirmationModal = false
      this.currentItem = null
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++
      }
    },
  },
}
</script>
<style scoped>
.modal {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.modal-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  min-width: 300px;
}

.modal-header .close {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.modal-footer {
  text-align: right;
  margin-top: 10px;
}

.modal-footer button {
  margin-left: 5px;
}

.show {
  display: block;
}
.sightings-section-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background: rgba(0,0,0,0.2);
}
.sightings-section {
  padding: 20px 30px;
  border: 2px solid #cecbca;
  border-radius: 6px;
  box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.2);
  position: fixed;
  transform: translateX(-50%);
  width: 60vw;
  top: 200px;
  left: 50%;
  z-index: 100;
  background: #fff;
  text-align: left;
  box-sizing: border-box;
  line-height: initial;
}
.sightings-section .close {
  position: absolute;
  top: 5px;
  right: 10px;
  transform: unset;
}
.sightings-info-header,
.sightings-info-item {
  border-bottom: 2px solid rgb(230, 229, 229);
  padding: 15px 10px;
  margin: 0;
}
.sightings-info-item {
  font-size: 0.8rem;
}
.display-items > div {
  display: flex;
  height: 55px;
  line-height: 55px;
}
.display-items-header {
  font-weight: bold;
  border-bottom: 2px solid rgb(230, 229, 229);
}
.display-item {
  border-bottom: 2px solid rgb(230, 229, 229);
}
.display-item button {
  transform: scale(0.8);
}
.display-items-header > div,
.display-item > div {
  width: 300px;
  flex-grow: 1;
}
.display-items-header > div:first-child,
.display-item > div:first-child {
  width: 400px;
}
.display-items-header > div:last-child,
.display-items-header > div:nth-last-child(2),
.display-item > div:last-child,
.display-item > div:nth-last-child(2) {
  text-align: center;
  width: 170px;
  max-width: 170px;
}
.v-progress-circular,
.loading-text {
  margin: auto;
}
.btn-data {
  background-color: #c22c3f;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;

}
.watchlist-tor {
  height: 25px;
  width: 25px;
  margin-left: 10px;
  vertical-align: middle;
}

</style>
