<template>
  <div class="watchlist-btn-container">
    <p
      v-if="lengthError"
      class="watchlist-btn-label-error"
    >
      The entered SHA1 or PhotoDNA is not of correct length
    </p>
    <div
      v-if="is_sha1"
      class="watchlist-btn-sha1"
    >
      <button
        v-if="!showLabel"
        :class="{added: addedToWatchlist}"
        :disabled="addedToWatchlist"
        class="watchlist-btn"
        @click="labelDialog"
      >
        {{ addedToWatchlist ? 'Added to Watchlist' : 'Add to Watchlist' }}
      </button>
      <div
        v-if="showLabel"
        :title="sha1Label"
      >
        <p v-if="sha1Label !== ''">
          <span class="u-weight-bold">In Watchlist:</span>
          <a :href="'/en/watchlist/'">
            <span :class="{watchlistBold:noSha1Label}">{{ shortenLabel(sha1Label, 50) }}</span>
          </a>
        </p>
        <p v-else>
          Item in Watchlist
        </p>
      </div>
    </div>
    <div
      v-else
      class="watchlist-btn-photodna"
    >
      <button
        v-if="!showLabel"
        :class="{added: addedToWatchlist}"
        :disabled="addedToWatchlist"
        class="pdna-add-btn"
        @click="labelDialog"
      >
        {{ addedToWatchlist ? 'Added PhotoDNA (' : 'Add PhotoDNA (' }}
        <span class="watchlistBold">{{ mainAddToWatchlistBtn(hash) }}</span>
        ) to Watchlist
      </button>
      <div
        v-if="showLabel"
        :title="pdnaLabel"
      >
        <p v-if="pdnaLabel !== ''">
          <span class="u-weight-bold">In Watchlist:</span>
          <a :href="'/en/watchlist/'">
            <span :class="{watchlistBold:noPdnaLabel}">{{ shortenLabel(pdnaLabel, 26) }}</span>
          </a>
        </p>
        <p v-else>
          Item in Watchlist
        </p>
      </div>
    </div>
    <div
      v-if="(showLabelDialog && !addedToWatchlist)"
      class="label-dialog"
    >
      <p>
        <label>Label:</label>
        <input
          ref="labelTextInput"
          v-model="labelText"
          type="text"
          name="labelMessage"
          size="50"
        >
        Note: Do not include victim information or media hashes in labels
      </p>
      <button
        type="button"
        class="label-dialog-cancel-btn"
        @click="showLabelDialog = false; labelText = ''"
      >
        Cancel
      </button>
      <div class="label-dialog-btns">
        <button
          type="button"
          class="label-dialog-nolabel-btn"
          @click="add_to_watchlist"
        >
          {{ labelText !== "" ? 'Add' : 'Add without Label' }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/require-prop-types, vue/prop-name-casing
  props: ['hash', 'is_sha1', 'in_watchlist', 'pdna_label', 'sha1_label'],
  emits: ['add-data-watchlist'],
  data() {
    return {
      addLabel: false,
      lengthError: false,
      showLabelDialog: false,
      labelText: '',
      sha1Label: this.sha1_label,
      pdnaLabel: this.pdna_label,
      addedToWatchlist: this.in_watchlist,
      showLabel: this.in_watchlist,
      noSha1Label: this.sha1Label !== '',
      noPdnaLabel: this.pdnaLabel !== '',
    }
  },

  methods: {
    mainAddToWatchlistBtn(hash) {
      return hash.slice(0, 10) + '...' + hash.slice(-10)
    },
    labelDialog() {
      console.log('click?')
      if ((this.hash.trim().length === 288 && this.is_sha1 === false) || (this.hash.trim().length === 32 && this.is_sha1 === true)) {
        this.showLabelDialog = true
        this.lengthError = false
        this.$nextTick(() => {
          this.$refs.labelTextInput.focus()
        })
      } else {
        this.lengthError = true
      }
    },
    add_to_watchlist() {
      if (this.is_sha1) {
        this.sha1Label = this.labelText
        this.noSha1Label = this.sha1Label !== ''
      } else {
        this.pdnaLabel = this.labelText
        this.noPdnaLabel = this.pdnaLabel !== ''
      }
      this.$emit('add-data-watchlist', {'label': this.labelText, 'data_val': this.hash.trim()})
      this.labelText = ''
      this.showLabelDialog = false
      this.addedToWatchlist = true
      setTimeout(() => {
        this.showLabel = true
      }, 2000)
    },
    shortenLabel(label, lngth) {
      if (label === '') {
        return 'Item in Watchlist'
      } else {
        if (label.length > lngth) {
          return label.slice(0, lngth) + '...'
        } else {
          return label
        }
      }
    }
  },
}
</script>

<style>
.watchlist-btn-photodna div {
  width: 322px;
}
.watchlist-btn-sha1 div {
  width: 820px;
}
.label-dialog {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  padding: 20px 30px 60px;
  border: 2px solid #9e9b9a;
  width: 50%;
  background: #fff;
  z-index: 100;
  box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.2);
}
.label-dialog p:first-child {
  margin-bottom: 20px;
}
.label-dialog input:disabled {
  background: #eee;
}
.label-dialog label {
  margin-bottom: 8px;
}
.label-dialog-btns {
  position: absolute;
  right: 30px;
}
.label-dialog-confirm-btn,
.label-dialog-nolabel-btn {
  background: #c22c3f;
  color: #fff;
  padding: 5px 8px;
  border-radius: 5px;
}
.label-dialog-cancel-btn {
  background: #fff;
  color: #c22c3f;
  padding: 5px 8px;
  border: 1px solid #c22c3f;
  border-radius: 5px;
}
.label-dialog-cancel-btn {
  position: absolute;
}
.watchlist-btn-label-error {
  color: #a42334;
  position: absolute;
  right: 180px;
  font-size: 0.85rem;
  width: 245px;
}
.watchlist-pdna-add {
  height: 80px;
}
.watchlist-btn-container,
.watchlist-sightings-items .watchlist-btn-container,
.result-grid__item .watchlist-btn-container {
  position: relative;
  height: 36px;
}
.watchlist-sightings-items .watchlist-btn-sha1,
.result-grid__item .watchlist-btn-sha1 {
  position: absolute;
  left: 0;
}
.pdna-add-btn,
.watchlist-btn {
  background: #c22c3f;
  color: #fff;
  padding: 5px 8px;
  border-radius: 5px;
  font-size: 14px;
  position: absolute;
}
.watchlistBold {
  font-weight: bold;
}
.dl-table .watchlist-btn,
.dl-table .watchlist-sha1-label {
  position: static;
}
.watchlist-btn-sha1 .watchlist-btn {
  width: 135px;
}
.watchlist-btn-photodna .watchlist-btn {
  width: 188px;
}
.watchlist-btn-photodna .watchlist-btn.added,
.watchlist-btn-photodna .watchlist-pdna-label {
  width: 204px;
}
.watchlist-btn.added {
  background: #999;
  color: #fff;
}
.watchlist-btn.added:hover {
  background: #999;
}
.label-dialog p:first-child {
  margin-bottom: 20px;
}
.label-dialog input:disabled {
  background: #eee;
}
.label-dialog label {
  margin-bottom: 8px;
}
</style>
