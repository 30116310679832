<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <div data-app>
    <v-dialog
      v-model="loading"
      transition="fade-transition"
      fullscreen
    >
      <v-container
        fluid
        fill-height
        style="background-color: rgba(255, 255, 255, 0.75);"
      >
        <v-row>
          <v-col cols="12">
            <v-row
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="#a42334"
                size="70"
                width="10"
              />
            </v-row>
            <v-row
              align="center"
              justify="center"
            >
              <div class="u-mt-md loading-text">
                <strong>Loading ...</strong>
              </div>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
    <forum-context-site-header
      :message-search-url="headerInfo.messageSearchUrl"
      :site-name="headerInfo.siteName"
      :site-id="headerInfo.siteId"
      :has-site-notes="headerInfo.hasSiteNotes"
      :in-watchlist="siteInWatchlist"
      :site-url="siteUrl"
    />
    <ul class="site-index">
      <notes-legend />
      <li
        v-for="(forum, index) in forumsData"
        :key="index"
        class="site-index__row"
      >
        <p class="site-index__name">
          <a
            v-if="forum.site_name"
            :href="'/en/message-search/forum-sites/'+ forum.site_name + '/' + forum.forum"
          >
            {{ forum.forum }}
          </a>
          <span v-else>No Forum Name</span>
          <note-buttons
            :noteId="forum.forum_id"
            :noteName="forum.forum"
            :noteLinked="forum.uuid"
            :noteItem="'forum'"
            :noteSiteId="forum.site_id"
            :noteSiteName="forum.site_name"
            :hasNotes="forum.has_forum_notes"
            :indexType="'forum'"
            :siteLink="getForumLink(forum.site_name, forum.forum)"
            :watchlistMessage="{
              'forum_watchlist': forum.forum_watchlist,
              'url':forum.url,
              '_source': {
                'site_id': forum.site_id,
                'site_name': forum.site_name,
                'forum': forum.forum,
              }
            }"
            @refresh="refreshed"
          />
        </p>
        <div class="site-index__info">
          <i class="u-fontsize-sm">
            Last post in forum:
          </i>
          <br>
          <span v-if="forum.topic">
            in
            <a
              :href="'/en/message-search/?filters=site_name:'+ forum.site_name + ',topic:' + encodeURL(forum.topic_url_encoded)"
              class="link--search"
            >
              {{ forum.topic }}
            </a>
            <note-buttons
              :noteId="forum.topic_id"
              :noteName="forum.topic"
              :noteLinked="forum.uuid"
              :noteItem="'Topic'"
              :noteSiteId="forum.site_id"
              :noteSiteName="forum.site_name"
              :hasNotes="forum.has_topic_notes"
              :indexType="'forum'"
              :siteLink="getTopicLink(forum.site_name, forum.topic)"
              :watchlistMessage="{
                'url':forum.url,
                'topic_watchlist': forum.topic_watchlist,
                '_source': {
                  'site_id': forum.site_id,
                  'site_name': forum.site_name,
                  'topic': forum.topic,
                  'site':forum.site
                }
              }"
              @refresh="refreshed"
            />
          </span>
          <span v-else-if="forum.topic_id">
            <a
              :href="'/en/message-search/?filters=site_name:'+ forum.site_name + ',topic_id:' + forum.topic_id"
              class="link--search"
            >
              <i>(no topic name)</i>
            </a>
            <note-buttons
              :noteId="forum.topic_id"
              :noteName="forum.topic"
              :noteLinked="forum.uuid"
              :noteItem="'topic'"
              :noteSiteId="forum.site_id"
              :noteSiteName="forum.site_name"
              :hasNotes="forum.has_topic_notes"
              :indexType="'forum'"
              :siteLink="getTopicIdLink(forum.site_name, forum.topic_id)"
              @refresh="refreshed"
            />
          </span>
          <span v-else><i>(no topic name)</i></span>
          <br>
          by
          <a
            :href="'/en/message-search/?filters=site_name:'+ forum.site_name + ',username:' + encodeURL(forum.username_url_encoded)"
            class="link--search"
          >
            {{ forum.username }}
          </a>
          <note-buttons
            :noteId="forum.user_id"
            :noteName="forum.username"
            :noteLinked="forum.uuid"
            :noteItem="'user'"
            :noteSiteId="forum.site_id"
            :noteSiteName="forum.site_name"
            :hasNotes="forum.has_user_notes"
            :indexType="'forum'"
            :siteLink="getUserLink(forum.site_name, forum.username)"
            :watchlistMessage="{
              'url':forum.url,
              'username': forum.username,
              'watchlist': forum.watchlist,
              'site': {
                'site_id': forum.site_id,
                'site_name': forum.site_name
              }
            }"
            @refresh="refreshed"
          />
          <a
            class="u-pl-sm"
            :href="`/en/user/detail/${forum.username}/${forum.site_name}`"
          >
            View User Details
            <svg
              class="icon u-fill-current u-fontsize-sm"
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 100 100"
            >
              <path d="M47 2L35 14l27 27-40-1H6v20h16l40-1-27 27 12 12 48-48z" />
            </svg>
          </a>
          <br>
          {{ formatDate(forum.posted_date) }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import NoteButtons from './notes/NoteButtons'
import NotesLegend from './notes/NotesLegend'
import ForumContextSiteHeader from './forum_search/ForumContextSiteHeader'
import axios from 'axios'
import Cookies from 'js-cookie'

export default {
  name: 'ForumIndex',
  components: {
    ForumContextSiteHeader,
    NoteButtons,
    NotesLegend
  },
  props: {
    siteName: {
      type: String,
      required: true
    },
    headerInfo: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      forumsData: [],
      siteUrl:'',
      loading: false,
      siteInWatchlist: false
    }
  },
  mounted() {
    const csrftoken = Cookies.get('csrftoken')
    const url = '/en/indices/' + this.siteName + '/forum_index/'
    this.loading = true
    axios.get(url, {'headers': {'X-CSRFToken': csrftoken}})
      .then(response => {
        this.forumsData = response.data
        this.siteInWatchlist = this.forumsData[0].site_watchlist
        this.siteUrl=this.forumsData[0].url
        this.loading = false
      })
      .catch(error => {
        console.log(error)
        this.forumsData = []
      })
  },
  methods: {
    getForumLink(siteName, forum) {
      return new URL('/en/message-search/forum-sites/' + siteName + '/' + forum + '/', document.baseURI).href
    },
    getTopicLink(siteName, topic) {
      return new URL('/en/message-search/?filters=site_name:' + siteName + ',topic:' + this.encodeURL(topic), document.baseURI).href
    },
    getTopicIdLink(siteName, topicId) {
      return new URL('/en/message-search/?filters=site_name:' + siteName + ',topic_id:' + topicId, document.baseURI).href
    },
    getUserLink(siteName, userName) {
      return new URL('/en/message-search/?filters=site_name:' + siteName + ',username:' + userName, document.baseURI).href
    },
    encodeURL(topic) {
      return encodeURIComponent(topic)
    },
    formatDate(date) {
      const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      }
      return new Date(date * 1000).toLocaleString('en-US', options)
    },
    refreshed() {
      const csrftoken = Cookies.get('csrftoken')
      let siteNameObj = {'site_name': this.forumsData[0].site_name}
      axios.get('/en/message-search/refresh_forum_index/', {params: siteNameObj}, {'headers': {'X-CSRFToken': csrftoken}}).then(response => {
        this.forumsData = response.data.data
      })
    }
  }
}
</script>

<style scoped>
.v-progress-circular,
.loading-text {
  margin: auto;
}
</style>
