<template>
  <div
    class="add-note-container"
    @click.self="closePopup"
  >
    <div class="add-note-popup">
      <button
        class="add-note-close"
        @click="closePopup"
      >
        &#x2716;
      </button>
      <p>
        <label for="note">Note: </label>
        <textarea
          ref="note"
          v-model="note"
          class="note-textarea"
          :class="{'note-error': noteError}"
          @keyup="noteTextareaKeypress"
        />
        <span
          v-if="noteError"
          class="note-error-message"
        >Please Enter a Note</span>
      </p>
      <p class="note-type">
        <label for="type">Type: </label>
        <input
          id="note-type-private"
          v-model="noteType"
          type="radio"
          value="PR"
        > <label for="note-type-private">Private</label>
        <input
          id="note-type-public"
          v-model="noteType"
          type="radio"
          value="PB"
        > <label for="note-type-public">Public</label>
      </p>
      <!--<select v-model="noteType">
        <option value="PB">Public</option>
        <option value="PR">Private</option>
      </select>
      <label for="type">Category: </label><br>
      <select v-model="noteCategory">
        <option value="1">Category One</option>
      </select>-->
      <p>
        <AddItemWatchlist
          v-if="watchlistMessage"
          :message="watchlistMessage"
          :watchlistItemName="noteName"
          :watchlistItemId="watchlistItemId"
          :itemName="noteName"
          :siteName="siteName"
          :siteId="siteId"
          :docId="watchlistMessage._id || watchlistMessage.uuid"
          :siteType="watchlistMessage._type || indexType === 'forum' ? 'forum_posts' : 'chat_messages'"
          :noteItem="noteItem"
        />
      </p>
      <p>
        <button
          class="add-note-btn"
          type="button"
          @click="updateNote()"
        >
          Add Note
        </button>
      </p>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Cookies from 'js-cookie'
import AddItemWatchlist from '../watchlist/AddItemWatchlist'

export default {
  name: 'AddNote',
  components: {AddItemWatchlist},
  props: {
    currentNoteId: {
      type: String,
      default: ''
    },
    noteItem: {
      type: String,
      default: ''
    },
    noteId: {
      type: [Number, String],
      default: null
    },
    noteName: {
      type: String,
      default: ''
    },
    notedLinked: {
      type: String,
      default: ''
    },
    noteSiteId: {
      type: [Number, String],
      default: null
    },
    noteSiteName: {
      type: String,
      default: ''
    },
    indexType: {
      type: String,
      default: ''
    },
    watchlistMessage: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['noteAdded', 'closePopup'],
  data() {
    return {
      note: '',
      noteType: 'PR',
      // noteCategory: '',
      noteError: false
    }
  },
  computed: {
    watchlistItemId() {
      return (this.watchlistMessage._source) ? this.watchlistMessage._source.site_id : this.watchlistMessage.site.site_id
    },
    siteName() {
      return (this.watchlistMessage._source) ? this.watchlistMessage._source.site_name : this.watchlistMessage.site.site_name
    },
    siteId() {
      return (this.watchlistMessage._source) ? this.watchlistMessage._source.site_id : this.watchlistMessage.site.site_id
    }
  },
  mounted() {
    this.$refs.note.focus()
  },
  methods: {
    updateNote() {
      if (this.note !== '') {
        this.showInputFields = false
        const csrftoken = Cookies.get('csrftoken')
        let note = {
          'note': this.note,
          'pk': this.currentNoteId,
          'noteType': this.noteType,
          // 'noteCategory': this.noteCategory,
          'item': this.noteItem,
          'id': this.noteId,
          'note_on': this.noteItem,
          'name': this.noteName,
          'linked_to': this.noteLinked,
          'site_id': this.noteSiteId,
          'site_name': this.noteSiteName,
          'site_type': this.indexType,
        }
        axios.post('/en/message-search/notes/add/', note, {'headers': {'X-CSRFToken': csrftoken}}).then(response => {
          this.$emit('noteAdded', response.data.choice_id)
          this.closePopup()
        })
      } else {
        this.noteError = true
      }
    },
    closePopup() {
      this.$emit('closePopup')
    },
    noteTextareaKeypress() {
      if (this.note.length > 0) {
        this.noteError = false
      }
    }
  }
}
</script>

<style>
.add-note-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background: rgba(0,0,0,0.2);
  line-height: 1.5;
}
.add-note-popup {
  position: fixed;
  top: 200px;
  left: 50%;
  z-index: 11;
  transform: translateX(-50%);
  width: 60vw;
  background: #f5f5f4;
  padding: 20px 30px;
  border: 2px solid #cecbca;
  border-radius: 6px;
  box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.2);
  box-sizing: border-box;
}
.add-note-close {
  position: absolute;
  top: 5px;
  right: 10px;
  padding-bottom: 10px;
}
.add-note-btn {
  background: #c22c3f;
  color: #fff;
  padding: 5px 8px;
  border-radius: 5px;
  margin-top: 10px;
}
.add-note-btn:active {
  background: #a42334;
}
.note-type label[for="note-type-private"],
.note-type label[for="note-type-public"] {
  width: auto;
  margin-right: 20px;
  padding-left: 1.6em;
}
.note-textarea {
  height: 80px;
  resize: none;
}
.note-textarea.note-error {
  border-color: #c22c3f;
}
.note-error-message {
  color: #c22c3f;
}
</style>
