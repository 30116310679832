import Cookies from 'js-cookie'
import axios from 'axios'

export const resetState = function({commit, dispatch}) {
  commit('resetState')
}

export function initializeWatchlistState({commit, state}, message, siteName = '') {
  const siteWatchlistData = {}
  const forumWatchlistData = {}
  const topicWatchlistData = {}
  const watchlistData = {}
  const username = message._source ? message._source.username : message.username
  if (message.watchlist === true && username) {
    watchlistData[username] = message.watchlist
  }
  if (message._source) {
    if (message.site_watchlist === true && message._source.site_name) {
      siteWatchlistData[message._source.site_name] = message.site_watchlist
    }
  }
  if (message.site_watchlist === true && message.site_name) {
    siteWatchlistData[message.site_name] = message.site_watchlist
  }

  if (message.forum_watchlist === true && message._source ? message._source.site_name : message.site_name) {
    forumWatchlistData[message._source ? message._source.forum : message.forum] = message.forum_watchlist
  }
  if (message.topic_watchlist === true && message._source ? message._source.site_name : message.site_name) {
    topicWatchlistData[message._source ? message._source.topic : message.topic] = message.topic_watchlist
  }
  const updatedWatchlist = {...state.watchlist, ...watchlistData}
  const updatedSiteWatchlist = {...state.site_watchlist, ...siteWatchlistData}
  const updatedForumWatchlist = {...state.forum_watchlist, ...forumWatchlistData}
  const updatedTopicWatchlist = {...state.topic_watchlist, ...topicWatchlistData}

  commit('setWatchlist', updatedWatchlist)
  commit('setSiteWatchlist', updatedSiteWatchlist)
  commit('setForumWatchlist', updatedForumWatchlist)
  commit('setTopicWatchlist', updatedTopicWatchlist)
}

export function addToWatchlist({commit}, message, watchlistItemName, watchlistItemId, docId, siteName, siteType, siteId, watchlistType) {
  let itemName, itemId
  if (watchlistType === 'username') { // for username
    itemName = watchlistItemName
    itemId = watchlistItemId
  } else if (watchlistType === 'site') { // for both forum site and chat site
    itemName = siteName
    itemId = siteId
  } else if (watchlistType === 'forum') { // for forum only
    itemName = watchlistItemName
    itemId = watchlistItemId
  } else {
    itemName = watchlistItemName
    itemId = watchlistItemId
  }
  const csrftoken = Cookies.get('csrftoken')
  let siteUrl = ''
  if (message._source && message._source.site) {
    siteUrl = message._source.site
  } else {
    siteUrl = message.url
  }

  let postData = {
    'item_name': itemName,
    'item_id': itemId,
    'doc_id': docId,
    'watchlist_type': watchlistType,
    'site_type': siteType,
    'site_id': siteId,
    'site_name': siteName,
    'site': siteUrl

  }
  return axios.post('/en/watchlist/create/', postData, {headers: {'X-CSRFToken': csrftoken}})
    .then(() => {
      const watchlistType = postData.watchlist_type
      if (watchlistType === 'username') { // for username
        commit('addUserToWatchlist', watchlistItemName, siteId)
      }
      if (watchlistType === 'site') { // for both forum site and chat site
        commit('addSiteToWatchlist', siteName)
      }
      if (watchlistType === 'forum') { // for both forum site and chat site
        commit('addForumToWatchlist', message._source ? message._source.forum : message.forum)
      }
      return true
    })
    .catch((error) => {
      console.error(error)
      return false
    })
}

export function removeFromWatchlist({commit}, message, watchlistItemName, watchlistItemId, docId, siteName, siteType, siteId, watchlistType) {
  const csrftoken = Cookies.get('csrftoken')
  let postData = {
    'item_name': watchlistItemName,
    'site_name': siteName,
    'site_id': siteId,
    'watchlist_type': watchlistType

  }
  return axios.post('/en/watchlist/delete/', postData, {headers: {'X-CSRFToken': csrftoken}})
    .then(() => {
      const watchlistType = postData.watchlist_type
      if (watchlistType === 'username') { // for username
        commit('removeUserFromWatchlist', watchlistItemName)
      }
      if (watchlistType === 'site') { // for both forum site and chat site
        commit('removeSiteWatchlist', siteName)
      }
      return true
    })
    .catch((error) => {
      console.error(error)
      return false
    })
}
