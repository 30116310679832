<template>
  <button
    class="bookmark-button"
    :title="isBookmarked ? 'Remove Bookmark' : 'Bookmark'"
    @click="toggleBookmark"
  >
    <img
      class="bookmark-icon"
      :src="isBookmarked? '/images/icons/bookmark-black.svg' : '/images/icons/bookmark-white.svg'"
      alt="Bookmark"
    >
  </button>
</template>
<script>
import axios from 'axios'
import Cookies from 'js-cookie'

export default {
  name: 'BookmarkButton',
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isBookmarked: this.message.bookmarked,
    }
  },
  methods: {
    toggleBookmark() {
      if (this.isBookmarked) {
        this.removeBookmark()
      } else {
        this.addBookmark()
      }
    },
    addBookmark() {
      const csrftoken = Cookies.get('csrftoken')
      axios
        .post('/en/bookmark/', {
          post_id: this.message._id,
          post_type: this.message._type
        }, {'headers': {'X-CSRFToken': csrftoken}})
        .then(() => {
          this.isBookmarked = true
        })
        .catch((error) => {
          console.error(error)
        })
    },
    removeBookmark() {
      const csrftoken = Cookies.get('csrftoken')
      axios
        .post('/en/bookmark/remove/', {
          post_id: this.message._id,
        }, {'headers': {'X-CSRFToken': csrftoken}})
        .then(() => {
          this.isBookmarked = false
        })
        .catch((error) => {
          console.error(error)
        })
      this.$emit('bookmarkRemoved')
    },
  }
}

</script>
