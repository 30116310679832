<template>
  <span class="note-buttons">
    <button
      title="View notes added by you or others"
      @click="listNotes"
    >
      <img
        v-if="noteExist"
        class="note-icon"
        src="/images/icons/view_notes.svg"
        title="View Notes"
      >
    </button>
    <button
      title="Add a public or private note"
      @click="addNotes"
    >
      <img
        class="note-icon"
        src="/images/icons/add_notes.svg"
        title="Add Note"
      >
    </button>
    <add-note
      v-if="showInputFields"
      :currentNoteId="currentNoteId"
      :noteItem="noteItem"
      :noteId="noteId"
      :noteName="noteName"
      :noteLinked="noteLinked"
      :noteSiteId="noteSiteId"
      :noteSiteName="noteSiteName"
      :indexType="indexType"
      :watchlistMessage="watchlistMessage"
      @noteAdded="newNoteAdded"
      @closePopup="showInputFields = false"
    />
    <UserNote
      v-if="showUserNotes"
      :usernotes="usernotes"
      :indexType="indexType"
      :noteSiteName="userNoteSiteName || noteSiteName"
      :siteLink="siteLink"
      @listEmpty="listEmpty"
      @closed="showUserNotes = false"
      @add="addNoRefUserNote"
    />
  </span>
</template>

<script>
import axios from 'axios'
import Cookies from 'js-cookie'
import AddNote from './AddNote'
import UserNote from './UserNote'

export default {
  name: 'NoteButtons',
  components: {AddNote, UserNote},
  props: {
    noteId: {
      type: [ String, Number ],
      default: null
    },
    noteName: {
      type: String,
      default: null
    },
    noteLinked: {
      type: String,
      default: null
    },
    noteItem: {
      type: String,
      default: null
    },
    hasNotes: {
      type: Boolean,
      default: null
    },
    noteSiteId: {
      type: [Number, String],
      default: null
    },
    noteSiteName: {
      type: String,
      default: ''
    },
    indexType: {
      type: String,
      default: null
    },
    userNoteSiteName: {
      type: String,
      default: ''
    },
    siteLink: {
      type: String,
      default: null
    },
    watchlistMessage: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      showInputFields: false,
      noteExist: false,
      currentNoteId: '',
      usernotes: [],
      showUserNotes: false,
      newNoteName: null,
      newNoteId: null,
      newNoteItem: null
    }
  },
  watch: {
    hasNotes(oldNote, newNote) {
      this.noteExist = this.hasNotes
    }
  },
  mounted() {
    this.noteExist = this.hasNotes
  },
  methods: {
    addNotes() {
      this.showInputFields = true
    },
    newNoteAdded() {
      if (this.usernotes.length === 0) {
        this.listRefresh()
      }
      this.listNotes()
    },
    listNotes() {
      const csrftoken = Cookies.get('csrftoken')
      this.showUserNotes = true
      let notes = {
        'choice_id': this.noteId,
        'name': this.noteName,
        'item': this.noteItem,
        'site_id': this.noteSiteId,
        'site_name': this.noteSiteName
      }
      axios.post('/en/message-search/notes/list/', notes, {'headers': {'X-CSRFToken': csrftoken}}).then(response => {
        this.usernotes = response.data.list_data
      })
    },
    listRefresh() {
      this.noteExist = true
      this.$emit('refresh')
    },
    listEmpty() {
      this.noteExist = false
      this.usernotes = []
      this.$emit('refresh')
    },
    readableDate(timestamp) {
      let date = new Date(timestamp * 1000)
      return date.toLocaleDateString('en-GB', {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      })
    },
    addNoRefUserNote(value) {
      this.showInputFields = true
      this.showUserNotes = false
    },
  }
}
</script>

<style>
.note-buttons, .note-buttons td {
  font-style: normal;
  font-size: 16px;
}
</style>
