var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"data-app":""}},[_c('v-dialog',{attrs:{"transition":"fade-transition","fullscreen":""},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('v-container',{staticStyle:{"background-color":"rgba(255, 255, 255, 0.75)"},attrs:{"fluid":"","fill-height":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"#a42334","size":"70","width":"10"}})],1),_vm._v(" "),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('div',{staticClass:"u-mt-md loading-text"},[_c('strong',[_vm._v("Loading ...")])])])],1)],1)],1)],1),_vm._v(" "),_c('topic-context-site-header',{attrs:{"message-search-url":_vm.headerInfo.messageSearchUrl,"site-name":_vm.headerInfo.siteName,"site-id":_vm.headerInfo.siteId,"forum-id":_vm.headerInfo.forumId,"forum":_vm.headerInfo.forum,"has-forum-notes":_vm.headerInfo.hasForumNotes,"in-watchlist":_vm.topicInWatchlist,"site-url":_vm.siteUrl}}),_vm._v(" "),(_vm.topicsData.length > 0)?_c('ul',{staticClass:"site-index"},[_c('notes-legend'),_vm._v(" "),_vm._l((_vm.topicsData),function(topic,index){return _c('li',{key:index,staticClass:"site-index__row"},[_c('p',{staticClass:"site-index__name"},[(!topic.topic || topic.topic_id)?_c('a',{staticClass:"link--search",attrs:{"href":_vm.topicLink(topic)}},[(topic.topic)?_c('span',[_vm._v(_vm._s(topic.topic))]):_c('i',[_vm._v("(no topic name)")])]):_c('i',[_vm._v("(no topic name)")]),_vm._v(" "),_c('note-buttons',{attrs:{"noteId":topic.topic_id,"noteName":topic.topic,"noteLinked":topic.uuid,"noteSiteId":topic.site_id,"noteSiteName":topic.site_name,"noteItem":'Topic',"hasNotes":topic.has_topic_notes,"indexType":'forum',"siteLink":_vm.getTopicLink(topic.site_name, topic.topic, topic.topic_id),"watchlistMessage":{
            'topic_watchlist': topic.topic_watchlist,
            'url':topic.url,
            '_source': {
              'site_id': topic.site_id,
              'site_name': topic.site_name,
              'topic': topic.topic,
              'site':topic.site
            }
          }},on:{"refresh":_vm.refreshed}})],1),_vm._v(" "),_c('div',{staticClass:"site-index__info"},[_c('i',{staticClass:"u-fontsize-sm"},[_vm._v("\n          Last post in topic:\n        ")]),_vm._v(" "),_c('br'),_vm._v(" "),(topic.username)?_c('span',[_vm._v("\n          by\n          "),_c('a',{staticClass:"link--search",attrs:{"href":'/en/message-search/?filters=site_name:' + topic.site_name + ',username:' + topic.username}},[_vm._v("\n            "+_vm._s(topic.username)+"\n          ")]),_vm._v(" "),_c('note-buttons',{attrs:{"noteId":topic.user_id,"noteName":topic.username,"noteLinked":topic.uuid,"noteSiteId":topic.site_id,"noteSiteName":topic.site_name,"noteItem":'user',"hasNotes":topic.has_user_notes,"indexType":'forum',"siteLink":_vm.getUserLink(topic.site_name, topic.username),"watchlistMessage":{
              'username': topic.username,
              'watchlist': topic.watchlist,
              'url':topic.url,
              'site': {
                'site_id': topic.site_id,
                'site_name': topic.site_name,
                'site': topic.site
              }
            }},on:{"refresh":_vm.refreshed}})],1):_vm._e(),_vm._v(" "),_c('a',{staticClass:"u-pl-sm",attrs:{"href":`/en/user/detail/${topic.username}/${topic.site_name}`}},[_vm._v("\n          View User Details\n          "),_c('svg',{staticClass:"icon u-fill-current u-fontsize-sm",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"14","height":"14","viewBox":"0 0 100 100"}},[_c('path',{attrs:{"d":"M47 2L35 14l27 27-40-1H6v20h16l40-1-27 27 12 12 48-48z"}})])]),_vm._v(" "),_c('br'),_vm._v("\n        "+_vm._s(_vm.formatDate(topic.posted_date))+"\n      ")])])})],2):_vm._e(),_vm._v(" "),(!_vm.loading && _vm.topicsData.length == 0)?_c('p',{staticClass:"alert alert--error"},[_vm._v("\n    No topics found\n  ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }