<template>
  <div>
    <v-card-text v-if="totalPages">
      <div>
        <div class="display-items">
          <div class="display-items-header">
            <div>
              <span>Post</span>
            </div>
            <div>
              <span/>
            </div>
          </div>
          <div
              v-for="(post, index) in displayedItem"
              :key="index"
              class="display-item"
          >
            <div>
              <a
                  :href="'/en/message-search/?query='+ post.doc_id"
              >

                {{ post.post_details| shortenString(maxLength=32) }}<br></a>
            </div>
            <div>
              <user-detail-media
                  :mediaItem="post"
                  :mediaSearchUrl="mediaSearchUrl"
              />
            </div>
          </div>
        </div>
      </div>
      <message-search-page-nav
          :initCurrentPage="currentPage"
          :totalPages="totalPages"
          @page-nav="handlePageNav"
          @select-page="updateCurrentPage"
      />
    </v-card-text>
  </div>
</template>

<script>

import UserDetailMedia from "./UserDetailMedia.vue";
import MessageSearchPageNav from "../message_search/MessageSearchPageNav.vue";

export default {
  name: 'PostWithMedia',
  components: {MessageSearchPageNav, UserDetailMedia},
  props: {
    mediaSearchUrl: {
      type: String,
      required: false,
    },
    totalMediaPost: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      perPageMax: 100,
      perPageMin: 1,
      currentItem: '',
      currentPage: 1,
      itemsPerPage: 10,
    }
  },
  computed: {
    displayedItem() {
      const startIdx = (this.currentPage - 1) * this.itemsPerPage
      const endIdx = startIdx + this.itemsPerPage
      return this.totalMediaPost.slice(startIdx, endIdx)
    },
    totalPages() {
      return Math.ceil(this.totalMediaPost.length / this.itemsPerPage)
    },
  },
  methods: {
    handlePageNav(data) {
      if (data === 'prev') this.prevPage()
      if (data === 'next') this.nextPage()
    },
    updateCurrentPage(page) {
      this.$store.commit('start', (this.limit * parseInt(page)) - 1)
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++
      }
    },
  }

}
</script>

<style scoped>
.display-items > div {
  display: flex;
  height: 55px;
  line-height: 55px;
}

.display-items-header {
  font-weight: bold;
  border-bottom: 2px solid rgb(230, 229, 229);
}

.display-item {
  border-bottom: 2px solid rgb(230, 229, 229);
}

.display-item button {
  transform: scale(0.8);
}

.display-items-header > div,
.display-item > div {
  width: 300px;
  flex-grow: 1;
}

.display-items-header > div:first-child,
.display-item > div:first-child {
  width: 500px;
}

.display-items-header > div:last-child,
.display-items-header > div:nth-last-child(2),
.display-item > div:last-child,
.display-item > div:nth-last-child(2) {
  width: 400px;
  max-width: 400px;
}
</style>