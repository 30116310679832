<template>
  <div>
    <table
      v-if="sightings"
      :id="'sightings' + mediaSha1"
      class="responsive-table u-mb-sm"
    >
      <tr class="u-fontsize-sm">
        <th>URL</th>
        <th>Page URL</th>
        <th>First Seen</th>
        <th>Last Seen</th>
        <th />
      </tr>
      <tr
        v-for="(sighting, index) in filterNElements(sightings, 5)"
        :key="index"
      >
        <td class="u-break">
          {{ sighting.url }}
        </td>
        <td class="u-break">
          {{ sighting.page_url }}
        </td>
        <td class="u-break">
          {{ sighting.first | formatDate }}
        </td>
        <td class="u-break" v-if="sighting.last_seen_date">
          {{ sighting.last_seen_date | formatDate }}
        </td>
        <td>
          <a v-if="sighting.has_messages" :href="forum_url + '?query=' + quote(sighting.page_url)">See forum posts</a>
        </td>
      </tr>
    </table>
    <button
      v-if="sightings.length > 5"
      class="btn btn--sm"
      @click="showSightingsTablePopup('media')"
    >
      Show All Media Sightings
    </button>
    <div v-if="forumSightings && forumSightings.length > 0">
      <br><br>
      <h4>Forum Sightings</h4>
      <table
        :id="'forum_sightings' + mediaSha1"
        class="responsive-table u-mb-sm"
      >
        <tr>
          <th>Site name</th>
          <th>Forum</th>
          <th>Topic</th>
          <th>Post ID</th>
          <th>Posted date</th>
          <th />
        </tr>
        <tr
          v-for="sighting in filterNElements(forumSightings, 5)"
          :key="sighting.post_id"
        >
          <td>{{ sighting.site_name }}</td>
          <td class="u-break">
            {{ sighting.forum }}
          </td>
          <td class="u-break">
            {{ sighting.topic }}
          </td>
          <td class="u-break">
            {{ sighting.post_id }}
          </td>
          <td class="u-break">
            <span v-if="sighting.posted_date">
              {{ sighting.posted_date | formatDate }}
            </span>
            <span v-else>Unavailable</span>
          </td>
          <td>
            <a :href="forumUrl + '?doc_id=' + sighting.doc_id">See forum posts</a>
          </td>
        </tr>
      </table>
      <button
        v-if="forumSightings.length > 5"
        class="btn btn--sm"
        @click="showSightingsTablePopup('forums')"
      >
        Show All Forum Sightings
      </button>
    </div>
    <div v-if="chatSightings && chatSightings.length > 0">
      <br><br>
      <h4>Chat Sightings</h4>
      <table
        :id="'chat_sightings' + mediaSha1"
        class="responsive-table u-mb-sm"
      >
        <tr>
            <th>Site name</th>
            <th>Posted date</th>
            <th />
        </tr>
        <tr
          v-for="sighting in filterNElements(chatSightings, 5)"
          :key="sighting.post_id"
        >
          <td>
            {{ sighting.site_name }}
          </td>
          <td class="u-break">
            <span v-if="sighting.posted_date">
                {{ sighting.posted_date | formatDate }}
            </span>
            <span v-else>Unavailable</span>
          </td>
          <td>
            <a :href="forumUrl + 'chat-sites/SiteName/' + sighting.doc_id">See chat messages</a>
          </td>
        </tr>
      </table>
      <button
        v-if="chatSightings.length > 5"
        class="btn btn--sm"
        @click="showSightingsTablePopup('chats')"
      >
        Show All Chat Sightings
      </button>
    </div>
    <sightings-table-popup
      v-if="showOverlay"
      :forumUrl="forumUrl"
      :sightingsType="overlaySightingsType"
      :mediaSha1="mediaSha1"
      :sightings="overlaySightings"
      @hideSightingsOverlay="showOverlay = false"
    />
  </div>
</template>

<script>
import axios from 'axios'
import Cookies from 'js-cookie'
import SightingsTablePopup from '../image_search/SightingsTablePopup.vue'

export default {
  name: 'MediaSearchSightingsTable',
  components: {SightingsTablePopup},
  props: {
    mediaSha1: {
      type: String,
      required: true
    },
    sightings: {
      type: Array,
      default: () => []
    },
    moreSightings: {
      type: Number,
      default: 0
    },
    forumSightings: {
      type: Array,
      default: () => []
    },
    chatSightings: {
      type: Array,
      default: () => []
    },
    forumUrl: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showOverlay: false,
      overlaySightingsType: 'media',
      overlaySightings: [],
    }
  },
  methods: {
    quote(s) {
      return `"${s}"`
    },
    filterNElements(arrayToFilter, n) {
      return arrayToFilter.slice(0, n)
    },
    showSightingsTablePopup(sightingsType) {
      const csrftoken = Cookies.get('csrftoken')
      this.overlaySightings = []

      if (sightingsType === 'media') {
        this.overlaySightings = this.sightings
      } else if (sightingsType === 'forums') {
        axios.get(
          '/en/media/get_forum_sightings/' + this.mediaSha1,
          {'headers': {'X-CSRFToken': csrftoken}}
        ).then((response) => {
          this.overlaySightings = response.data
        }).catch((error) => {
          console.log(error)
        })
      } else if (sightingsType === 'chats') {
        const csrftoken = Cookies.get('csrftoken')
        axios.get(
          '/en/media/get_chat_sightings/' + this.mediaSha1,
          {'headers': {'X-CSRFToken': csrftoken}}
        ).then((response) => {
          this.overlaySightings = response.data
        }).catch((error) => {
          console.log(error)
        })
      } else {
        return // don't do anything because sightings type is not valid
      }
      this.overlaySightingsType = sightingsType
      this.showOverlay = true
    }
  }
}
</script>
