<script>
import axios from 'axios'
import Cookies from 'js-cookie'

export default {
  name: 'ContactForm',

  props: {
    action: {
      type: String,
      required: true,
    },
    referrer: {
      type: String,
      default: '',
    }
  },

  data() {
    return {
      email: '',
      subject: '',
      message: '',
      errors: {},
      showSuccess: false
    }
  },

  watch: {
    email() { this.showSuccess = false },
    subject() { this.showSuccess = false },
    message() { this.showSuccess = false },
  },

  methods: {
    submit() {
      this.showSuccess = false
      this.errors = {}
      const data = {
        email: this.email,
        subject: this.subject,
        message: this.message,
        referrer: this.referrer,
      }

      const csrftoken = Cookies.get('csrftoken')
      axios.post(this.action, data, {'headers': { 'X-CSRFToken': csrftoken }}).then(
        this.success,
        this.error
      )
    },

    error(error) {
      this.errors = error.response.data.errors
    },

    success(response) {
      this.email = ''
      this.subject = ''
      this.message = ''
      // Resetting the data above will cause showSuccess to be set false next
      // tick, so set it true using $nextTick rather than immediately
      this.$nextTick(function() { this.showSuccess = true })
    },

  },
}
</script>
