<template>
  <footer class="message__details-wrapper">
    <p class="media-retrieval-p">
      <a class="media-retrieval" :class="{'open': !hideMediaData, 'closed': hideMediaData}" href="#" @click.prevent="retrieveMediaDetails">
        {{ gettext('Media details') }}
      </a>
    </p>
    <div v-if="!hideMediaData" class="message__details search-media-details">
      <div v-if="mediaData" class="menu-bar">
        <button v-if="mediaData.archives && mediaData.archives.length > 0" @click="activeDataTab = 'archives'" :class="{'open': activeDataTab === 'archives'}">
          Archives
        </button>
        <button v-if="mediaData.media && mediaData.media.length > 0" @click="activeDataTab = 'media'" :class="{'open': activeDataTab === 'media'}">
          Media
        </button>
      </div>
      <div v-else-if="mediaDataError" class="alert alert--error">
        <p>{{mediaDataError}}</p>
      </div>
      <div v-else class="alert alert--info u-text-center">
        {{gettext("Loading...")}}
      </div>
      <div v-if="activeDataTab && activeDataTab === 'archives'" class="data-tab">
        <ol v-if="mediaData.archives && mediaData.archives.length > 0" class="u-mb-0 details-list">
          <li v-for="archive in mediaData.archives" class="details-item">
            <message-result-archive-details
                :archive-data="archive"
                :media-search-url="mediaSearchUrl"
                :message-source="messageSource"
                :index-type="indexType"
            ></message-result-archive-details>
          </li>
        </ol>
      </div>
      <div v-else-if="activeDataTab && activeDataTab === 'media'" class="data-tab">
        <ol v-if="mediaData.media && mediaData.media.length > 0" class="u-mb-0 details-list">
          <li v-for="media in mediaData.media" class="details-item">
            <message-result-media-details
                :media-data="media"
                :media-search-url="mediaSearchUrl"
                :message-source="messageSource"
                :index-type="indexType"
                :parent-type="'media'"
            ></message-result-media-details>
          </li>
        </ol>
        <div v-else-if="mediaData.media && mediaData.media.length === 0" class="alert">
          <p>No media details</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import axios from 'axios'
import Cookies from 'js-cookie'
import MessageResultMediaDetails from "./MessageResultMediaDetails";
import MessageResultArchiveDetails from "./MessageResultArchiveDetails";

export default {
  name: 'MessageResultDetails',
  components: {
    MessageResultArchiveDetails,
    MessageResultMediaDetails,
  },
  props: {
    docId: {
      type: String,
      required: true
    },
    mediaSearchUrl: {
      type: String,
      required: true
    },
    messageSource: {
      type: Object,
    },
    indexType: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      note: '',
      noteType: '',
      noteCategory: '',
      mediaData: null,
      mediaDataError: null,
      hideMediaData: true,
      activeDataTab: null
    }
  },
  methods: {
    retrieveMediaDetails() {
      this.hideMediaData = !this.hideMediaData
      if (!this.hideMediaData && this.mediaData === null) {
        const csrftoken = Cookies.get('csrftoken')
        axios.get("/en/message-search/get_post_media_details/", {params: {'doc_id': this.docId}}, {'headers': {'X-CSRFToken': csrftoken}}).then(response => {
          if (response.status === 200) {
            this.mediaData = response.data
            this.getInitialDataTab()
          }
        }).catch((error) => {
          this.mediaDataError = error
        })
      }
    },
    getInitialDataTab() {
      if (this.mediaData && this.mediaData.archives && this.mediaData.archives.length > 0) {
        this.activeDataTab = 'archives'
      } else if (this.mediaData && this.mediaData.media && this.mediaData.media.length > 0) {
        this.activeDataTab = 'media'
      }
    }
  }
}
</script>

<style>
.message__details .note-buttons {
  position: absolute;
  right: 30px;
}
.search-media-details {
  padding-top: 10px;
  padding-bottom: 10px;
}
.data-tab {
  background: #FFF;
  padding: 20px;
  border: 1px solid #c22c3f;
}
.media-retrieval {
  text-decoration: none;
  color: black;
  padding-left: 10px;
}
.media-retrieval:before {
  color: #a42334;
  position: relative;
  top: -2px;
  left: -8px;
}
.media-retrieval.closed:before {
  content: '▸';
}
.media-retrieval.open:before {
  content: '▾';
}
.media-retrieval-p {
  margin-bottom: 6px;
}
.menu-bar {
  padding-left: 20px;
}
.menu-bar button {
  color: #a42334;
  border: 1px solid #a42334;
  border-bottom: 0;
  padding: 5px 10px;
}
.menu-bar button.open {
  background: #a42334;
  color: #FFF;
}
.details-list {
  list-style: none;
  margin-left: 0;
}
.details-item {
  border-bottom: hsl(11,3%,90%) solid 1px;
  margin-bottom: 10px;
}
.details-item:last-child {
  border-bottom: none;
}
</style>
