<template>
    <div>
        <p><a :href="'/en/message-search/?filters=username%3A' + result + '&posted_date=desc&query=&limit=25&start=0'">Search with {{ result }}</a><hr></p>
    </div>
</template>

<script>
export default {
  props: {
    result: {
      type: String
    }
  }
}
</script>

<script setup>
</script>