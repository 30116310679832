<template>
  <div>
    <button @click="showRequestForm=true" class="send-message" v-show="!search_req_sent">Yes</button>
    <span v-if="search_req_sent" style="color: green">&#10004</span><output class="in-depth-search-output" ref="outputBox"></output>
    <div class="request-form" v-if="showRequestForm">
      <form>
        <p>
          <label for="email">Your email:</label>
          <input :value="userEmail" disabled>
        </p>
        <p>
          <label>Additional Information<small> (optional)</small></label>
          <textarea v-model="contactMessage" name="contactMessage"></textarea>
        </p>
        <p>
          <input type="checkbox" id="urgent" value="urgent" v-model="checkedUrgent">
          <label for="urgent">Mark Urgent</label>
        </p>
        <div class="request-form-btns">
          <button class="request-form-cancel-btn" type="button" @click="cancelPopup">Cancel</button>
          <button class="request-form-confirm-btn" type="button" @click="inDepthSearch">Send</button>
        </div>
      </form>
      <div class="confirm-close" v-if="showConfirm">
        <p>Are you sure?</p>
        <p>
          <button class="confirm-close-yes" @click="showRequestForm = false; showConfirm = false; contactMessage = ''">Yes</button>
          <button class="confirm-close-no" @click="showConfirm = false">No</button>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Cookies from 'js-cookie'


export default {
  props: ['search_type', 'search_hash'],
  data() {
    return {
      showRequestForm: false,
      userEmail: '',
      contactMessage: '',
      showConfirm: false,
      search_req_sent: false,
      checkedUrgent: false,
    }
  },

  methods: {
    cancelPopup() {
      if (this.contactMessage === '') {
        this.showRequestForm = false
        this.checkedUrgent = false
      } else {
        this.showConfirm = true
      }
    },
    inDepthSearch() {
      let result = 'Something went wrong, request not sent'
      this.errors = {}
      var data = {};

      if (this.search_hash !== null) {
        data = {
          hash_val: this.search_hash,
          hash_type: this.search_type,
          message: this.contactMessage,
          urgent: this.checkedUrgent
        }
      }

      if (Object.keys(data).length !== 0) {
        axios.post('/en/media/in-depth-search/', data, {'headers': {'X-CSRFToken': Cookies.get('csrftoken')}})
        .then((response) => {
           if (response.data.response_text === 'email sent') {
             result = 'Thank you! Your request has been sent. We will follow up as soon as possible.'
             this.search_req_sent = true
          }
          this.showRequestForm = false
          this.checkedUrgent = false
          this.contactMessage = ''
          this.$refs.outputBox.value = result

        }).catch( err => {
          if (err.response.status === 400) {
            console.log("Reason: %o", err.response.data.response_text)
            result = 'Search Hash is not valid. Request not sent!'
            this.search_req_sent = false
            this.showRequestForm = false
            this.checkedUrgent = false
            this.$refs.outputBox.value = result
          }
          console.log("something went wrong! %o", err)
        } )
      }
    },

    getEmailAddress() {
      axios.get('/en/media/user_email/').then(response => {
        this.userEmail = response.data.current_user_email
      }).catch(
        err => {console.log("something went wrong! %o", err)}
      )
    }
  },
  mounted: function() {
      this.getEmailAddress()
  },
}
</script>

<style>
.request-form {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  padding: 20px 30px 60px;
  border: 2px solid #9e9b9a;
  width: 50%;
  background: #fff;
  z-index: 100;
  box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.2);
}
.request-form p:first-child {
  margin-bottom: 20px;
}
.request-form input:disabled {
  background: #eee;
}
.request-form label {
  margin-bottom: 8px;
}
.request-form textarea {
  width: 100%;
  height: 150px;
  resize: none;
}
.send-message {
  background-color: #c22c3f;
  color: white;
  padding: 5px 8px;
  border-radius: 5px;
  font-size: 18px;
  float: left;
}
.request-form-btns {
  position: absolute;
  right: 30px;
  bottom: 10px;
}
.request-form-confirm-btn, .confirm-close-yes {
  background: #c22c3f;
  color: #fff;
  padding: 5px 8px;
  border-radius: 5px;
}
.request-form-cancel-btn, .confirm-close-no {
  background: #fff;
  color: #c22c3f;
  padding: 5px 8px;
  border: 1px solid #c22c3f;
  border-radius: 5px;
}
.in-depth-search-output {
  font-weight: bold;
  padding: 5px 8px;
  opacity: 1;
  color: #c22c3f;
}
.confirm-close {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  border: 2px solid #9e9b9a;
  background: #fff;
  width: 300px;
  padding: 20px;
  box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.2);
}
.confirm-close p {
  text-align: center;
}
</style>
