<template>
  <div>
    <button @click="showEditPopup" class="edit-label-btn edit-label-btn-add">Edit Label</button>
    <div class="edit-label" v-if="showEdit">
      <form>
        <p>
          <label>Add or Change Label:</label>
          <input class="edit-label-input" type="text" name="newLabel" v-model="newLabel">
          Note: Do not include victim information or media hashes in labels
        </p>
        <button type="button" class="edit-label-clear-btn" @click="newLabel = ''">Clear Label</button>
        <div class="edit-label-btns">
          <button type="button" class="edit-label-cancel-btn" @click="showEdit = false">Cancel</button>
          <button type="button" class="edit-label-confirm-btn" @click="edit_label">Update</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

export default {
  emits: ['edit-label'],
  props: ['label'],
  data() {
    return {
      showEdit: false,
      newLabel: ''
    }
  },
  methods: {
    edit_label() {
      this.$emit('edit-label', this.newLabel)
      this.showEdit = false
    },
    showEditPopup() {
      this.newLabel = this.label
      this.showEdit = true
    }
  }
}
</script>

<style>
.edit-label-btn {
  background: #c22c3f;
  color: #fff;
  padding: 5px 8px;
  border-radius: 5px;
}
.edit-label-btn-add {
  right: 20px;
  bottom: 15px;
  font-size: 14px;
}
.edit-label-btn:active {
  background: #a42334;
}
.edit-label {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  padding: 20px 30px 60px;
  border: 2px solid #9e9b9a;
  width: 50%;
  background: #fff;
  z-index: 100;
  box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.2);
}
.edit-label-input {
  box-sizing: border-box;
}
.edit-label-btns {
  position: absolute;
  right: 30px;
  bottom: 10px;
}
.edit-label-confirm-btn {
  background: #c22c3f;
  color: #fff;
  padding: 5px 8px;
  border-radius: 5px;
}
.edit-label-cancel-btn {
  background: #fff;
  color: #c22c3f;
  padding: 5px 8px;
  border: 1px solid #c22c3f;
  border-radius: 5px;
}
.edit-label-clear-btn {
  background: #fff;
  color: #c22c3f;
  padding: 5px 8px;
  border: 1px solid #c22c3f;
  border-radius: 5px;
  position: absolute;
  bottom: 10px;
  left: 30px;
}
</style>
