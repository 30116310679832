<template>
 <div class="l-container--flex">
    <v-dialog v-model="loading" transition="fade-transition" fullscreen>
        <v-container
            fluid
            fill-height
            style="background-color: rgba(255, 255, 255, 0.75);">
            <v-row>
                <v-col cols="12">
                    <v-row
                        align="center"
                        justify="center"
                    >
                        <v-progress-circular
                            indeterminate
                            color="accent"
                            size="70"
                            width="10">
                        </v-progress-circular>
                    </v-row>
                    <v-row
                        align="center"
                        justify="center"
                    >
                        <div class="u-mt-md">
                            <strong v-if="uploaded">Upload Complete - Loading Results ...</strong>
                            <strong v-else>Loading ...</strong>
                        </div>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </v-dialog>
    <div class="l-container__controls">
        <SearchForm
            action="/en/media/"
            :searchicon="searchicon"
            v-on:childToParent="didSearch"
            v-on:invalidInput="invalidInput"
            v-on:startSearch="startSearch"
            v-on:noResults="noResults"
            v-on:manualPDNASearchStart="resetImageSearch"
            v-on:manualSHA1SearchStart="resetImageSearch"
            v-on:manualMD5SearchStart="resetImageSearch"
            v-on:batchSuccess="batchDone"
            ref="theSearchForm"
            :photoDNA="pdna"
            :sha1qs="sha1"
            :md5qs="md5"
            :pdna_val="pdna_for_watchlist"
            :pdna_in_watchlist="pdna_in_watchlist"
            :pdna_label="pdna_label"
            @add-data-watchlist="add_data_watchlist"
        />
        <UploadForm
            action="/en/upload/"
            referrer=""
            :csrf-token="csrf_token"
            @videoUploadComplete="finishVideoUpload"
            @got-file-type="gotFileType"
            v-on:uploadComplete="finishUpload"
            v-on:batchSuccess="batchDone"
            v-on:batchPartialFail="batchPartialFail"
            ref="image_search_form"
        />
        <ImageSearchFilterResults
            v-on:filterTrigger="filterResults"

        />
    </div>
    <div class="l-container__output">
        <ResultArea
            :results_sighted_images="sighted_images"
            :results_unsighted_images="unsighted_images"
            :results_forum_sightings="forum_sightings"
            :search_hash="search_hash"
            :search_type="search_type"
            :forum_url="forum_url"
            :current_filter="current_filter"
            :batch_search="batch_search"
            :batch_partial_fail="batch_partial_fail"
            :user_email="user_email"
            :has_hashes="has_hashes"
            :pdna_val="pdna_for_watchlist"
            :pdna_in_watchlist="pdna_in_watchlist"
            :pdna_label="pdna_label"
            :file_type="file_type"
            @add-data-watchlist="add_data_watchlist"
        />
    </div>
  </div>

</template>

<script>
import 'url-search-params-polyfill'
import ResultArea from './image_search/ResultArea'
import axios from 'axios'
import Cookies from "js-cookie";
import SearchForm from './ImageSearchForm'
import UploadForm from './FileUploadForm'

let serverURL = '/en/media'

export default {
    name: 'ImageSearch',
    components: {
        SearchForm,
        UploadForm,
        ResultArea
    },
    props: {
        searchicon: {
            type: String,
            required: true
        },
        csrf_token: {
            type: String,
            required: true
        },
        forum_url: {
            type: String,
            required: true
        },
        user_email: {
            type: String
        },
    },

    computed: {
      getFileType() { return this.file_type },
    },
    data() {
        return {
          sighted_images: [],
          unsighted_images: [],
          forum_sightings: [],
          search_hash: '',
          search_type: '',
          pdna: '',
          sha1: '',
          md5: '',
          loading: false,
          uploaded: false,
          fltr_sighted_images: [],
          fltr_unsighted_images: [],
          current_filter: 'all',
          batch_search: false,
          has_hashes: false,
          pdna_for_watchlist: null,
          pdna_in_watchlist: false,
          pdna_label: '',
          batch_partial_fail: [],
          file_type: '',
        };
    },
    methods: {
      add_data_watchlist(args) {
      // add a media entry to the watchlist
        let labelInput = args['label'];
        let data_val = args['data_val'];
        let media_data = {};

        if (data_val != null) {
        if (data_val.trim().length === 288) {
          media_data = {
            label: labelInput,
            pdna_key: data_val
          }
        } else if (data_val.trim().length === 32) {
          media_data = {
            label: labelInput,
            sha1_key: data_val
          }
        }

        if (Object.keys(media_data).length !== 0) {
          const csrftoken = Cookies.get('csrftoken')
          axios.post('/en/watchlist/add/', media_data, {'headers': {'X-CSRFToken': csrftoken}}).then(
            resp => {console.log("got a response! %o", resp)}
          ).catch(
            err => {console.log("something went wrong! %o", resp)}
          )
        }
        data_val = ''
      }
    },
        gotFileType(file_format) {
          this.file_type = file_format
        },
        resetImageSearch(){
            this.$refs.image_search_form.resetDropzone = true;
            this.uploaded = false;
        },
        filterResults(value){
            this.current_filter = value;
            switch(value){
                case "sightings":{
                    this.sighted_images = this.fltr_sighted_images;
                    this.unsighted_images = [];
                    break;
                }
                case "hashonly":{
                    this.unsighted_images = this.fltr_unsighted_images;
                    this.sighted_images = [];
                    break;
                }
                default:{
                    this.sighted_images = this.fltr_sighted_images;
                    this.unsighted_images = this.fltr_unsighted_images;
                }
            }
        },
        finishUpload(value){
            this.uploaded = true;
            this.loading = false;
            this.pdna = value;
        },
        finishVideoUpload(value) {
          this.uploaded = true
          this.loading = false
          this.sha1 = value
        },
        didSearch(value) {
          if (value.search_type === 'pdna') {
            this.pdna_for_watchlist = value.search_hash
            this.pdna_in_watchlist = value.pdna_in_watchlist
            this.pdna_label = value.pdna_label
          } else {
            this.pdna_for_watchlist = ''
          }
          this.batch_search = false // make sure we don't suppress the results when not doing a batch upload
          this.sighted_images = value.sighted_images
          this.unsighted_images = value.unsighted_images
          this.forum_sightings = value.forum_sightings
          this.search_hash = value.search_hash
          this.search_type = value.search_type

          // I'm attempting to prevent the need for another request to the API when using the filters
          // so things will be quicker and so frequent filter changes don't trigger the rate limiter
          this.fltr_sighted_images = value.sighted_images
          this.fltr_unsighted_images = value.unsighted_images
          this.loading = false
          this.has_hashes = true
        },
        invalidInput (value) {
            // Need to reset the loading glitz if the hash value(s) come back as invalid. Otherwise you can't do anything
            this.loading = false;
        },
        noResults(value) {
          // Also need to reset the loading glitz if there's no results returned. Missed this thanks to faker always returning
          // results for anything entered in the hash boxes -- good in some cases but need to inject a few "not found" in there.
          this.loading = false
          this.sighted_images = []
          this.unsighted_images = []
          this.fltr_sighted_images = []
          this.fltr_unsighted_images = []
        },
        startSearch(search_hash, search_type) {
          this.loading = true
        },
        batchDone() {
            this.batch_search = true;
            this.loading = false;
        },
        batchPartialFail(data) {
          this.batch_search = true;
          this.batch_partial_fail = data;
          this.loading = false;
        }
    }
}
</script>
