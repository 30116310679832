<template>
  <form
    :action="action"
    method="POST"
    enctype="multipart/form-data"
    novalidate
    autocomplete="off"
    class="u-mt-xl u-mb-lg"
    @submit.prevent="submit">
    <input
      :value="csrfToken"
      type="hidden"
      name="csrfmiddlewaretoken"
    >

    <fieldset class="u-mb-0">
      <legend>Upload Video</legend>
      <dropzone
        id="file-drop"
        :csrfToken="csrfToken"
        :xtraParams="dzParams"
        :acceptedMIME="accepted_mime"
        :url="action"
        :form-state="state"
        :files-vuelidate="$v.totalFiles"
        :max-files="parseInt(maxFiles)"
        :reset-dropzone="resetDropzone"
        :file-type-description="fileTypeDescription"
        :max-filesize="parseInt(maxFilesize)"
        @vue-dz-error="dzError"
        @vue-dz-erroredFiles="state = 'form'"
        @vue-dz-success="queueComplete"
        @vue-dz-totalfiles="updateTotalFiles"
        @vue-dz-queuingfiles="queuingFiles"
        @vue-dz-reset="resetUploader"
      />
    </fieldset>

    <div v-if="state === 'unacceptable_file_type_error'">
      <div class="alert alert--error">
        <p>
          Search by image only accepts image file types (e.g. .jpg, .png, .gif)
        </p>
        <p>
          Please <a href="/en/contact/">contact us</a> if you require assistance.
        </p>
      </div>
      <!-- the retry button doesn't really do anything since in this case something went sideways with the upload, so
           you'd need to do the upload again anyway to retry.  Just expose the reset button as it makes more sense -->
      <!-- <button @click.prevent="submit" class="btn btn--dark u-mr-sm" type="submit" :disabled="state === 'processing'">
        Retry search
      </button> -->
      <button @click.prevent="resetDropzone = true" class="btn btn--primary" type="button">
        Reset
      </button>
    </div>

    <div v-if="state === 'error'">
      <div class="alert alert--error">
        <p>
          An error occurred while attempting the video upload.
        </p>
        <p>
          Please <a href="/en/contact/">contact us</a> if you continue to experience errors.
        </p>
      </div>
      <!-- the retry button doesn't really do anything since in this case something went sideways with the upload, so
           you'd need to do the upload again anyway to retry.  Just expose the reset button as it makes more sense -->
      <!-- <button @click.prevent="submit" class="btn btn--dark u-mr-sm" type="submit" :disabled="state === 'processing'">
        Retry search
      </button> -->
      <button @click.prevent="resetDropzone = true" class="btn btn--primary" type="button">
        Reset
      </button>
    </div>

    <div v-if="state === 'success' || state === 'videoarachnidsuccess'">
      <div class="alert alert--success" v-if="state === 'success' || state === 'videoarachnidsuccess'">
        <svg class="file-drop__icon file-drop__icon--success-sm" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="20" aria-hidden="true">
          <path d="M38 87L4 54l14-15 20 21 43-44 14 14z"/>
        </svg>
        Video uploaded successfully
      </div>
      <button @click.prevent="resetDropzone = true" class="btn btn--primary" type="button">
        Upload another Video
      </button>
    </div>

    <div v-else-if="totalFiles >= 1 && state !== 'error'" class="u-mb-lg">
      <button @click.prevent="checkValidity" class="btn btn--xl btn--primary" type="submit" :disabled="state === 'processing'">
        <span v-if="state === 'processing'">Processing…</span>
        <!-- <span v-else-if="state === 'error'">Retry</span> -->
        <!-- <span v-else>Process image</span> -->

        <!-- As long as this form bumps you straight into a PDNA search, make this button tell it like it is: -->
        <span v-else>
          <img src="/static/images/icons/icon-arrow-up-white.svg" class="file-drop__icon" width="20" alt="" aria-hidden="true">
          Upload
        </span>
      </button>
    </div>

  </form>
</template>

<script>
// import axios from 'axios'
// import Cookies from 'js-cookie'
import { validationMixin } from 'vuelidate'
import { required, requiredIf, between, not } from 'vuelidate/lib/validators'
import Clipboard from 'clipboard'

import Dropzone from './Dropzone'

const noWhitespace = (value) => /^\S*$/.test(value)

export default {
  name: 'FileUploadForm',
  mixins: [validationMixin],

  components: {
    Dropzone
  },

  props: {
    action: {
      type: String,
      required: true
    },
    referrer: {
      type: String,
      default: ''
    },
    csrfToken: {
      type: String,
      required: true
    },
    maxFiles: {
      type: Number,
      required: true
    },
    fileTypeDescription: {
      type: String,
      default: 'file'
    },
    maxFilesize: {
      type: Number,
      default: 20
    }
  },

  data() {
    return {
      files: [],
      totalFiles: 0,
      imageID: '',
      dzParams: {},
      errors: {},
      state: 'form',
      resetDropzone: false,
      accepted_mime: 'video/*'
    }
  },
  watch: {
    // reset_image_search(value){
    //   console.log("hit reset image search watchy");
    //   if(value === true){
    //     this.resetDropzone = true;
    //     //this.reset_image_search = false;
    //   }
    // }
  },
  computed: {
    showForm() { return this.state === 'form' },
    showProcessing() { return this.state === 'processing' },
    showSuccess() { return this.state === 'success' },
    showError() { return this.state === 'error' },
  },

  validations() {
    let v = {
      totalFiles: {
        required: between(1, parseInt(this.maxFiles))
      }
    }
    return v
  },

  methods: {
    checkValidity() {
      // console.log('checking form field validity')
      if (this.$v.$invalid) {
        this.$v.$touch()
        this.$nextTick(() => this.scrollToError())
        return
      }
      this.state = 'processing'
    },

    error(error) {
      this.state = 'error'
    },
    matchMIME(mime_pattern, mime_type) {
        // look for a wildcard to denote all types in the pattern
        if(mime_pattern.includes("*")){
            var arr_general_match = mime_pattern.split("/")
            var general_match = arr_general_match[0]
            var arr_matchable = mime_type.split("/")
            var matchable = arr_matchable[0]
            if(matchable == general_match){
                return true
            }
            else{
                return false
            }
        }
        else{
            if(mime_pattern == mime_type){
                return true
            }
            else{
                return false
            }
        }
    },
    dzError(file, message, xhr) {
      //console.log('heard dzError,', file, message, xhr)
      // now that we're filtering accepted files by mime type, I think we should output a more specific
      // message if that's why the error is being called.
      var uploaded_mime_type = file.type
      //console.log(uploaded_mime_type)
      if(uploaded_mime_type){
        //
        if(!this.matchMIME(this.accepted_mime, uploaded_mime_type)){
            // console.log("MIME no matchy")
            this.state = 'unacceptable_file_type_error'
        }
        else{
            // this is an error not related to accepted file type (mime type) so pass along the error state
            this.state = 'error'
        }
      }
      else{
        this.state = 'error'
      }
    },

    queuingFiles() {
      //console.log('heard vue-dz-queuingfiles')
    },

    queueComplete(file, response) {
      let name = file.name
      let response_status = response.status
      this.state = response_status
      this.files.push({
        name
      });
      if (response_status == 'success') {
        // show it's done?
        // this.resetDropzone = true; // this resets the upload form UI.
      }
    },
    success(response) {
      this.state = 'success'
    },

    updateTotalFiles(files) {
      this.totalFiles = files
    },

    resetUploader() {
      this.resetDropzone = false;
      this.state = 'form'
      this.$v.$reset()
      this.files = []
      //this.$set(this.dzParams, 'add_to_arachnid', false)
    },

    getFocusableEls() {
      const focusables = this.$el.querySelectorAll('a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]')
      return Array.prototype.slice.call(focusables)
    },

    scrollToError() {
      const errorEls = [...this.$el.querySelectorAll('.error')]
      const firstError = errorEls.filter(el => el.tagName.toUpperCase() !== 'LEGEND')[0]

      if (firstError) {
        const focusTarget = this.getFocusableEls().filter(formEl => firstError.contains(formEl))
        focusTarget[0].focus()
      }
    },
  },

  mounted() {
    // set up copy-to-clipboard listeners for when the eventual SHA-1/PDNA results get rendered
    let clipboard = new Clipboard('[data-clipboard-target]', {
      target: function(trigger) {
        return document.querySelector(trigger.dataset.clipboardTarget)
      }
    })
    window._paq.push(['FormAnalytics::scanForForms', this.$el])
  }
}

</script>
