<template>
  <span>
    <button
      :class="{ added: itemInWatchlist }"
      class="watchlist-btn"
      :disabled="itemInWatchlist"
      @click="toggleWatchlist"
    >
      {{ itemInWatchlist ? addedItem : itemToAdd }}
    </button>
    <a
      v-if="itemInWatchlist"
      :href="'/en/watchlist/user/index/?watchlist_type=' + stores[noteItem].type"
    >
      See Watchlist
    </a>
  </span>
</template>

<script>
import {addToWatchlist, removeFromWatchlist} from '../message_search/store/actions'

export default {
  name: 'AddItemWatchlist',
  props: {
    message: {
      type: Object,
      required: true
    },
    watchlistItemName: {
      type: String,
      required: true
    },
    watchlistItemId: {
      type: [Number, String],
      required: true
    },
    docId: {
      type: String,
      default: ''
    },
    siteType: {
      type: String,
      required: true
    },
    itemName: {
      type: String,
      required: true
    },
    siteName: {
      type: String,
      required: true
    },
    siteId: {
      type: [Number, String],
      required: true
    },
    noteItem: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      stores: {
        'chat site': {
          'store': 'site_watchlist',
          'add': 'addSiteToWatchlist',
          'remove': 'removeSiteWatchlist',
          'type': 'site'
        },
        'chat user': {
          'store': 'watchlist',
          'add': 'addUserToWatchlist',
          'remove': 'removeUserFromWatchlist',
          'type': 'username'
        },
        'forum': {
          'store': 'forum_watchlist',
          'add': 'addForumToWatchlist',
          'remove': 'removeForumWatchlist',
          'type': 'forum'
        },
        'site': {
          'store': 'site_watchlist',
          'add': 'addSiteToWatchlist',
          'remove': 'removeSiteWatchlist',
          'type': 'site'
        },
        'user': {
          'store': 'watchlist',
          'add': 'addUserToWatchlist',
          'remove': 'removeUserFromWatchlist',
          'type': 'username'
        },
        'Topic': {
          'store': 'topic_watchlist',
          'add': 'addTopicToWatchlist',
          'remove': 'removeTopicFromWatchlist',
          'type': 'topic'
        }
      }
    }
  },
  computed: {
    itemToAdd() {
      return `Watch ${this.itemName}`
    },
    addedItem() {
      return `Watching ${this.itemName}`
    },
    itemInWatchlist: {
      get() {
        return this.$store.state[this.stores[this.noteItem].store][this.itemName] || false
      },
      set(value) {
        this.$store.commit(value ? this.stores[this.noteItem].add : this.stores[this.noteItem].remove, this.itemName, this.siteId) // call function from mutation.js
      },
    },
  },
  created() {
    this.$store.dispatch('initializeWatchlistState', this.message)
  },
  methods: {
    async toggleWatchlist() {
      const watchlistType = this.stores[this.noteItem].type
      this.itemInWatchlist ? await this.removeFromWatchlist(watchlistType) : await this.addToWatchlist(watchlistType)
    },
    async addToWatchlist(watchlistType) {
      const response = await addToWatchlist(this.$store, this.message, this.watchlistItemName, this.watchlistItemId, this.docId, this.siteName, this.siteType, this.siteId, watchlistType)
      if (response) {
        this.itemInWatchlist = true
      }
    },
    async removeFromWatchlist(watchlistType) {
      const response = await removeFromWatchlist(this.$store, this.message, this.watchlistItemName, this.watchlistItemId, this.docId, this.siteName, this.siteType, this.siteId, watchlistType)
      if (response) {
        this.itemInWatchlist = false
      }
    }
  }
}
</script>

<style scoped>
.watchlist-btn {
  background-color: rgba(0, 0, 0, 0.9);
  position: static;
}
.watchlist-btn.added {
  background: #999;
}
.watchlist-btn:disabled {
  cursor: default;
}
</style>
