<template>
  <div>
    <div class="archives">
      <table class="responsive-table u-mb-sm">
        <tr class="u-fontsize-sm">
          <th>Archives</th>
          <th>Filename</th>
          <th>Media</th>
        </tr>
        <media-search-archive-media
            v-for="(archive, index) in archives" :key="index"
            :archive="archive"
            :media="media"
        />
      </table>
    </div>
  </div>
</template>

<script>
import MediaSearchArchiveMedia from "./MediaSearchArchiveMedia";

export default {
  name: "MediaSearchArchives",
  props: ["archives", "media"],
  components: {MediaSearchArchiveMedia}
}
</script>

<style>
.archives {
  overflow: auto;
  max-height: 100%;
}
</style>