<template>
  <div class="l-container--flex">
    <div class="l-container--flex">
      <UploadForm
        ref="video_upload_form"
        :csrf-token="csrf_token"
        :max-files="1"
        :max-filesize="4000"
        :file-type-description="'video'"
        action="upload_video"
        referrer=""
        @uploadComplete="finishUpload"
      />
    </div>
  </div>
</template>

<script>
import 'url-search-params-polyfill'
import axios from 'axios'
import UploadForm from './VideoUploadForm'

let serverURL = '/en/upload_video'

export default {
  name: 'VideoUpload',
  components: {
    UploadForm
  },
  props: {
    searchicon: {
      type: String,
      required: true
    },
    csrf_token: {
      type: String,
      required: true
    },
    forum_url: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      uploaded: false,
    }
  },
  methods: {
    resetVideoUpload() {
      this.$refs.video_upload_form.resetDropzone = true
      this.uploaded = false
    },
    finishUpload(value) {
      this.uploaded = true
      this.loading = false
      this.resetVideoUpload()
    }
  }
}
</script>
