<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    class="message__body"
    :class="rootclass"
  >
    <note-buttons
      v-if="messageType === 'chat'"
      :noteId="messageSource._id"
      :noteName="null"
      :noteLinked="messageSource._id"
      :noteItem="'chat message'"
      :noteSiteId="messageSource._source.site_id"
      :noteSiteName="messageSource._source.site_name"
      :hasNotes="messageSource._source.has_chat_message_notes"
      :indexType="indexType"
    />

    <note-buttons
      v-else-if="messageType === 'forum'"
      :noteId="messageSource._source.post_id"
      :noteName="messageSource._source.title"
      :noteLinked="messageSource._id"
      :noteSite="messageSource._source.site_id"
      :noteItem="'post'"
      :noteSiteId="messageSource._source.site_id"
      :noteSiteName="messageSource._source.site_name"
      :hasNotes="messageSource._source.has_post_notes"
      :indexType="indexType"
      :siteLink="siteLink"
    />
    <template v-if="isLongPost">
      <div
        :data-long-post-hidden="!showLongPost"
        class="message__body-inner"
        v-html="makeSourcesCopyable(messageSource._source.message)"
      />
      <div
        class="message__body-tldr"
        :data-long-post-hidden="!showLongPost"
      >
        <button
          ype="button"
          class="message__btn-tldr"
          @click.prevent="toggleLongPost"
        >
          <span class="btn btn--sm">
            {{ showLongPost ? gettext('Show less') : gettext('See full message') }}
          </span>
        </button>
      </div>
    </template>

    <template v-else-if="isKeywordPost">
      <div
        class="message__keywords"
        :data-long-post-hidden="!showLongPost"
      >
        <p class="u-mb-sm">
          <i>{{ gettext('Matching keywords in message:') }}</i>
        </p>
        <ul class="u-mb-0">
          <li
            v-for="(snippet, index) in messageSource.highlight.message"
            :key="index"
            class="message__keyword-result"
          >
            <span v-html="snippet" />
          </li>
        </ul>
      </div>
      <div
        v-show="showKeywordPost"
        class="message__body-inner"
        v-html="makeSourcesCopyable(messageSource._source.message)"
      />
      <div
        class="message__body-tldr"
        :data-long-post-hidden="!showLongPost"
      >
        <button
          type="button"
          class="message__btn-tldr"
          @click.prevent="toggleKeywordPost"
        >
          <span class="btn btn--sm">
            {{ showKeywordPost ? gettext('Hide message') : gettext('See full message') }}
          </span>
        </button>
      </div>
    </template>

    <div
      v-else
      v-html="makeSourcesCopyable(messageSource._source.message)"
    />
  </div>
</template>

<script>
import NoteButtons from '../notes/NoteButtons'

export default {
  name: 'MessageResultBody',
  components: {
    NoteButtons
  },
  props: {
    messageType: {
      type: String,
      default: ''
    },
    messageSource: {
      type: Object,
      default: () => {}
    },
    featured: {
      type: Boolean,
      default: false
    },
    indexType: {
      type: String,
      default: ''
    },
    siteLink: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      isLongPost: false,
      isKeywordPost: false,
      showLongPost: false,
      showKeywordPost: false,
      note: '',
      noteType: '',
      noteCategory: '',
    }
  },

  computed: {
    rootclass() {
      var classnames = []

      if (this.messageType === 'chat') {
        classnames.push('message__body--chat')
      } else {
        classnames.push('message__body--forum')
      }

      if (!this.featured) {
        classnames.push('u-fontsize-sm')
      }

      return classnames.join(' ')
    },
  },

  mounted() {
    this.isLongPost = this.$el.clientHeight > 500 && !this.messageSource.highlight

    this.isKeywordPost = this.$el.clientHeight > 500 && !!this.messageSource.highlight && this.messageSource.highlight.message.length > 0

    // Copies link href or image source to clipboard, if target has it available
    document.addEventListener('click', (e) => {
      if (!e.target.closest('.message__body')) return

      // need a bypass for this so that the notes dialog can render/operate correctly
      if (e.target.closest('.add-note-container') || e.target.closest('.edit-note-dialog')) return

      e.preventDefault()
      if (e.target.getAttribute('data-copy')) {
        navigator.clipboard.writeText(e.target.getAttribute('data-copy'))
      }
    })

    // The backend may have added some <div>s in this structure:
    //
    // <div class="c3p-encrypted">
    //   <div class="c3p-ciphertext">paihjfpqedirfhjpasdifhj</div>
    //   <div class="c3p-plaintext">This is the plaintext</div>
    // </div>
    //
    // By doing this CSS switcheroo, we can allow the user to toggle whether they want
    // to see the plaintext or ciphertext.  Normally they will want the plaintext, but
    // if the encryption detection code decrypts something that wasn't encrypted, they
    // will want to be able to recover the original content.
    for (let encryptedDiv of this.$el.getElementsByClassName('c3p-encrypted')) {
      encryptedDiv.setAttribute('title', 'Click to show encrypted/decrypted')
      encryptedDiv.addEventListener('click', (e) => {
        encryptedDiv.classList.toggle('show-ciphertext')
      })
    }
  },

  methods: {
    toggleLongPost(e) {
      this.$emit('toggle-post', this.showLongPost)
      this.showLongPost = !this.showLongPost
    },

    toggleKeywordPost(e) {
      this.$emit('toggle-post', this.showKeywordPost)
      this.showKeywordPost = !this.showKeywordPost
    },

    mangleSource(element) {
      // This is meant to mangle a DOM element in-place, so we shall return nothing
      let elSrc = element.currentSrc || element.src || ''
      let elHref = element.href || ''

      if (elSrc) {
        element.src = '#'
      }
      if (element.srcset) {
        // Just nuke it - if this attr exists, we'll have already stored the path
        // that the browser would have selected with element.currentSrc above
        element.srcset = '#'
      }
      if (elHref) {
        element.href = '#'
      }

      let sourceString = elSrc || elHref

      if (!sourceString.startsWith(window.location.origin)) {
        // If the src/href doesn't share a base url with the Arthropod:
        element.setAttribute('data-copy', sourceString)
        element.setAttribute('title', `Copy link: ${sourceString}`)
        element.setAttribute('aria-label', `Copy link: ${sourceString}`)
      }
    },

    removeAttributes(element) {
      // currently just remove style attribute, but we could probably allow-list this..
      // if we have a background image url, create a new empty image so that there is
      // something to hover over/see
      if (element.style.backgroundImage) {
        // a bit of nastiness to remove url() from this property...
        let sourceString = element.style.backgroundImage.slice(4, -1).replace(/"/g, '')
        // If the src/href doesn't share a base url with the Arthropod:
        if (!sourceString.startsWith(window.location.origin)) {
          let imgElement = this.createFakeImage(sourceString, 'Copy background image link: ')
          element.appendChild(imgElement)
        }
      }
      element.style.cssText = '' // just erase all styles, we don't need 'em
    },

    mangleVideo(element) {
      // I use appendChild in here for everything because at the end we switch it to a div anyway
      // console.log("mangleVideo: %o", element)
      // console.log("methods: %o", Object.getOwnPropertyNames(element))

      if (element.poster) {
        // console.log("poster=%o", element.poster)
        let posterElement = this.createFakeImage(element.poster, 'Copy poster image link: ')
        element.appendChild(posterElement)
        element.poster = '#'
      }
      // look for source(s)
      for (let videoSource of Array.from(element.getElementsByTagName('source'))) {
        // console.log("video source: %o", video_source)
        let sourceElement = this.createFakeImage(videoSource.src, 'Copy video source link: ')
        element.appendChild(sourceElement)
        element.removeChild(videoSource)
      }
      let divElement = document.createElement('div')
      divElement.innerHTML = element.innerHTML
      // console.log("final div: %o", div_element)
      element.parentNode.replaceChild(divElement, element)
    },

    mangleIframe(element) {
      let frameSrc = element.src

      let frameInfo = document.createElement('a')
      frameInfo.innerHTML = frameSrc

      if (!frameSrc.startsWith(window.location.origin)) {
        frameInfo.setAttribute('href', '#')
        // If the src/href doesn't share a base url with the Arthropod:
        frameInfo.setAttribute('data-copy', frameSrc)
        frameInfo.setAttribute('title', `Copy link: ${frameSrc}`)
        frameInfo.setAttribute('aria-label', `Copy link: ${frameSrc}`)
      }

      element.parentNode.replaceChild(frameInfo, element)
    },

    createFakeImage(url, textPrefix) {
      let imgElement = document.createElement('img')
      imgElement.src = '#'
      imgElement.setAttribute('data-copy', url)
      imgElement.setAttribute('title', textPrefix + url)
      imgElement.setAttribute('aria-label', textPrefix + url)
      return imgElement
    },

    makeSourcesCopyable(message) {
      let parser = new DOMParser()
      let parsedMessage = parser.parseFromString(message, 'text/html')

      for (let img of parsedMessage.images) {
        this.mangleSource(img)
      }
      for (let link of parsedMessage.links) {
        this.mangleSource(link)
      }
      // loop through all tags to remove any attributes we don't like
      for (let tag of parsedMessage.getElementsByTagName('*')) {
        if (tag.tagName.toUpperCase() === 'IFRAME') {
          this.mangleIframe(tag)
        }

        this.removeAttributes(tag)
      }
      // weird HTMLCollection...

      for (let video of Array.from(parsedMessage.getElementsByTagName('video'))) {
        this.mangleVideo(video)
      }

      let stringifiedMessage = new XMLSerializer().serializeToString(parsedMessage)

      return stringifiedMessage
    },

  },

}
</script>

<style>
.note-icon {
  width: 30px;
  height: auto;
}
.message__body .note-buttons {
  position: absolute;
  right: 10px;
}
.message__body img.note-icon {
  width: 30px !important;
  height: auto !important;
  opacity: 1;
  border: 0;
}
</style>
