import { render, staticRenderFns } from "./MediaSearchArchives.vue?vue&type=template&id=dcf180b8&"
import script from "./MediaSearchArchives.vue?vue&type=script&lang=js&"
export * from "./MediaSearchArchives.vue?vue&type=script&lang=js&"
import style0 from "./MediaSearchArchives.vue?vue&type=style&index=0&id=dcf180b8&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports