<template>

  <fieldset class="u-mb-0">
      <legend>
        Filter Search Results
      </legend>

      <div class="form-group u-mb-sm">
        <input id="images_filter_all" name="images_filter" class="images_filter_option" type="radio" value="all" v-model="filtertype">
        <label for="images_filter_all"><span class="u-weight-bold" v-if="filtertype == 'all'">All images</span><span v-else>All images</span></label>

        <input id="images_filter_sightings" name="images_filter" class="images_filter_option" type="radio" value="sightings" v-model="filtertype">
        <label for="images_filter_sightings"><span class="u-weight-bold" v-if="filtertype == 'sightings'">Images with sightings</span><span v-else>Images with sightings</span></label>

        <input id="images_filter_hashonly" name="images_filter" class="images_filter_option" type="radio" value="hashonly" v-model="filtertype">
        <label for="images_filter_hashonly"><span class="u-weight-bold" v-if="filtertype == 'hashonly'">Images without sightings (hash only)</span><span v-else>Images without sightings (hash only)</span></label>
      </div>
    </fieldset>
</template>

<script>
export default {
  name: 'ImageSearchFilterResults',
  props: {},
  data() {
    return {
      filtertype: 'all'
    }
  },
  watch: {
    filtertype:{
      immediate: true,
      handler(newVal, oldVal){
        if(oldVal != undefined){
          this.$emit("filterTrigger", newVal);
        }
      }
    }
  }
}
</script>
