<template>
  <p>
    <span class="topic-links-selected-tag" v-for="tag in newTags" :key="tag">{{ tag }} <button @click="deleteTag(tag)" type="button">&#x2716;</button></span>
    <span>
      <button class="topic-links-new-tag-btn" v-if="showAddTagBtn" @click="addTag">Add Tag +</button>
      <span class="topic-links-add-tag-input" v-if="showAddTagInput">
        <input class="topic-links-new-tag-input"
          ref="newTag"
          type="text"
          v-model="newTag"
          @keyup="tagSearch">
        <button
          class="topic-links-new-tag-btn"
          type="button"
          @click="addNewTag">
            Add
        </button>
        <ul v-if="filteredTags.length > 0" class="topic-links-filtered-tags">
          <li v-for="t in filteredTags" :key="t">
            <button @click="suggestedTag(t)">{{ t }}</button>
          </li>
        </ul>
      </span>
    </span>
  </p>
</template>

<script>
export default {
  name: 'TopicLinksTag',
  emits: ['newTag'],
  props: ['tags', 'fullTagList'],
  data() {
    return {
      showAddTagBtn: true,
      showAddTagInput: false,
      newTag: '',
      newTags: this.tags,
      filteredTags: []
    }
  },
  methods: {
    addTag() {
      this.showAddTagInput = true
      this.showAddTagBtn = false
      this.$nextTick(() => {
        this.$refs.newTag.focus()
      })
    },
    suggestedTag(tag) {
      this.newTag = tag
      this.addNewTag()
      this.filteredTags = []
    },
    addNewTag() {
      if (this.newTag != '') {
        if (!this.newTags.find(t => t === this.newTag)) {
          this.newTags.push(this.newTag)
        }
        this.returnTags()
        this.showAddTagBtn = true
        this.showAddTagInput = false
        this.newTag = ''
      }
    },
    deleteTag(tag) {
      this.newTags = this.newTags.filter((t) => {
        return t != tag
      })
      this.returnTags()
    },
    returnTags() {
      this.$emit('addNewTag', this.newTags)
    },
    tagSearch() {
      if (this.newTag.length > 3) {
        this.filteredTags = this.fullTagList.filter(t => {
          return t.includes(this.newTag)
        })
      }
    }
  }
}
</script>

<style>
.topic-links-new-tag-btn {
  padding: 5px;
  border-radius: 6px;
  font-size: 0.85em;
  color: #fff;
  background: #999999;
}
.topic-links-new-tag-input {
  width: 200px;
  border-radius: 6px;
  padding: 5px;
  height: 35px;
  vertical-align: bottom;
  font-size: 0.85em;
}
.topic-links-add-tag-input {
  position: relative;
}
.topic-links-filtered-tags {
  width: 200px;
  position: absolute;
  left: 0;
  list-style: none;
  margin-left: 0;
  border: 1px solid #cecbca;
}
.topic-links-filtered-tags li {
  padding: 5px;
}
.topic-links-filtered-tags li:hover {
  background: #999999;
  color: #fff;
}
.topic-links-filtered-tags li button {
  width: 100%;
  text-align: left;
}
</style>
