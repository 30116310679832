<template>
  <tr>
    <td>
      <button @click="copyToClipboard($event, archive.archive_sha1)">{{ archive.archive_sha1 | shortenString }} <img class="media-search-copy" title="Copy URL to clipboard" src="/images/icons/content_copy48.svg">
      </button>
    </td>
    <td  @click="copyToClipboard($event, archive.archive_sha1)" class="u-break">
      {{ archive.filename | shortenString }} <img class="media-search-copy" title="Copy URL to clipboard" src="/images/icons/content_copy48.svg">
    </td>
    <td class="u-break">
      <button class="note-item-btn" @click="getArchiveMedia">{{ btnText }}</button>
      <div :class="{'archives-hidden': hideMedia}" class="media-sha1">
        <div v-if="mediaDataError" class="alert alert--error">
          <p>{{ mediaDataError }}</p>
        </div>
        <ul v-else-if="mediaData && mediaData.length > 0" class="u-ml-0 u-mb-0">
          <li v-for="media_sha1 in mediaData">
            <a :href="mediaSearchLink(media_sha1)">
              <code class="u-p-0">{{ media_sha1 }}</code>
            </a>
          </li>
        </ul>
        <div v-else-if="mediaData && mediaData.length === 0" class="alert">
          <p>
            No media found
          </p>
        </div>
        <div v-else class="alert alert--info u-text-center">
          {{ gettext('Loading...') }}
        </div>
      </div>
    </td>
    <div
        v-if="copySuccess"
        ref="copySuccessMsg"
        class="text-copy-success"
        :style="{ top: copySuccessY + 'px', left: copySuccessX + 'px' }">
      <p>Copied to clipboard</p>
    </div>
  </tr>
</template>

<script>
import axios from 'axios'
import Cookies from 'js-cookie'

export default {
  name: "MediaSearchArchiveMedia",
  props: ["archive", "media"],
  data() {
    return {
      hideMedia: true,
      mediaData: null,
      mediaDataError: null,
      copySuccess: false,
      copySuccessX: null,
      copySuccessY: null
    }
  },
  methods: {
    copyToClipboard(e, text) {
      this.copySuccess = true
      this.copySuccessX = e.clientX + 20
      this.copySuccessY = e.clientY + 20
      navigator.clipboard.writeText(text)
      this.$nextTick(() => {
        this.$refs.copySuccessMsg.addEventListener('animationend', () => {
          this.copySuccess = false
        })
      })
    },
    mediaSearchLink(imgHash) {
      return `/en/media/?sha1=${imgHash}`
    },
    getArchiveMedia() {
      this.hideMedia = !this.hideMedia
      const csrftoken = Cookies.get('csrftoken')
      axios.get('/en/media/get_archive_media/', {params: {'archive_sha1': this.archive.archive_sha1}}, {'headers': {'X-CSRFToken': csrftoken}}).then(response => {
        if (response.status === 200) {
          this.mediaData = response.data
        }
      }).catch((error) => {
        this.mediaDataError = error
      })
    }
  },
  computed: {
    btnText: function() {
      return (this.hideMedia) ? "[Show +]" : "[Hide -]";
    }
  }
}
</script>

<style>
.media-sha1 {
  overflow: auto;
  max-height: 10rem;
}
.media-sha1 ul {
  list-style: none;
}
.archives-hidden {
  overflow: hidden;
  max-height: 0;
}
.media-search-copy {
  max-width: 24px;
  max-height: 24px;
  margin-left: 4px;
}
.text-copy-success {
  position: fixed;
  background: #e2ce66;
  border: 1px solid #000;
  padding: 10px 20px;
  animation-name: fadeOutSuccess;
  animation-duration: 1s;
  animation-delay: 3s;
}
.text-copy-success p {
  margin-bottom: 0;
}
@keyframes fadeOutSuccess {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>