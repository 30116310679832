<template>
  <div
    v-if="usernote_list.length>0"
    class="usernote-container"
    @click.self="$emit('closed', false)"
  >
    <div class="usernote-popup">
      <button
        class="usernote-close"
        @click="$emit('closed', false)"
      >
        &#x2716;
      </button>
      <p>Notes for: {{ usernote_list[0].choice_name }}</p>
      <div class="notes-container">
        <div class="notes-header">
          <div>Author</div>
          <div>Note</div>
          <div>Date Created</div>
          <div>Date Updated</div>
          <div>Visibility</div>
          <div />
        </div>
        <div class="notes-items">
          <div
            v-for="(item, index) in usernote_list"
            :key="index"
            class="notes-item"
          >
            <div>{{ item.organization }}</div>
            <div>
              <note-item
                :originalNote="item.choice_note"
                :noteLength="99"
              />
            </div>
            <div>{{ item.date_noted }}</div>
            <div>{{ item.date_updated }}</div>
            <div>
              <img
                v-if="item.choice_display_type === 'PR'"
                class="note-type-icon"
                src="/static/images/icons/private-note.svg"
                title="Private Note"
              >
              <img
                v-if="item.choice_display_type === 'PB'"
                class="note-type-icon"
                src="/static/images/icons/public-note.svg"
                title="Public Note"
              >
            </div>
            <div>
              <edit-note-popup
                v-if="item.current_logged_user === item.user_id_id"
                :note_id="item.id"
                :note="item.choice_note"
                :visibility="item.choice_display_type"
                :date_created="item.date_noted"
                :date_updated="item.date_updated"
                @edit-note="edit_note"
              />
              <button
                v-if="item.choice_display_type === 'PB' && item.user_id_id === item.current_logged_user"
                class="notes-delete-btn"
                title="Delete"
                @click="showDisableConfirmBtn(item.id, index)"
              >
                <img src="/images/icons/delete_black_48dp.svg">
              </button>
              <button
                v-else-if="item.choice_display_type === 'PR'"
                title="Delete"
                class="notes-delete-btn"
                @click="showDeleteConfirmBtn(item.id, index)"
              >
                <img src="/images/icons/delete_black_48dp.svg">
              </button>
              <button
                v-else
                class="notes-contact-btn"
                title="Contact"
                @click="setContactForm(item.user_id_id, item.login_user_email, item.user_name, item.choice_name, item.note_on, item.choice_note, item.choice_id, item.choice_display_type)"
              >
                <img src="/images/icons/mail.svg">
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="confirmDelete"
        class="notes-confirm-delete"
      >
        <p>Are you sure you want to permanently remove this note?</p>
        <p>
          <button
            class="notes-confirm-btn"
            @click="delete_note()"
          >
            Confirm Delete
          </button>
          <button
            class="notes-cancel-btn"
            @click="confirmDelete = false"
          >
            Cancel
          </button>
        </p>
      </div>
      <div
        v-if="confirmDisable"
        class="notes-confirm-delete"
      >
        <p>Are you sure you want to permanently remove this note?</p>
        <p>This note is visible to all users.</p>
        <p>
          <button
            class="notes-confirm-btn"
            @click="disable_note()"
          >
            Confirm Delete
          </button>
          <button
            class="notes-cancel-btn"
            @click="confirmDisable = false"
          >
            Cancel
          </button>
        </p>
      </div>
      <div v-if="showContactForm">
        <UserNoteContactForm
          :userId="userId"
          :userEmail="userEmail"
          :noteUserName=" noteUserName"
          :indexType="indexType"
          :noteSiteName="noteSiteName"
          :itemType="itemType"
          :noteItemContent="noteItemContent"
          :noteContent="noteContent"
          :siteLink="siteLink"
          :noteDisplayType="noteDisplayType"
          @closeContact="showContactForm = false"
        />
      </div>
      <p>
        <button
          class="add-note-btn"
          @click="$emit('add', {'noteId': usernote_list[0].choice_id , 'noteName': usernote_list[0].choice_name, 'noteItem': usernote_list[0].note_on})"
        >
          Add Note
        </button>
      </p>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Cookies from 'js-cookie'
import UserNoteContactForm from './UserNoteContactForm'
import NoteItem from './NoteItem'
import EditNotePopup from './EditNotePopup'

export default {
  name: 'UserNote',
  components: {UserNoteContactForm, NoteItem, EditNotePopup},
  props: {
    usernotes: {
      type: Array,
      default: () => []
    },
    indexType: {
      type: String,
      default: null
    },
    noteSiteName: {
      type: String,
      default: null
    },
    siteLink: {
      type: String,
      default: null
    }
  },
  emits: ['closed'],
  data() {
    return {
      usernote_list: [],
      showContactForm: false,
      userId: null,
      userEmail: null,
      noteUserName: null,
      expandBtn: false,
      confirmDelete: false,
      deleteId: null,
      deleteIndex: null,
      confirmDisable: false,
      disableId: null,
      disableIndex: null,
      itemType: null,
      noteItemContent: null,
      noteContent: null,
      noteDisplayType: null,
    }
  },
  watch: {
    usernotes(newNotes, oldNotes) {
      this.getUserNotes()
    }
  },
  methods: {
    edit_note(args) {
      if (args['newNote'] !== 'cancel') {
        var noteData = {
          note_id: args['noteId'],
          new_note: args['newNote'],
          new_visibility: args['newVisibility']
        }

        const csrftoken = Cookies.get('csrftoken')
        axios.post('/en/message-search/notes/change/', noteData, {'headers': {'X-CSRFToken': csrftoken}}).then(
          resp => {
            console.log('got a response! %o', resp)
            if (resp.status === 200 && resp.data.note_changed === 'Success') {
              for (const obj in this.usernote_list) {
                if (this.usernote_list[obj].id === args['noteId']) {
                  this.usernote_list[obj].choice_note = args['newNote']
                  this.usernote_list[obj].choice_display_type = args['newVisibility']
                  var dateEdited = new Date()
                  var noteEditDatetime = dateEdited.toLocaleString('en-GB').replace(',', '')
                  this.usernote_list[obj].date_updated = noteEditDatetime
                }
              }
            }
          }
        ).catch(
          err => { console.log('something went wrong! %o', err) }
        )
      }
    },
    closeUserNotePage() {
      this.$emit('closed', false)
    },
    showDisableConfirmBtn(id, index) {
      this.disableId = id
      this.disableIndex = index
      this.confirmDisable = true
    },
    showDeleteConfirmBtn(id, index) {
      this.deleteId = id
      this.deleteIndex = index
      this.confirmDelete = true
    },
    setContactForm(id, email, name, noteItemContent, itemType, noteContent, choiceId, displayType) {
      this.showContactForm = true
      this.userId = id
      this.userEmail = email
      this.noteUserName = name
      this.itemType = itemType
      if (itemType.toLowerCase() === 'media') {
        this.noteItemContent = choiceId // item_id
      } else if (itemType.toLowerCase() === 'chat message') {
        this.noteItemContent = new URL('/en/message-search/chat-sites/' + this.noteSiteName + '/' + choiceId + '/', document.baseURI).href
      } else {
        this.noteItemContent = noteItemContent // item_name
      }
      this.noteContent = noteContent
      this.noteDisplayType = displayType
    },
    disable_note() {
      this.usernote_list.splice(this.disableIndex, 1)
      let note = {
        'pk': this.disableId
      }
      const csrftoken = Cookies.get('csrftoken')
      axios.post('/en/message-search/notes/disable/', note, {'headers': {'X-CSRFToken': csrftoken}})
      this.disableId = null
      this.disableIndex = null
      this.confirmDisable = false
      if (this.usernote_list.length === 0) {
        this.$emit('listEmpty')
      }
    },
    getUserNotes() {
      this.usernote_list = []
      for (let i = 0; i < this.usernotes.length; i++) {
        this.usernote_list.push(this.usernotes[i])
      }
    },
    delete_note() {
      this.usernote_list = this.usernote_list.filter(note => note.id !== this.deleteId)
      let note = {
        'pk': this.deleteId
      }
      const csrftoken = Cookies.get('csrftoken')
      axios.post('/en/message-search/notes/delete/', note, {'headers': {'X-CSRFToken': csrftoken}})
      this.deleteId = null
      this.deleteIndex = null
      this.confirmDelete = false
      if (this.usernote_list.length === 0) {
        this.$emit('listEmpty')
      }
    },
  }

}
</script>

<style>
.usernote-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background: rgba(0,0,0,0.2);
  line-height: 1.5;
}
.usernote-popup {
  position: fixed;
  top: 200px;
  z-index: 11;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  width: 60vw;
  max-height: 50vh;
  background: #f5f5f4;
  padding: 20px 30px;
  border: 2px solid #cecbca;
  border-radius: 6px;
  box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.2);
}
.usernote-container table {
  overflow-y: scroll;
  height: 200px;
  display: block;
}
.usernote-close {
  position: absolute;
  top: 5px;
  right: 10px;
}
.notes-header {
  display: flex;
}
.notes-header > div {
  flex: 1;
  font-weight: bold;
  padding: 5px;
}
.notes-items {
  overflow-y: scroll;
  height: calc(60vh - 300px);
}
.notes-item {
  display: flex;
  width: 100%;
}
.notes-item:nth-child(odd) {
  background: #fff;
}
.notes-item > div {
  flex: 1;
  padding: 10px 5px;
}
.notes-header > div:first-child,
.notes-item > div:first-child {
  flex: 0 0 100px;
}
.notes-header > div:nth-child(2),
.notes-item > div:nth-child(2) {
  flex: 0 0 400px;
}
.notes-item > div:nth-child(4) {
  padding-left: 10px;
}
.notes-item > div:nth-child(5) {
  padding-left: 30px;
}
.notes-item > div:last-child {
  text-align: center;
}
.notes-cancel-btn{
  border-radius: 5px;
  padding: 5px;
  background: #fff;
  width: 76px;
}
.notes-cancel-btn {
  color: #6b6b6b;
  border: 1px solid #6b6b6b;
}
.notes-delete-btn {
  width: 36px;
  margin-left: 20px;
}
.notes-contact-btn {
  width: 36px;
}
.message__body .notes-contact-btn img,
.message__body .notes-delete-btn img {
  width: 36px !important;
  height: auto !important;
  opacity: 1;
  border: 0;
}
.notes-confirm-delete {
  width: 360px;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border: 2px solid #cecbca;
  border-radius: 6px;
  text-align: center;
  padding: 6px 6px;
}
.notes-confirm-delete p {
  margin: 8px auto;
  width: 90%;
}
.notes-confirm-btn {
  color: #fff;
  background-color: #c22c3f;
  border-radius: 5px;
  padding: 5px;
}
.note-type-icon {
  width: 30px;
}

/*Need to override the !important declarations from the main stylesheet that address inline images*/
.message__body img.note-type-icon {
  width: 30px !important;
  height: 30px !important;
  opacity: 1 !important;
  border: none !important;
}

.u-break .usernote-container {
  word-break: break-word;
}
</style>
