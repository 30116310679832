<template>
  <div class="media-container">
    <button
      v-if="mediaItem.media.length > 0"
      class="btn btn--sm open-details-btn show-media-btn"
      :disabled="disable"
      @click="isHidden = !isHidden; $emit('popup', true)"
    >
      Show Media
    </button>
    <div
      v-if="!isHidden"
      class="media-items"
    >
      <a
        class="close-media"
        href="#"
        @click.prevent="isHidden = !isHidden; $emit('popup', false)"
      >&#x2716;</a>
      <div
        v-for="(item, index) in mediaItem.media"
        :key="index"
      >
        <p><strong>sha1:</strong><a :href="mediaSearchLink(item.sha1)"> {{ item.sha1 }}</a></p>
        <p><strong>classification:</strong> {{ item.classification }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserDetailMedia',
  props: {
    mediaItem: {
      type: Object,
      required: true
    },
    mediaSearchUrl: {
      type: String,
      required: true
    },
    disable: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isHidden: true
    }
  },
  methods: {
    mediaSearchLink(imgHash) {
      return `${this.mediaSearchUrl}?sha1=${imgHash}`
    },
  }
}
</script>

<style scoped>
.media-items {
  background: #fff;
  border: 1px solid #000;
  border-radius: 6px;
  position: absolute;
  top: 100px;
  right: 25px;
  z-index: 10;
  padding: 20px;
  overflow: scroll;
  line-height: normal;
  width: 500px;
  max-height: calc(100% - 120px);
  text-align: left;
}
.media-items > div {
  border-bottom: 2px solid rgb(230, 229, 229);
  margin-bottom: 8px;
  padding-top: 1.25rem;
}
.media-items > div:last-child {
  border-bottom: 1.25rem;
  margin-bottom: 0;
}
.close-media {
  position: absolute;
  top: 5px;
  right: 10px;
  color: #000;
  font-weight: bold;
  text-decoration: none;
  line-height: normal;
}
.show-media-btn:disabled {
  background: #c22c3f;
  color: #fff;
}
</style>
