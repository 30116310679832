<template>
  <div :id="id" class="file-drop" :class="{'file-drop--error': filesVuelidate.$error}">
    <p v-if="filesVuelidate.$error && !filesVuelidate.required && filesVuelidate.$model < maxFilesCnt" class="alert alert--error">
      Please add a file.
    </p>
    <p v-if="filesVuelidate.$model > maxFilesCnt" class="alert alert--error">
      Please include no more than {{maxFilesCnt}} {{ fileTypeDescription }}<template v-if="maxFilesCnt > 1">s</template>.
    </p>

    <!-- handling the .file-drop__text element's visibility with a v-if breaks the fileDropBrowse button's clickability,
         so the CSS display rule gets toggled instead -->
    <div class="file-drop__text" :style="{'display': maxFilesReached ? 'none' : 'table'}">
      <div style="display:table-cell;vertical-align:middle;">
        <svg class="file-drop__files-img" xmlns="http://www.w3.org/2000/svg" width="70" height="60" viewBox="-20 0 155 134">
          <path class="page-back" d="M87 130H4V24h12v95h71z"/>
          <path class="page-front" d="M97 91h-8V71H69V41h20V21h15V4H26v105h78V91z"/>
          <path class="plus" d="M111 83V63h20V49h-20V29H97v20H77v14h20v20z"/>
        </svg>
      </div>
      <div style="display:table-cell;vertical-align:middle;">
        <p class="file-drop__text-inner">
          Drag and drop a {{ fileTypeDescription }} here, or
        </p>
        <label for="file-drop-input" ref="fileDropBrowse">
          <button class="btn btn--dark file-drop__browse-btn" type="button" :disabled="disableAdding">
            Select {{ fileTypeDescription }}…
          </button>
        </label>
      </div>
    </div>

    <div v-if="totalFiles >= 3" class="file-drop__remove-all">
      <button class="btn btn--sm btn--warning" @click="removeAll" type="button" :disabled="disableAdding">
        Remove all
      </button>
    </div>
    <ul class="file-drop__list" ref="previewsContainer"></ul>
    <input type="file" id="file-drop-input" class="dz-hidden-input"
      style="visibility: hidden; position: absolute; top: 0px; left: 0px; height: 0px; width: 0px;">
  </div>
</template>

<script>
import Dropzone from 'dropzone'

Dropzone.autoDiscover = false

const filePreviewTemplate = `<li class="file-drop__file">
  <div class="file-drop__details">
    <p class="file-drop__file-name" data-dz-name></p>
    <p class="file-drop__file-size" data-dz-size></p>
    <p class="file-drop__file-remove">
      <button class="btn btn--sm btn--warning" type="button" data-dz-remove>
        <svg class="file-drop__icon file-drop__icon--remove" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="20" aria-hidden="true">
          <path d="M90 20L80 10 50 40 20 10 10 20l30 30-30 30 10 10 30-30 30 30 10-10-30-30z"/>
        </svg>
        <span class="u-vh">Remove</span>
      </button>
      <svg class="file-drop__icon file-drop__icon--success" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="20" aria-hidden="true" hidden>
        <path d="M38 87L4 54l14-15 20 21 43-44 14 14z"/>
      </svg>
      <svg class="file-drop__icon file-drop__icon--attention" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="20" aria-hidden="true" hidden>
        <path d="M50 1C22.9 1 1 22.9 1 50s21.9 49 49 49 49-21.9 49-49S77.1 1 50 1zm8 82c0 1.3-.7 2.4-2 3s-3.1 1-6 1-4.7-.4-6-1-2-1.7-2-3v-9c0-1.3.7-2.4 2-3s3.1-1 6-1 4.7.4 6 1 2 1.7 2 3v9zm1-66l-4 42c-.1.9-1 3-5 3s-4.9-2.1-5-3l-4-42c-.1-1.1 0-2 1-3s1.8-1 3-1h10c1.2 0 2 0 3 1s1.1 1.9 1 3z"/>
      </svg>
    </p>
  </div>
  <p class="file-drop__file-error form-error" aria-live="polite"><b data-dz-errormessage></b></p>
  <div class="file-drop__file-progress">
    <span class="file-drop__progress" data-dz-uploadprogress></span>
  </div>
</li>`

export default {
  name: 'Dropzone',

  props: {
    id: {
      type: String,
      required: true,
      default: 'file-drop'
    },
    url: {
      type: String,
      required: true
    },
    formState: {
      type: String,
      required: true
    },
    filesVuelidate: {
      type: Object,
      required: false
    },
    maxFilesCnt: {
      type: Number,
      required: true
    },
    maxFilesize: {
      type: Number
    },
    resetDropzone: {
      type: Boolean,
      required: false
    },
    csrfToken: {
      type: String,
      required: false
    },
    xtraParams: {
      type: Object,
      required: false
    },
    acceptedMIME: {
      type: String
    },
    fileTypeDescription: {
      type: String,
      default: 'file'
    },
  },

  data() {
    return {
      fileDropzone: {},
      totalFiles: 0,
      disableAdding: false,
      dropzoneOptions: {
        url: this.$props.url,
        autoQueue: false,
        autoProcessQueue: false,
        uploadMultiple: true,
        parallelUploads: 50,
        timeout: 3600000,
        maxFiles: this.$props.maxFilesCnt,
        maxfilesexceeded: function(file) {
          this.removeFile(file)
        },
        maxFilesize: parseInt(this.$props.maxFilesize), // megabytes
        previewTemplate: filePreviewTemplate,
        headers: { 'X-CSRFToken': this.$props.csrfToken },
        params: this.$props.xtraParams,
        acceptedFiles: this.$props.acceptedMIME
      }
    }
  },

  computed: {
    dropzoneSettings() {
      return {
        previewsContainer: this.$refs.previewsContainer,
        clickable: this.$refs.fileDropBrowse,
        ...this.dropzoneOptions
      }
    },
    maxFilesReached() {
      return this.totalFiles >= this.$props.maxFilesCnt
    }
  },

  watch: {
    maxFilesCnt(value) {
      this.dropzoneOptions.maxFiles = value
    },
    acceptedMIME(value) {
      this.dropzoneOptions.acceptedFiles = value
    },
    formState(state) {
      if (state === 'processing' && this.totalFiles >= 1) {
        let erroredFiles = this.fileDropzone.getFilesWithStatus(Dropzone.ERROR)

        if (erroredFiles.length) {
          // console.log('found errored files already! stop the train')
          this.$emit('vue-dz-erroredFiles', erroredFiles)
          return
        }

        this.$emit('vue-dz-queuingfiles')
        this.queueFiles()

        if (erroredFiles.length) {
          // console.log('found post-queue errored files')
          this.$emit('vue-dz-erroredFiles', erroredFiles)
        }
      }
      else if (state === 'error' && this.totalFiles >= 1) {
      }

      this.disableAdding = (state === 'processing' || state === 'success' || state === 'arachnidsuccess') ? true : false
    },

    totalFiles(files) {
      this.$emit('vue-dz-totalfiles', files)
    },

    resetDropzone(reset) {
      if (reset) this.handleDropzoneReset()
    },
    xtraParams: {
      handler(opts) {
        this.dropzoneOptions.params = opts
      },
      deep: true
    }
  },

  methods: {
    duplicateFileCheck(dz, files, currentFile) {
      // based on vue-dropzone's duplicate-checking function
      if (files.length) {
        for (
          let i = 0;
          i < files.length - 1;
          i++ // -1 to exclude current file
        ) {
          if (
            files[i].name === currentFile.name &&
            files[i].size === currentFile.size &&
            files[i].lastModified.toString() === currentFile.lastModified.toString()
          ) {
            dz.removeFile(files[i])
            this.totalFiles = files.length
            this.$emit('vue-dz-duplicate-file', files[i])
          }
        }
      }
    },

    queueFiles() {
      let toQueue = this.fileDropzone.getFilesWithStatus(Dropzone.ADDED)
      this.fileDropzone.enqueueFiles(toQueue)
      this.fileDropzone.processQueue()
    },

    removeAll() {
      let vm = this
      this.fileDropzone.removeAllFiles()
      vm.$emit('vue-dz-removedall')
    },

    initDropzone() {
      this.fileDropzone = new Dropzone(
        document.body,
        this.dropzoneSettings
      )
      // console.log('data options: ', this.dropzoneOptions)
      let vm = this

      this.fileDropzone.on('dragenter', function(e) {
        if (vm.formState === 'success') {
          // then someone's trying to drag in more files without resetting
          // the dropzone via the "upload more files" button, so:
          vm.handleDropzoneReset()
        }
      })

      this.fileDropzone.on('addedfile', function(file) {
        let fileLimit;
        vm.duplicateFileCheck(this, this.files, file)
        vm.totalFiles = this.files.length
        if (vm.$props.acceptedMIME === 'text/csv,application/vnd.ms-excel') {
          fileLimit = 1;
        } else {
          fileLimit = 5;
        }
        if (this.files.length > fileLimit) {
          this.removeFile(this.files[this.files.length-1]);
        }
        file.previewElement.querySelector('.file-drop__file-progress').classList.remove('hide')
        vm.$emit('vue-dz-checkstate', this.files)
      })

      this.fileDropzone.on('removedfile', function(file) {
        vm.totalFiles = this.files.length
        vm.$emit('vue-dz-checkstate', this.files)

      })

      this.fileDropzone.on('error', function(file, message, xhr) {
        // for each errored file
        file.previewElement.querySelector('[data-dz-remove]').removeAttribute('disabled')
        file.previewElement.querySelector('.file-drop__file-progress').classList.add('hide')
        vm.$emit('vue-dz-error', file, message, xhr, this.files.length)
      })

      this.fileDropzone.on('processing', function(file) {
        file.previewElement.querySelector('[data-dz-remove]').setAttribute('disabled', 'disabled')
        file.previewElement.querySelector('.file-drop__file-progress').classList.remove('hide')
        vm.$emit('vue-dz-processing', file)
      })

      this.fileDropzone.on('processingmultiple', function(file) {
        vm.$emit('vue-dz-processingmultiple', file)
      })

      this.fileDropzone.on('uploadprogress', function(file, progress, bytesSent) {
        file.previewElement.querySelector('[data-dz-uploadprogress]').style.width = `${progress}%`
        vm.$emit('vue-dz-uploadprogress', file, progress, bytesSent)
      })

      this.fileDropzone.on('success', function(file, response) {
        var response_status = response.status;
        file.previewElement.querySelector('[data-dz-remove]').style.display = 'none'
        file.previewElement.querySelector('.file-drop__file-progress').classList.add('hide')
        if(response_status == 'error'){
          file.previewElement.querySelector('.file-drop__icon--attention').removeAttribute('hidden');
          vm.$emit('vue-dz-error', file, response.message, file.xhr);
        }
        else{
          file.previewElement.querySelector('.file-drop__icon--success').removeAttribute('hidden')
          vm.$emit('vue-dz-success', file, response)
        }
      })

      this.fileDropzone.on('successmultiple', function(files) {
        vm.$emit('vue-dz-successmultiple', files)
      })

      this.fileDropzone.on('queuecomplete', function(progress) {
        vm.$emit('vue-dz-queuecomplete', progress)
      })
    },

    handleDropzoneReset() {
      // this.fileDropzone.removeAllFiles()
      this.fileDropzone.destroy()
      this.initDropzone()
      this.$emit('vue-dz-reset')
    },

    requeueFiles(files) {
      for (let i = 0; i < files.length; i++) {
        let file = files[i]
        file.status = Dropzone.ADDED
        file.upload.progress = 0
        file.upload.bytesSent = 0
        // console.log('requeuing file:', file)
      }
    }

  },

  mounted() {
    this.initDropzone()
  },

  beforeDestroy() {
    this.fileDropzone.destroy()
  }

}
</script>
