<script>
import axios from 'axios'
import Cookies from 'js-cookie'

export default {
  name: 'RequestDemoForm',

  props: {
    action: {
      type: String,
      required: true,
    },
    referrer: {
      type: String,
      default: '',
    },
    current_date: {
      type: String,
    }
  },

  data() {
    return {
      first_demo_date: '',
      second_demo_date: '',
      third_demo_date: '',
      notes: '',
      errors: {},
      first_demo_date_error: false,
      second_demo_date_error: false,
      third_demo_date_error: false,
      showSuccess: false
    }
  },

  watch: {
    first_demo_date() {
      if (this.first_demo_date < this.$props.current_date) {
        this.first_demo_date_error = true
      } else {
        this.showSuccess = false
        this.first_demo_date_error = false
      }
    },
    second_demo_date() {
      if (this.second_demo_date < this.$props.current_date) {
        this.second_demo_date_error = true
      } else {
        this.showSuccess = false
        this.second_demo_date_error = false
      }
    },
    third_demo_date() {
      if (this.third_demo_date < this.$props.current_date) {
        this.third_demo_date_error = true
      } else {
        this.showSuccess = false
        this.third_demo_date_error = false
      }
    },
    notes() { this.showSuccess = false },
  },

  methods: {
    submit() {
      this.showSuccess = false
      this.errors = {}
      const data = {
        first_demo_date: this.first_demo_date,
        second_demo_date: this.second_demo_date,
        third_demo_date: this.third_demo_date,
        notes: this.notes,
        referrer: this.referrer,
      }

      if (!this.first_demo_date_error && !this.second_demo_date_error && !this.third_demo_date_error) {
        const csrftoken = Cookies.get('csrftoken')
        axios.post(this.action, data, {'headers': {'X-CSRFToken': csrftoken}}).then(
            this.success,
            this.error
        )
      }
    },

    error(error) {
      this.errors = error.response.data.errors
    },

    success(response) {
      this.first_demo_date = ''
      this.second_demo_date = ''
      this.third_demo_date = ''
      this.notes = ''
      this.first_demo_date_error = false
      this.second_demo_date_error = false
      this.third_demo_date_error = false
      // Resetting the data above will cause showSuccess to be set false next
      // tick, so set it true using $nextTick rather than immediately
      this.$nextTick(function() { this.showSuccess = true })
    },

  },
}
</script>
