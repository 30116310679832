<template>
  <button v-if="showButton" class="back-to-top-button" @click="scrollToTop" title="Back To Top">
    <img class="back-to-top-icon" src="/images/icons/icon-arrow-up-white.svg" alt="Back to top">
  </button>
</template>

<script>
export default {
  name: "BackToTopButton",
  data() {
    return {
      showButton: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const scrollPosition = window.scrollY || document.documentElement.scrollTop;
      this.showButton = scrollPosition > 600;
    },
    scrollToTop() {
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    },
  },

};
</script>
