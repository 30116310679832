<template>
  <div class="topic-links">
    <h1>Locations of Interest
      <button v-if="userCanEdit" class="topic-links-add-btn" @click="addURL()">Add</button>
    </h1>
    <div class="topic-links-tag-container" v-if="sortedByTag">
      Sorted by: <span class="topic-links-selected-tag" v-for="tag in selectedTags" :key="tag">
        {{ tag }} <button @click="removeTag(tag)" type="button">&#x2716;</button>
      </span>
    </div>
    <div v-if="list_data.length!==0">
      <div class="topic-links-header">
        <div><button class="topic-links-sort-btn" :class="{ 'asc': statusDir === 'asc', 'desc': statusDir === 'desc' }" @click="statusSort">Status</button></div>
        <div>Crawled</div>
        <div>Title</div>
        <div>URL/Location</div>
        <div>Notes</div>
        <div>Last Update</div>
        <div v-if="userCanEdit" class="topic-links-btn-col"></div>
        <div class="topic-links-btn-col"></div>
        <div v-if="userCanEdit" class="topic-links-btn-col"></div>
        <div>Tags</div>
      </div>
      <div class="topic-link-items">
        <div class="topic-links-item" v-for="(item, index) in list_data" :key="index">
          <div><img class="topic-links-updown-icon" v-if="item.site_status_type === 'D'" src="/images/icons/topic-list-status-down.svg"><img class="topic-links-updown-icon" v-if="item.site_status_type === 'U'" src="/images/icons/topic-list-status-up.svg"></div>
          <div><img class="topic-list-scraped-icon" v-if="item.has_scraped === false" src="/images/icons/topic-list-scraped-false.svg"><img class="topic-list-scraped-icon" v-if="item.has_scraped === true" src="/images/icons/topic-list-scraped-true.svg"></div>
          <div>{{ item.title }}</div>
          <div>
            <button @click="copyToClipboard($event, item.url)">{{ item.url }} <img class="topic-links-copy" title="Copy URL to clipboard" src="/images/icons/content_copy48.svg">
            </button>
          </div>
          <div class="topic-links-item-notes">{{ shortenNote(item.notes, 250) }}
            <button v-if="item.notes.length >= 250" class="topic-link-show-all-btn" @click="topicNotePopupText = item.notes">Show All</button>
          </div>
          <div>{{ item.last_updated_date }}</div>
          <div v-if="userCanEdit" class="topic-links-btn-col">
            <button @click="confirmDelete(item.id, index)" title="Delete"><img class="topic-links-icon" src="/images/icons/delete_black_48dp.svg">
            </button>
          </div>
          <div class="topic-links-btn-col">
            <button @click="viewHistory(item.id)" title="View History"><img class="topic-links-icon" src="/images/icons/history_black_48dp.svg">
            </button>
          </div>
          <div v-if="userCanEdit" class="topic-links-btn-col">
            <button @click="updateNotes(item.url, index)" title="Edit"><img class="topic-links-icon" src="/images/icons/edit_black_48dp.svg">
            </button>
          </div>
          <div>
            <span v-for="(tag, index) in item.tags" :key="index"><a class="topic-links-tag" @click.prevent="addTag(tag)" href="#">{{ tag }}</a><span v-if="index < item.tags.length - 1">,&nbsp;</span></span>
          </div>
        </div>
        <div
            v-if="copySuccess"
            ref="copySuccessMsg"
            class="url-copy-success"
            :style="{ top: copySuccessY + 'px', left: copySuccessX + 'px' }">
          <p>URL copied to clipboard</p>
        </div>
      </div>
    </div>
    <div v-else>
      <p class="empty-list">Your list is empty.<span v-if="userCanEdit"> Click
        <button class="empty-list-btn" @click="addURL()">ADD</button>
        to add your first link.</span>
      </p>
    </div>
    <div class="topic-links-add-url" v-if="addUrl && userCanEdit">
      <label class="u-weight-bold" for="url">Add URL:</label>
      <input
          type="url"
          ref="url"
          v-model="url"
          @keyup="urlEnter">
      <p class="error" v-if="urlError">Please enter a URL</p>
      <p>
        <button class="cancel-btn" type="button" @click="addUrl = false; urlInValid= false; urlError = false">Cancel
        </button>
        <button class="add-btn" type="button" @click="check_url()">Add URL</button>
      </p>
    </div>
    <div class="topic-links-url-notes" v-if="addNotes && !same_url && userCanEdit">
      <p>Add notes for: <strong>{{ url }}</strong></p>
      <label for="title">Title</label>
      <input type="text" ref="title" v-model="title">
      <label for="notes">Notes</label>
      <textarea v-model="notes"></textarea>
      <div class="status-scraped">
        <div>
          <label for="site_status">Site Status</label>
          <select v-model="siteStatusType">
            <option value="U">Up</option>
            <option value="D">Down</option>
          </select>
        </div>
        <div>
          <label for="has_scraped">Site Crawled</label>
          <select v-model="hasScraped">
            <option value=1>Yes</option>
            <option value=0>No</option>
          </select>
        </div>
      </div>
      <label for="tags">Tags</label>
      <topic-links-tag
          :tags="tags"
          :fullTagList="fullTagList"
          @addNewTag="addNewTag">
      </topic-links-tag>
      <p class="buttons">
        <button class="cancel-btn" type="button" @click="addNotes = false">Cancel</button>
        <button class="add-btn" type="button" @click="addTopicList">Add Notes</button>
      </p>
    </div>
    <div class="topic-links-url-exists" v-else-if="same_url && history_exists && userCanEdit">
      <p><strong>{{ url }}</strong> already exists in your list. You can update the current note for it.</p>
      <p class="buttons">
        <button class="cancel-btn" @click="same_url = false; history_exists = false">Cancel</button>
        <button class="update-btn" @click="same_url = false; history_exists = false; updateNotes(url, index)">Update
        </button>
      </p>
    </div>
    <div class="topic-links-update-notes" v-if="update_notes && userCanEdit">
      <p>Update notes for: <strong>{{ oldUrl }}</strong></p>
      <label for="title">Title</label>
      <input type="text" v-model="title">
      <label for="url">Url</label>
      <input type="url" v-model="url">
      <label for="notes">Notes</label>
      <textarea v-model="notes"></textarea>
      <div class="status-scraped">
        <div>
          <label for="site_status">Site Status</label>
          <select v-model="siteStatusType">
            <option value="U">Up</option>
            <option value="D">Down</option>
          </select>
        </div>
        <div>
          <label for="has_scraped">Site Crawled</label>
          <select v-model="hasScraped">
            <option value=1>Yes</option>
            <option value=0>No</option>
          </select>
        </div>
      </div>
      <label for="tags">Tags</label>
      <topic-links-tag
          :tags="tags"
          :fullTagList="fullTagList"
          @addNewTag="addNewTag">
      </topic-links-tag>
      <p class="buttons">
        <button class="cancel-btn" type="button" @click="update_notes = false; formReset()">Cancel</button>
        <button class="update-btn" type="button" @click="addTopicList">Update</button>
      </p>
    </div>
    <div class="topic-links-history" v-else-if="!update_notes && show_topic_link_history">
      <div class="topic-links-history-close">
        <button @click="show_topic_link_history = false" title="Close">&#x2716;</button>
      </div>
      <div class="topic-links-history-header">
        <div>Person</div>
        <div>Date</div>
        <div>Title</div>
        <div>Notes</div>
        <div>Url</div>
        <div>Status</div>
        <div>Crawled</div>
      </div>
      <div class="topic-links-history-item" v-for="(item, index) in history_data" :key="index">
        <div>{{ item.user_info }}</div>
        <div>{{ item.update_date }}</div>
        <div>{{ item.all_titles }}</div>
        <div>{{ shortenNote(item.all_notes, 100) }}<button v-if="item.all_notes.length >= 100" class="topic-link-show-all-btn" @click="topicNotePopupText = item.all_notes">Show All</button></div>
        <div>{{ item.all_urls }}</div>
        <div><img class="topic-links-updown-icon" v-if="item.site_status === 'D'" src="/images/icons/topic-list-status-down.svg"><img class="topic-links-updown-icon" v-if="item.site_status === 'U'" src="/images/icons/topic-list-status-up.svg"></div>
        <div><img class="topic-list-scraped-icon" v-if="item.has_scraped === false" src="/images/icons/topic-list-scraped-false.svg"><img class="topic-list-scraped-icon" v-if="item.has_scraped === true" src="/images/icons/topic-list-scraped-true.svg"></div>
      </div>
      <p>
        <button class="topic-links-history-btn" type="button" @click="show_topic_link_history = false">Close</button>
      </p>
    </div>
    <div class="topic-links-confirm-delete" v-if="showConfirmDelete && userCanEdit">
      <p>Confirm deletion of <strong>{{ deleteInfo.url }}</strong></p>
      <p class="buttons">
        <button class="cancel-btn" @click="clearDelete">Cancel</button>
        <button class="confirm-btn" @click="deleteTopicList">Confirm</button>
      </p>
    </div>
    <div v-if="topicNotePopupText" class="topic-links-note-popup-container" @click.self="topicNotePopupText = ''">
      <div class="topic-links-note-popup">
        <button class="all-notes-full-note-popup-close" @click="topicNotePopupText = ''">&#x2716;</button>
        {{ topicNotePopupText }}
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Cookies from 'js-cookie'
import TopicLinksTag from './TopicLinksTag'

export default {
  name: 'TopicList',
  components: {TopicLinksTag},
  data() {
    return {
      fullList: [],
      list_data: [],
      history_data: [],
      addUrl: false,
      same_url: true,
      history_exists: false,
      topic_link_history: [],
      search_results: [],
      show_topic_link_history: false,
      update_notes: false,
      searchInput: '',
      title: '',
      url: '',
      oldUrl: '',
      newUrl: '',
      notes: '',
      tags: [],
      index: '',
      copySuccess: false,
      copySuccessX: null,
      copySuccessY: null,
      urlError: false,
      addNotes: false,
      currentIndex: null,
      sortedByTag: false,
      selectedTags: [],
      fullTagList: [],
      showConfirmDelete: false,
      deleteInfo: {
        url: null,
        id: null
      },
      topicNotePopupText: '',
      siteStatusType: "U",
      hasScraped: 0,
      sortDir: {'desc': 1, 'asc': -1},
      statusDir: null,
      userCanEdit: false,
    }
  },

  mounted() {
    this.getUpdatedTopicList()
  },

  methods: {
    urlEnter(e) {
      if (e.keyCode === 13) this.check_url()
    },
    addURL() {
      this.addUrl = true
      this.url = ''
      this.$nextTick(() => {
        this.$refs.url.focus()
      })
    },
    updateNotes(url, index) {
      this.update_notes = true
      this.oldUrl = url
      this.url = url
      if (index !== undefined) this.currentIndex = index
      for (let t of this.list_data[this.currentIndex].tags) this.tags.push(t)
      this.notes = this.list_data[this.currentIndex].notes
      this.title = this.list_data[this.currentIndex].title
      this.siteStatusType = this.list_data[this.currentIndex].site_status_type
      this.hasScraped = this.list_data[this.currentIndex].has_scraped ? 1 : 0
    },
    addTag(tag) {
      this.sortedByTag = true
      if (!this.selectedTags.find(t => t === tag)) {
        this.selectedTags.push(tag)
      }
      this.list_data = this.list_data.filter((item) => {
        return item.tags.find(t => t === tag)
      })
    },
    removeTag(tag) {
      this.selectedTags = this.selectedTags.filter((t) => {
        return t != tag
      })
      if (this.selectedTags.length === 0) {
        this.list_data = this.fullList
        this.sortedByTag = false
      } else {
        for (let tg of this.selectedTags) {
          this.list_data = this.fullList.filter((item) => {
            return item.tags.find(t => t === tg)
          })
        }
      }
    },
    addNewTag(tags) {
      this.tags = tags
    },
    copyToClipboard(e, url) {
      this.copySuccess = true
      this.copySuccessX = e.clientX + 20
      this.copySuccessY = e.clientY + 20
      navigator.clipboard.writeText(url)
      this.$nextTick(() => {
        this.$refs.copySuccessMsg.addEventListener('animationend', () => {
          this.copySuccess = false
        })
      })
    },
    check_url() {
      if (this.url != '') {
        this.urlError = false
        this.same_url = false
        this.history_exists = false
        this.addUrl = false
        let urlExists = this.fullList.filter((item, index) => {
          if (this.url === item.url) {
            this.index = index
            return this.url === item.url
          }
        })
        if (urlExists.length > 0) {
          this.same_url = true
          this.history_exists = true
        } else {
          this.addNotes = true
          this.$nextTick(() => {
            this.$refs.title.focus()
          })
        }
      } else {
        this.urlError = true
      }
    },
    addTopicList() {
      this.update_notes = false
      this.addUrl = false
      if (this.oldUrl !== this.url && this.oldUrl !== '') {
        this.newUrl = this.url
        this.url = this.oldUrl
      } else {
        this.newUrl = ''
      }
      let topic_info = {
        'title': this.title,
        'notes': this.notes,
        'tags': this.tags,
        'url': this.url,
        'newUrl': this.newUrl,
        'has_scraped': Boolean(parseInt(this.hasScraped)),
        'site_status': this.siteStatusType
      }
      const csrftoken = Cookies.get('csrftoken')
      axios.post('/en/topic/add/', topic_info, {'headers': {'X-CSRFToken': csrftoken}}).then(response => {
        if (response.status === 200) {
          this.same_url = false
          this.addNotes = false
          this.oldUrl = ''
          this.newUrl = ''
          this.formReset()
          this.getUpdatedTopicList()
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    confirmDelete(id, index) {
      this.deleteInfo.url = this.fullList[index].url
      this.deleteInfo.id = id
      this.showConfirmDelete = true
    },
    deleteTopicList() {
      const csrftoken = Cookies.get('csrftoken')
      axios.post('/en/topic/delete/', this.deleteInfo.id, {'headers': {'X-CSRFToken': csrftoken}}).then(response => {
        if (response.status === 200) {
          this.getUpdatedTopicList()
          this.clearDelete()
        } else {
          console.log('Something went wrong')
          this.showConfirmDelete = false
        }
      }).catch((error) => {
        console.log(error)
        this.showConfirmDelete = false
      })
    },
    clearDelete() {
      this.deleteInfo.url = null
      this.deleteInfo.id = null
      this.showConfirmDelete = false
    },
    getUpdatedTopicList() {
      const csrftoken = Cookies.get('csrftoken')
      axios.get('/en/topic/list/', {'headers': {'X-CSRFToken': csrftoken}}).then(response => {
        if (response.status === 200) {
          this.fullList = this.list_data = response.data.list
          this.fullTagList = response.data.tags
          this.userCanEdit = response.data.user_can_edit
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    convertDate(date) {
      let splitDate = date.split(' ')
      let splitDMY = splitDate[0].split('/')
      let newDate = splitDMY[2] + '-' + splitDMY[1] + '-' + splitDMY[0] + 'T' + splitDate[1]
      return Date.parse(newDate)
    },
    viewHistory(id) {
      this.show_topic_link_history = true
      for (let index = 0; index < this.list_data.length; index++) {
        if (this.list_data[index].id === id) {
          this.history_data = this.list_data[index].history
        }
      }
      this.history_data.sort((a, b) => this.convertDate(b.update_date) - this.convertDate(a.update_date))

    },
    formReset() {
      this.title = ''
      this.notes = ''
      this.tags = []
      this.url = ''
      this.oldUrl = ''
      this.newUrl = ''
      this.hasScraped = 0
      this.siteStatusType = 'U'
    },
    shortenNote(note, noteLen) {
      if (note.length >= noteLen) {
        return note.substring(0, noteLen) + '...'
      } else {
        return note
      }
    },
    statusSort() {
      this.statusDir = (this.statusDir === 'asc') ? 'desc' : 'asc'
      this.list_data.sort((a, b) => this.sortDir[this.statusDir] * a.site_status_type.localeCompare(b.site_status_type))
    }
  }

}
</script>

<style>
.topic-links {
  width: 80vw;
  margin: auto;
}

.topic-links-add-btn {
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #a42334;
  color: #fff;
  text-decoration: none;
  padding: 10px;
  border-radius: 6px;
  position: relative;
  top: -10px;
  left: 30px;
}

.topic-links-header,
.topic-links-item,
.topic-links-history-header,
.topic-links-history-item {
  display: flex;
  width: 100%;
}

.topic-links-header > div,
.topic-links-item > div,
.topic-links-history-header > div,
.topic-links-history-item > div {
  flex-grow: 1;
  padding: 0.35rem 0.5rem;
  font-size: 0.85em;
  width: 25%;
}

.topic-links-history-item:nth-child(odd) {
  background: #f5f5f4;
}

.topic-links-header {
  border-bottom: 2px solid #ccc;
  font-weight: bold;
}

.topic-links-header > div:first-child,
.topic-links-item > div:first-child {
  width: 120px;
}
.topic-links-header > div:nth-child(2),
.topic-links-item > div:nth-child(2) {
  width: 100px;
}
.topic-links-header > div:nth-child(6),
.topic-links-item > div:nth-child(6),
.topic-links-header > div:last-child,
.topic-links-item > div:last-child {
  max-width: 150px;
}

.topic-links-item > div:last-child {
  word-break: break-all;
}

.topic-links-history-header > div:nth-child(-n+3),
.topic-links-history-item > div:nth-child(-n+3) {
  width: 150px;
}

.topic-links-history-header > div:nth-child(6),
.topic-links-history-item > div:nth-child(6),
.topic-links-history-header > div:last-child,
.topic-links-history-item > div:last-child {
  width: 100px;
}

.topic-links-header > div.topic-links-btn-col,
.topic-links-item > div.topic-links-btn-col {
  max-width: 75px;
}

.topic-links-item:nth-child(even) {
  background: #f5f5f4;
}

.topic-links-icon {
  max-width: 36px;
  max-height: 36px;
}

.topic-links-copy {
  max-width: 24px;
  max-height: 24px;
  margin-left: 4px;
}

.url-copy-success {
  position: absolute;
  background: #e2ce66;
  border: 1px solid #000;
  padding: 10px 20px;
  animation-name: fadeOutSuccess;
  animation-duration: 1s;
  animation-delay: 3s;
}

.url-copy-success p {
  margin-bottom: 0;
}

@keyframes fadeOutSuccess {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.topic-links-add-url,
.topic-links-url-notes,
.topic-links-update-notes,
.topic-links-history,
.topic-links-url-exists,
.topic-links-confirm-delete {
  width: 50vw;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid #ccc;
  background: #fff;
  padding: 30px;
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.2);
  z-index: 100;
}

.topic-links-history {
  max-height: 70vh;
  overflow-y: scroll;
}

.topic-links-update-notes label,
.topic-links-url-notes label {
  margin: 10px 0;
}

.topic-links-update-notes label:first-of-type,
.topic-links-url-notes label:first-of-type {
  margin-top: 0;
}

.topic-links-add-url p,
.topic-links-url-notes p.buttons,
.topic-links-update-notes p.buttons,
.topic-links-history p,
.topic-links-url-exists p.buttons,
.topic-links-confirm-delete p.buttons {
  text-align: right;
  margin-top: 20px;
  margin-bottom: 0;
}

.topic-links-add-url p.error {
  text-align: left;
  color: #a42334;
  position: absolute;
}

.topic-links-add-url .add-btn,
.topic-links-url-notes .add-btn,
.topic-links-update-notes .update-btn,
.topic-links-url-exists .update-btn,
.topic-links-confirm-delete .confirm-btn {
  background-color: #a42334;
  color: #fff;
  padding: 10px;
  border-radius: 6px;
}

.topic-links-add-url .cancel-btn,
.topic-links-url-notes .cancel-btn,
.topic-links-update-notes .cancel-btn,
.topic-links-history-btn,
.topic-links-url-exists .cancel-btn,
.topic-links-confirm-delete .cancel-btn {
  background-color: #fff;
  color: #a42334;
  border: 1px solid #a42334;
  padding: 10px;
  border-radius: 6px;
}

.topic-links-history-close {
  position: absolute;
  top: 5px;
  right: 15px;
}

.topic-links-tag {
  text-decoration: none;
}

.topic-links-tag:hover {
  text-decoration: underline;
}

.topic-links-tag-container {
  margin-bottom: 20px;
}

.topic-links-selected-tag {
  background-color: #a42334;
  color: #fff;
  padding: 5px;
  border-radius: 6px;
  font-size: 0.85em;
  margin-right: 10px;
}

.topic-links-tag-container .topic-links-selected-tag:first-of-type {
  margin-left: 10px;
}

.empty-list {
  text-align: center;
  margin-top: 100px;
}

.empty-list-btn {
  background-color: #a42334;
  color: #fff;
  padding: 5px;
  border-radius: 6px;
  font-size: 0.85em;
}

.topic-links-update-notes textarea,
.topic-links-url-notes textarea {
  height: 200px;
  resize: none;
}

.topic-links-item-notes {
  white-space: pre-line;
  word-break: break-all;
}

.topic-link-show-all-btn {
  color: #c22c3f;
  float: right;
}

.topic-links-note-popup-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
  background: rgba(0, 0, 0, 0.2);
}

.topic-links-note-popup {
  position: fixed;
  top: 50%;
  z-index: 11;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 60vw;
  max-height: 60vh;
  background: #f5f5f4;
  padding: 20px 30px;
  border: 2px solid #cecbca;
  border-radius: 6px;
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.2);
  white-space: pre-line;
  overflow: scroll;
}

.topic-links-updown-icon {
  width: 32px;
  margin-left: 7px;
}
.topic-list-scraped-icon {
  width: 32px;
  margin-left: 14px;
}
.topic-links-sort-btn {
  color: #a42334;
}
.desc:after {
  content: '▼';
  display: inline-block;
  color: black;
  text-decoration: none;
}
.asc:after {
  content: '▲';
  display: inline-block;
  color: black;
  text-decoration: none;
}
.status-scraped {
  display: flex;
  margin-top: 10px;
  justify-content: space-evenly;
}
.status-scraped > div {
  width:45%;
}
</style>
