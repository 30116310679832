<template>
  <div class="result-box image-result">
    <div class="sha1-section">
      <div>
        <strong>SHA1:</strong>
        <span class="sha1" :id="result.sha1">
          {{ result.sha1 }}
        </span>
      </div>
      <div>
        <strong>Similarity:</strong>
        <span>{{ result.similarity }}%</span>
      </div>
    </div>
    <div v-if="result.sightings && result.sightings.length > 0">
      <h4>Images with Sightings</h4>
    </div>

    <div :id="'sightings' + result.sha1" v-if="result.sightings">
      <div class="watchlist-sightings-header u-fontsize-sm">
        <div>URL</div>
        <div>Page URL</div>
        <div>First Seen</div>
        <div>Last Seen</div>
        <div></div>
      </div>
      <div class="watchlist-sightings" v-for="(sighting, index) in result.sightings" :key="index">
        <div>{{ sighting.url }}</div>
        <div>{{ sighting.page_url }}</div>
        <div>{{ sighting.first | formatDate }}</div>
        <div>{{ sighting.last | formatDate }}</div>
        <div>
          <a v-if="sighting.has_messages" :href="forum_url + '?query=' + quote(sighting.page_url)">See forum posts</a>
        </div>
      </div>
    </div>
    <span v-if="result.sightings">
      <a :href="'/en/media/?sha1=' + result.sha1">All Sightings</a>
    </span>
    <span v-if="(result.sightings && result.forum_sightings)">
      <br><br>
    </span>
    <div class="forum-sightings" v-if="result.forum_sightings && result.forum_sightings.length > 0">
      <h4>Forum Sightings</h4>
      <div :id="'forum_sightings' + result.sha1">
        <div class="forum-sightings-header u-fontsize-sm">
          <div>Site name</div>
          <div>Forum</div>
          <div>Topic</div>
          <div>Post ID</div>
          <div>Posted date</div>
          <div></div>
        </div>
        <div class="forum-sightings-item" v-for="(sighting, index) in result.forum_sightings" :key="index">
          <div>{{ sighting.site_name }}</div>
          <div>{{ sighting.forum }}</div>
          <div>{{ sighting.topic }}</div>
          <div>{{ sighting.post_id }}</div>
          <div>{{ sighting.posted_date | formatDate}}</div>
          <div><a :href="forum_url + '?doc_id=' + sighting.doc_id">See forum posts</a></div>
        </div>
        <div v-if="result.forum_sightings">
          <a :href="'/en/media/?sha1=' + result.sha1">All Sightings</a>
        </div>
      </div>
    </div>

    <div v-if="result.collage_match">
      <br>
      <h5>This media has collage match!</h5>
    </div>

    <div v-if="result.video_sha1_list && result.video_sha1_list.length > 0">
      <h5>This media was extracted from videos with following SHA1:</h5>
      <span class="watchlist-sightings" v-for="video_sha1 in result.video_sha1_list" :key="video_sha1">
        <a :href="'/en/media/?sha1=' + video_sha1">{{ video_sha1 }}</a>
      </span>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    result: {
      type: Object
    },
    forum_url: {
      type: String,
      required: true
    },
    media_item: {
      type: String
    }
  },
  filters: {
    formatDate: function (value){
      if (parseInt(value) == value) {
        // this is unixEpoch (seconds) need to be in milliseconds
        value = value * 1000
      }
      var theDate = new Date(value);
      return theDate.toLocaleDateString('en-CA', {year: 'numeric', month: 'short', day: 'numeric'})
    }
  },
  methods: {
    quote(s) {
      return `"${s}"`
    }
  },
}
</script>

<style>
.sha1-section {
  margin-bottom: 24px;
}
.sha1-section > div {
  margin-bottom: 10px;
}
.sha1-section > div strong {
  width: 18%;
  display: inline-block;
}
.watchlist-sightings-header, .forum-sightings-header {
  display: flex;
  border-bottom: 2px solid hsl(11, 3%, 90%);
}
.forum-sightings-header > div,
.forum-sightings-item > div {
  flex-grow: 1;
  padding: 0.35rem 0.5rem;
  word-break: break-word;
  width: 16%;
}
.forum-sightings-header > div {
  vertical-align: bottom;
}
.forum-sightings-header > div:nth-child(4),
.forum-sightings-item > div:nth-child(4) {
  width: 50px;
}
.watchlist-sightings-header > div,
.forum-sightings-header > div {
  font-weight: bold;
}
.watchlist-sightings-header > div,
.watchlist-sightings > div {
  padding: 0.35rem 0.5rem;
}
.watchlist-sightings-header > div:nth-child(1),
.watchlist-sightings > div:nth-child(1),
.watchlist-sightings-header > div:nth-child(4),
.watchlist-sightings > div:nth-child(4) {
  width: 25%;
}
.watchlist-sightings-header > div:nth-child(2),
.watchlist-sightings-header > div:nth-child(3),
.watchlist-sightings > div:nth-child(2),
.watchlist-sightings > div:nth-child(3) {
  width: 50%;
}
.watchlist-sightings:nth-child(even),
.forum-sightings-item:nth-child(even) {
  background-color: #fff;
}
.watchlist-sightings, .forum-sightings-item {
  display: flex;
  font-size: 0.85em;
  word-break: break-word;
}
.forum-sightings {
  margin-top: 20px;
}
</style>
