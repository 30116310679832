<template>
  <div
    class="overlay"
    @click.self="$emit('hideSightingsOverlay')"
  >
    <div class="overlay-panel">
      <button
        class="overlay-close"
        @click="$emit('hideSightingsOverlay')"
      >
        &#x2716;
      </button>
      <h5 class="sightings-table-title">
        {{ getTableTitle() }} Sightings for <span style="color: #a42334">{{ mediaSha1 }}</span>
      </h5>
      <div class="sightings-panel">
        <div class="sightings-table-container">
          <div
            v-if="sightings.length === 0"
            class="circular-loading"
          >
            <v-progress-circular
              indeterminate
              color="accent"
              size="70"
              width="10"
            />
            <strong>Loading ...</strong>
          </div>
          <table
            v-else-if="sightingsType === 'media'"
            class="responsive-table u-mb-0"
          >
            <tr class="sticky-header-row">
              <th>Sightings</th>
              <th>URL</th>
              <th>Page URL</th>
            </tr>
            <tr
              v-for="(sighting, index) in sightings"
              :key="index"
            >
              <td>
                {{ sighting.total }}
                <span v-if="sighting.first !== sighting.last">
                  ({{ sighting.first | formatDate }} - {{ sighting.last | formatDate }})
                </span>
                <span v-else>({{ sighting.first | formatDate }})</span>
              </td>
              <td class="u-break">
                {{ sighting.url }}
              </td>
              <td class="u-break">
                {{ sighting.page_url }}
              </td>
            </tr>
          </table>
          <table
            v-else-if="sightingsType === 'forums'"
            class="responsive-table u-mb-0"
          >
            <tr class="sticky-header-row">
              <th>Site name</th>
              <th>Forum</th>
              <th>Topic</th>
              <th>Post ID</th>
              <th>Crawled date</th>
              <th />
            </tr>
            <tr
              v-for="sighting in sightings"
              :key="sighting.post_id"
            >
              <td>{{ sighting.site_name }}</td>
              <td class="u-break">
                {{ sighting.forum }}
              </td>
              <td class="u-break">
                {{ sighting.topic }}
              </td>
              <td class="u-break">
                {{ sighting.post_id }}
              </td>
              <td class="u-break">
                <span v-if="sighting.scraped_date">
                  {{ sighting.scraped_date | formatDate }}
                </span>
                <span v-else>Unavailable</span>
              </td>
              <td>
                <a :href="forumUrl + '?doc_id=' + sighting.doc_id">See forum posts</a>
              </td>
            </tr>
          </table>
          <table
            v-else-if="sightingsType === 'chats'"
            class="responsive-table u-mb-0"
          >
            <tr class="sticky-header-row">
              <th>Site name</th>
              <th>Crawled date</th>
              <th />
            </tr>
            <tr
              v-for="sighting in sightings"
              :key="sighting.post_id"
            >
              <td>{{ sighting.site_name }}</td>
              <td class="u-break">
                <span v-if="sighting.scraped_date">
                  {{ sighting.scraped_date | formatDate }}
                </span>
                <span v-else>Unavailable</span>
              </td>
              <td>
                <a :href="forumUrl + 'chat-sites/SiteName/' + sighting.doc_id">See chat messages</a>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SightingsTablePopup',
  props: {
    mediaSha1: {
      type: String,
      required: true
    },
    sightingsType: {
      type: String,
      required: true
    },
    sightings: {
      type: Array,
      required: true
    },
    forumUrl: {
      type: String,
      required: true
    }
  },
  emits: ['hideSightingsOverlay'],
  methods: {
    getTableTitle() {
      if (this.sightingsType === 'media') {
        return 'Media'
      } else if (this.sightingsType === 'forums') {
        return 'Forum'
      } else if (this.sightingsType === 'chats') {
        return 'Chat'
      } else {
        return ''
      }
    },
  }
}

</script>

<style scoped>
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.2);
    z-index: 10;
  }
  .overlay-panel {
    position: fixed;
    inset: 0;
    width: 90vw;
    height: 90vh;
    background: #FFF;
    z-index: 15;
    border: 2px solid #CECBCA;
    border-radius: 6px;
    box-shadow: 10px 10px 5px 0 rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items:stretch;
    gap: 10px;
    padding: 10px;
  }
  .overlay-close {
    align-self: flex-end;
    padding: 0 5px;
  }
  .sightings-table-container {
    overflow-y: scroll;
    max-height: 100%;
  }
  .sticky-header-row {
    position: sticky;
    top: 0;
    background: #F5F5F4;
  }
  .sightings-table-title {
    margin-left: 10px;
    margin-bottom: 5px;
  }
  .sightings-panel {
    background: #F5F5F4;
    padding: 10px;
    height: 90%;
    border-top: 2px #CECBCA solid;
  }
  .circular-loading {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 20px;
  }
</style>
