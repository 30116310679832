<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <div data-app>
    <v-dialog
      v-model="loading"
      transition="fade-transition"
      fullscreen
    >
      <v-container
        fluid
        fill-height
        style="background-color: rgba(255, 255, 255, 0.75);"
      >
        <v-row>
          <v-col cols="12">
            <v-row
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="#a42334"
                size="70"
                width="10"
              />
            </v-row>
            <v-row
              align="center"
              justify="center"
            >
              <div class="u-mt-md loading-text">
                <strong>Loading ...</strong>
              </div>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
    <div class="chat-message-timeframe-selector">
      <div>
        <label for="timeframe">Timeframe: </label>
        <input
          id="timeframe"
          v-model="timeframeInput"
          type="number"
        >
      </div>
      <div
        v-for="type in scale"
        :key="type"
      >
        <input
          :id="'range_scale_' + type"
          v-model="range"
          type="radio"
          :value="type"
        >
        <label :for="'range_scale_' + type">{{ type }}</label>
      </div>
      <button @click="calculateTimeframe">
        Apply
      </button>
    </div>
    <chat-context-site-header
      :message-search-url="headerInfo.messageSearchUrl"
      :site-name="headerInfo.siteName"
      :site-id="headerInfo.siteId"
      :has-chat-site-notes="headerInfo.hasChatSiteNotes"
      :in-watchlist = "siteInWatchlist"
    />
    <div class="timeline">
      <chat-context-item
        v-for="(message, index) in all_chat_messages"
        :key="index"
        :message="message"
        :message-search-url="messageSearchUrl"
        :media-search-url="mediaSearchUrl"
        :site-name="siteName"
        :timeframe="timeframe"
        :featured="message._id === chatDocId"
        @refreshed="refreshed"
        @target="target"
      />
    </div>
  </div>
</template>

<script>
import ChatContextSiteHeader from './ChatContextSiteHeader'
import ChatContextItem from './ChatContextItem'
import axios from 'axios'
import Cookies from 'js-cookie'

export default {
  name: 'ChatFocusView',

  components: {
    ChatContextSiteHeader,
    ChatContextItem
  },

  props: {
    docId: {
      type: String,
      default: ''
    },
    messageSearchUrl: {
      type: String,
      default: ''
    },
    siteName: {
      type: String,
      default: ''
    },
    mediaSearchUrl: {
      type: String,
      default: ''
    },
    headerInfo: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      all_chat_messages: [],
      limit: 10,
      loading: false,
      scale: ['minutes', 'hours', 'days'],
      timeframeInput: 14,
      timeframe: '1209600',
      range: 'days',
      username: '',
      chatDocId: this.docId,
      siteInWatchlist: false
    }
  },
  mounted() {
    if (window.location.search.includes('?timeframe')) {
      this.timeframe = window.location.search.split('=')[1]
      this.convertTimeframe()
    }
    this.loadMessages()
  },
  methods: {
    scrollToFeatured() {
      if (this.$el.querySelector('.timeline__focus')) {
        window.scrollTo({
          top: this.$el.querySelector('.timeline__focus').offsetTop,
          behavior: 'smooth',
        })
      }
    },
    loadMessages() {
      const csrftoken = Cookies.get('csrftoken')
      let siteDataObj = {
        'doc_id': this.chatDocId,
        'timeframe': this.timeframe
      }
      this.loading = true
      axios.get('/en/message-search/chat_message_timeline/', {params: siteDataObj}, {'headers': {'X-CSRFToken': csrftoken}}).then(response => {
        this.all_chat_messages = response.data.messages
        this.siteInWatchlist = this.all_chat_messages[0].site_watchlist
        this.loading = false
        this.$nextTick(() => {
          this.scrollToFeatured()
        })
      })
    },
    refreshed() {
      this.loadMessages()
    },
    calculateTimeframe() {
      switch (this.range) {
        case 'days':
          this.timeframe = (this.timeframeInput * 24 * 60 * 60).toString()
          break
        case 'hours':
          this.timeframe = (this.timeframeInput * 60 * 60).toString()
          break
        case 'minutes':
          this.timeframe = (this.timeframeInput * 60).toString()
          break
      }
      this.updateURL()
      this.loadMessages()
    },
    convertTimeframe() {
      if (parseInt(this.timeframe) >= 86400) {
        this.range = 'days'
        this.timeframeInput = Math.round(parseInt(this.timeframe) / 24 / 60 / 60)
      } else if (parseInt(this.timeframe) >= 3600) {
        this.range = 'hours'
        this.timeframeInput = Math.round(parseInt(this.timeframe) / 60 / 60)
      } else {
        this.range = 'minutes'
        this.timeframeInput = Math.round(parseInt(this.timeframe) / 60)
      }
    },
    target(message) {
      this.username = message.username
      this.chatDocId = message.messageId
      this.updateURL()
      this.loadMessages()
    },
    updateURL() {
      let title = 'Chat timeline: message by ' + this.username + ' on ' + this.siteName + ' – Arthropod'
      document.title = title
      window.history.pushState(null, title, `${this.messageSearchUrl}chat-sites/${this.siteName}/${this.chatDocId}/?timeframe=${this.timeframe}`)
    }
  },
}
</script>

<style scoped>
.chat-message-timeframe-selector {
  font-size: 0.85em;
  margin-bottom: 30px;
}
.chat-message-timeframe-selector > div {
  display: inline-block;
}
.chat-message-timeframe-selector > div:not(:first-child) {
  margin-left: 10px;
}
.chat-message-timeframe-selector label:first-child,
.chat-message-timeframe-selector input {
  min-width: 6em;
  width: 6em;
  display: block;
}
.chat-message-timeframe-selector input[type="radio"] + label {
  padding-left: 25px;
}
.chat-message-timeframe-selector button {
  background: #696463;
  color: #fff;
  padding: 0.5em 1.125em;
  border-radius: 5px;
  margin-left: 15px;
}
.v-progress-circular,
.loading-text {
  margin: auto;
}
</style>
