<template>
    <div>
        <p>
            <a :href="'/en/media/?photodna=' + result">Search PhotoDNA hash {{ pdnaindex + 1 }}</a>
            <button
                type="button"
                class="btn btn--xs tooltip__toggle"
                :aria-label="'View PhotoDNA Hash ' + pdnaindex"
                v-on:click="togglehash(pdnaindex)">
                View hash
            </button>
            <div :id="'hidden_pdna' + pdnaindex">
                <template v-if="hashDetail == pdnaindex">
                    <span class="tooltip__container" aria-live="polite" @click.prevent>
                        <span class="tooltip" style="overflow-wrap: break-word;">
                            {{ result }}
                        </span>
                    </span>
                </template>
            </div>
            <hr>
        </p>
    </div>
</template>

<script>
export default {
  props: {
    result: {
      type: String
    },
    pdnaindex: {
        type: Number
    }
  },
  data(){
      return{
          hashDetail: 999999 // using this value as the trigger for hiding all hash details since I hope we'll never have this many in the demo
      }
  },
  methods:{
      togglehash(index){
        if(this.hashDetail == index){
            this.hashDetail = 999999;
        }
        else{
            this.hashDetail = index;
        }
      }
  }
}
</script>
