// src/plugins/vuetify.js

import Vue from 'vue'
import Vuetify from 'vuetify'

Vue.use(Vuetify)
const opts = {
  theme: {
    themes: {
      light: {
        primary: '#a42334',
        secondary: '#474443',
        accent: '#c22c3f',
        error: '#a32435'
      }
    }
  }
}

export default new Vuetify(opts)
