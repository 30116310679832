<template>
  <details class="other-users-details u-px-md" v-if="resultOthersCnt[entry]">
    <summary @click="get_users_details(entry)">
      Watched by <strong>({{ resultOthersCnt[entry] }})</strong> other users
    </summary>

    <ul class="other-orgs-list" v-if="entry == usersMediaEntry">
      <li v-for="uid in publicUserId" :key="uid">
        <span style="margin-right: 20px;">{{ uid }}</span>
        <org-contact-popup
          :uid="uid"
          :entry="entry"
          :is_pdna="is_pdna"
        />
      </li>
    </ul>
  </details>
</template>

<script>
import axios from 'axios'
import Cookies from 'js-cookie'
import OrgContactPopup from "./OrgContactPopup"

export default {
  name: 'OtherUsersDetails',
  components: {
    OrgContactPopup,
  },
  props: ['is_pdna', 'entry', 'resultOthersCnt'],
  data() {
    return {
      publicUserId: null,
      usersMediaEntry: null
    }
  },
  methods: {
    get_users_details(val) {
      // get details of users who have same entry in their watchlist, send the hash to a views' method that returns the
      // organization name of users
      var media_data = {hash_val: val}
      const csrftoken = Cookies.get('csrftoken')
      axios.post('/en/watchlist/users_with_same_media/', media_data, {'headers': {'X-CSRFToken': csrftoken}}).then(
          r => {
            this.publicUserId = r.data.public_user_ids,
                this.usersMediaEntry = r.data.users_media_entry
          }
      ).catch(
        err => {console.log("something went wrong! %o", resp)}
      )
    },
  },
  mounted: function() {
    this.get_users_details(this.entry, this.is_pdna)
  }
}
</script>

<style>
.other-users-details {
  margin-bottom: 10px;
  border: 2px solid #9e9b9a;
  border-radius: 6px;
}
.v-application ul.other-orgs-list {
  list-style: none;
  margin: 0 0 25px 0;
  padding: 0;
  border: 2px solid #ccc;
}
.other-orgs-list li {
  padding: 10px;
  margin: 0;
}
.other-orgs-list li:nth-child(even) {
  background: #f5f5f4;
}
.other-orgs-list li:nth-child(odd) {
  background: #fff;
}
</style>
