<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <div data-app>
    <v-dialog
      v-model="loading"
      transition="fade-transition"
      fullscreen
    >
      <v-container
        fluid
        fill-height
        style="background-color: rgba(255, 255, 255, 0.75);"
      >
        <v-row>
          <v-col cols="12">
            <v-row
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="#a42334"
                size="70"
                width="10"
              />
            </v-row>
            <v-row
              align="center"
              justify="center"
            >
              <div class="u-mt-md loading-text">
                <strong>Loading ...</strong>
              </div>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
    <chat-context-site-header
      :message-search-url="headerInfo.messageSearchUrl"
      :site-name="headerInfo.siteName"
      :site-id="headerInfo.siteId"
      :has-chat-site-notes="headerInfo.hasChatSiteNotes"
      :in-watchlist="siteInWatchlist"
      :site-url="siteUrl"
    />
    <div class="chat-message-num-selector">
      Show lastest
      <input
        v-model="limit"
        type="number"
      > messages <button @click="loadMessages">
        Apply
      </button>
    </div>
    <div class="timeline">
      <chat-context-item
        v-for="(message, index) in all_chat_messages"
        :key="index"
        :message="message"
        :message-search-url="messageSearchUrl"
        :media-search-url="mediaSearchUrl"
        :site-name="siteName"
        :timeframe="timeframe"
        :featured="message._id === docId"
        @refreshed="refreshed"
        @target="target"
      />
    </div>
  </div>
</template>

<script>
import ChatContextSiteHeader from './ChatContextSiteHeader'
import ChatContextItem from './ChatContextItem'
import axios from 'axios'
import Cookies from 'js-cookie'

export default {
  name: 'ChatContextView',

  components: {
    ChatContextSiteHeader,
    ChatContextItem
  },

  props: {
    docId: {
      type: String,
      default: ''
    },
    siteId: {
      type: String,
      required: true
    },
    messageSearchUrl: {
      type: String,
      required: true
    },
    siteName: {
      type: String,
      required: true
    },
    mediaSearchUrl: {
      type: String,
      required: true
    },
    headerInfo: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      all_chat_messages: [],
      limit: 10,
      siteUrl:'',
      loading: false,
      timeframe: '1209600',
      siteInWatchlist: false,

    }
  },
  mounted() {
    this.scrollToFeatured()
    this.loadMessages()
  },
  methods: {
    scrollToFeatured() {
      if (this.$el.querySelector('.timeline__focus')) {
        // need to use $nextTick so that the coordinates are correct. Otherwise the offsetTop reported by the elements
        // is incorrect if they land after a "long post" that uses the "tldr" button and overflow:hidden to clip the
        // post content. This way the DOM is fully rendered and we have the coordinate from the actual visible elements.
        this.$nextTick(() => {
          window.scrollTo({
            top: this.$el.querySelector('.timeline__focus').offsetTop,
            behavior: 'smooth',
          })
        })
      }
    },
    loadMessages() {
      const csrftoken = Cookies.get('csrftoken')
      let siteDataObj = {
        'site_name': this.siteName,
        'limit': this.limit
      }
      this.loading = true
      axios.get('/en/message-search/refresh_chat_messages_index/', {params: siteDataObj}, {'headers': {'X-CSRFToken': csrftoken}}).then(response => {
        this.all_chat_messages = response.data.messages
        this.loading = false
        this.siteInWatchlist = this.all_chat_messages[0].site_watchlist
        this.siteUrl=this.all_chat_messages[0]._source.site
      })
    },
    refreshed() {
      this.loadMessages()
    },
    target(message) {
      window.location = `${this.messageSearchUrl}chat-sites/${this.siteName}/${message.messageId}/?timeframe=${this.timeframe}`
    }
  },
}
</script>

<style scoped>
.chat-message-num-selector {
  font-size: 0.85em;
}
.chat-message-num-selector input {
  min-width: 3.5em;
  width: 3.5em;
  text-align: center;
  margin: 0 5px;
}
.chat-message-num-selector button {
  background: #696463;
  color: #fff;
  padding: 0.5em 1.125em;
  border-radius: 5px;
  margin-left: 5px;
}
.v-progress-circular,
.loading-text {
  margin: auto;
}
</style>
