<template>
  <div id="demo_data">
    <h2>Hashes</h2>
    <div class="l-container">
      <h3>PhotoDNA</h3>
      <pdna-item
        v-for="(pdnahash, pdnaindex) in pdnahashes"
        :key="pdnahash"
        :result="pdnahash"
        :pdnaindex="pdnaindex"
      />
    </div>
    <div class="l-container">
      <h3>SHA1</h3>
      <sha-item
        v-for="shahash in shahashes"
        :key="shahash"
        :result="shahash"
      />
    </div>
    <h2>Image Files</h2>
    <file-item
      v-for="(fileitem, fileindex) in fileList"
      :key="fileitem"
      :result="fileitem"
      :file_path="filepath"
      :fileindex="fileindex"
    />
    <h2>Usernames</h2>
    <username-item
      v-for="username in usernameList"
      :key="username"
      :result="username"
    />
    <h2>Regular Expressions (Regex)</h2>
    <regex-item
      v-for="regex in regexList"
      :key="regex"
      :result="regex"
    />
  </div>
</template>
<script>
import PdnaItem from './demo/PdnaItem'
import ShaItem from './demo/ShaItem'
import FileItem from './demo/FileItem'
import UsernameItem from './demo/UsernameItem'
import RegexItem from './demo/RegexItem'

export default {
  name: 'DemoList',
  components: {
    PdnaItem,
    ShaItem,
    FileItem,
    UsernameItem,
    RegexItem
  },
  props: {
    pdnahashes: {
      type: Array,
      default: () => []
    },
    shahashes: {
      type: Array,
      default: () => []
    },
    fileList: {
      type: Array,
      default: () => []
    },
    filepath: {
      type: String,
      default: ''
    },
    usernameList: {
      type: Array,
      default: () => []
    },
    regexList: {
      type: Array,
      default: () => []
    },
  }
}
</script>
