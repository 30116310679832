<template>
  <p class="note-legend"><strong>Legend:</strong> <img class="note-icon" src="/images/icons/view_notes.svg" title="View Notes">- View Notes <img class="note-icon" src="/images/icons/add_notes.svg" title="Add Note">- Add Note</p>
</template>

<script>
export default {
  name: 'NotesLegend'
}
</script>

<style>
.note-legend {
  font-size: 14px;
  text-align: right;
  margin-bottom: 4px;
}
.note-legend img {
  width: 20px;
}
</style>
