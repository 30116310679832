<template>
  <span>
    <button @click="showNoteDialog = true; newNote = note; newVisibility = visibility; " title="Edit"><img class="note-icon" src="/images/icons/edit_black_48dp.svg"></button>
    <div class="edit-note-dialog" v-if="showNoteDialog">
      <p>
        <label for="editNote">Edit Note: </label>
        <textarea
          class="note-textarea"
          :class="{'note-error': noteError}"
          @keyup="noteTextareaKeypress"
          ref="editNote"
          v-model="newNote">
        </textarea>
        <span v-if="noteError" class="note-error-message">Please Enter a Note</span>
      </p>
      <p class="note-type">
      <label for="new-type">Visibility: </label>
        <input id="new-note-type-private" type="radio" value="PR" v-model="newVisibility"> <label for="new-note-type-private">Private</label>
        <input id="new-note-type-public" type="radio" value="PB" v-model="newVisibility"> <label for="new-note-type-public">Public</label>
      </p>
      <p v-if="date_created !== date_updated" class="edit-note-last-edited">This note was last edited on {{date_updated}}</p>
      <p> <button type="button" class="edit-note-dialog-confirm-btn" @click="update_note">Update</button> <button type="button" class="edit-note-dialog-cancel-btn" @click="close_process">Cancel</button></p>
    </div>
  </span>
</template>

<script>

export default {
  emits: ['edit-note'],
  props: ['note_id', 'note', 'visibility', 'date_created', 'date_updated'],
  data() {
    return {
      showNoteDialog: false,
      newNote: '',
      newVisibility: '',
      noteError: false,
    }
  },
  methods: {
    noteTextareaKeypress() {
      if (this.newNote.length > 0) {
        this.noteError = false
      }
    },
    close_process() {
      this.showNoteDialog = false
      this.newNote = ''
      this.newVisibility = ''
      this.noteError = false
    },
    update_note() {
      if (this.newNote.trim() === '') {
        this.noteError = true
      } else {
        this.$emit('edit-note', {'noteId': this.note_id, 'newNote': this.newNote, 'newVisibility': this.newVisibility})
        this.close_process()
      }
    },

  }
}
</script>

<style>
.empty-note-error {
  color: #a42334;
  position: relative;
  left: 0;
  font-size: 0.85rem;
  width: 245px;
}
.edit-note-dialog {
  position: fixed;
  text-align: left;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  padding: 20px 30px;
  border: 2px solid #9e9b9a;
  width: 58vw;
  background: #fff;
  z-index: 100;
  box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.2);
  box-sizing: border-box;
  font-size: 0.85rem;
}
.v-application .edit-note-dialog p {
  margin-bottom: 16px;
}
.edit-note-dialog p:first-child {
  margin-bottom: 20px;
}
.edit-note-dialog input:disabled {
  background: #eee;
}
.edit-note-dialog label {
  margin-bottom: 8px;
}
.edit-note-dialog-confirm-btn {
  background: #c22c3f;
  color: #fff;
  padding: 5px 8px;
  border-radius: 5px;
}
.edit-note-dialog-cancel-btn {
  background: #fff;
  color: #c22c3f;
  padding: 5px 8px;
  border: 1px solid #c22c3f;
  border-radius: 5px;
}
.edit-note-edit-btn {
  width: 36px;
}
.message__body img.edit-note-edit-btn {
  width: 36px !important;
  height: auto !important;
  opacity: 1;
  border: 0;
}
.note-type label[for="new-note-type-private"],
.note-type label[for="new-note-type-public"] {
  width: auto;
  margin-right: 20px;
  padding-left: 1.6em;
}
.edit-note-last-edited {
  font-style: italic;
}
</style>
