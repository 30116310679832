<template>
  <span>
    <button @click="showForm" class="send-message">Send Message</button>
    <output class="contact-form-output" ref="outputBox"></output>
    <div class="contact-form" v-if="showContactForm">
      <form>
        <p>
          <label for="email">Your email:</label>
          <input :value="userEmail" disabled>
        </p>
        <p>
          <label>Send message to <strong>{{ uid }}</strong>:</label>
          <textarea :value="contactMessage" name="contactMessage" disabled></textarea>
        </p>
        <div class="contact-form-btns">
          <button class="contact-form-cancel-btn" type="button" @click="cancelPopup">Cancel</button>
          <button class="contact-form-confirm-btn" type="button" @click="messageToUser">Send</button>
        </div>
      </form>
      <div class="confirm-close" v-if="showConfirm">
        <p>Are you sure?</p>
        <p>
          <button class="confirm-close-yes" @click="showContactForm = false; showConfirm = false; contactMessage = ''">Yes</button>
          <button class="confirm-close-no" @click="showConfirm = false">No</button>
        </p>
      </div>
    </div>
  </span>
</template>

<script>
import axios from 'axios'
import Cookies from 'js-cookie'


export default {
  props: ['is_pdna', 'entry', 'uid'],
  data() {
    return {
      showContactForm: false,
      userEmail: '',
      contactMessage: '',
      showConfirm: false
    }
  },
  methods: {
    showForm() {
      this.showContactForm = true
      this.contactMessage = 'An Arthropod user (' + this.userEmail + ') is requesting contact with you regarding the following Item on your Media Watchlist:'
      if (this.is_pdna) {
          this.contactMessage += '\nPhotoDNA - ' + this.entry.slice(0, 10) + '...' + this.entry.slice(-10)
      } else {
          this.contactMessage += '\nSHA1 - ' + this.entry
      }
    },
    cancelPopup() {
      if (this.contactMessage === '') {
        this.showContactForm = false
      } else {
        this.showConfirm = true
      }
    },
    messageToUser() {
      if (this.contactMessage != '') {
        const csrftoken = Cookies.get('csrftoken')
        let message = this.contactMessage
        let email_sent = 'Something went wrong, message not sent'
        let media_data = {
          request_org: this.uid,
          hash_val: this.entry,
          request_msg: message
        }

        axios.post('/en/watchlist/send_email_to_user/', media_data, {'headers': {'X-CSRFToken': csrftoken}})
        .then((response) => {
          if (response.status === 200) {
            email_sent = 'Message sent to ' + this.uid
          }
          this.contactMessage = ''
          this.showContactForm = false
          this.$refs.outputBox.value = email_sent
          this.$refs.outputBox.classList.add('show-text', 'fade-out')
          this.$refs.outputBox.addEventListener('animationend', () => {
            this.$refs.outputBox.classList.remove('show-text', 'fade-out')
          })
        }).catch(
          err => {console.log("something went wrong! %o", err)}
        )
      }

    },
    getEmailAddress() {
      axios.get('/en/watchlist/user_email/').then(response => {
        this.userEmail = response.data.current_user_email
      }).catch(
        err => {console.log("something went wrong! %o", resp)}
      )
    }
  },
  mounted: function() {
      this.getEmailAddress()
  },
}
</script>

<style>
.contact-form {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  padding: 20px 30px 60px;
  border: 2px solid #9e9b9a;
  width: 50%;
  background: #fff;
  z-index: 100;
  box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.2);
}
.contact-form p:first-child {
  margin-bottom: 20px;
}
.contact-form input:disabled {
  background: #eee;
}
.contact-form label {
  margin-bottom: 8px;
}
.contact-form textarea {
  width: 100%;
  height: 150px;
  resize: none;
}
.send-message {
  background: #fff;
  color: #c22c3f;
  padding: 5px 8px;
  border: 1px solid #c22c3f;
  border-radius: 5px;
  font-size: 14px;
  float: right;
}
.contact-form-btns {
  position: absolute;
  right: 30px;
  bottom: 10px;
}
.contact-form-confirm-btn, .confirm-close-yes {
  background: #c22c3f;
  color: #fff;
  padding: 5px 8px;
  border-radius: 5px;
}
.contact-form-cancel-btn, .confirm-close-no {
  background: #fff;
  color: #c22c3f;
  padding: 5px 8px;
  border: 1px solid #c22c3f;
  border-radius: 5px;
}
.contact-form-output {
  font-weight: bold;
  opacity: 0;
}
.show-text {
  opacity: 1;
}
.fade-out {
  animation-delay: 5s;
  animation-name: fadeText;
  animation-duration: 1s;
}
@keyframes fadeText {
  0% { opacity: 1; }
  100% { opacity: 0; }
}
.confirm-close {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  border: 2px solid #9e9b9a;
  background: #fff;
  width: 300px;
  padding: 20px;
  box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.2);
}
.confirm-close p {
  text-align: center;
}
</style>
