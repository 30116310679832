import Vue from 'vue'
import Vuex from 'vuex'

import state from './store/state'
import * as mutations from './store/mutations'
import * as actions from './store/actions'

Vue.use(Vuex)

const store = new Vuex.Store({
  state() {
    return {...state}
  },
  mutations,
  actions,
})

export default store
