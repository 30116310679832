<template>
  <div>
    <dl class="dl-table dl-table--dense u-mb-xs">
      <div class="dl-table__row">
        <dt>Archive SHA1:</dt>
        <dd>
          {{archiveData.archive_sha1}}
        </dd>
      </div>
      <div class="dl-table__row">
        <dt>Filename:</dt>
        <dd> {{archiveData.filename}} </dd>
      </div>
      <div class="dl-table__row">
        <dt>Archive result:</dt>
        <dd> {{archiveData.archive_result}} </dd>
      </div>
      <div class="dl-table__row">
        <dt></dt>
        <dd>
          <button v-if="archiveData.doc_ids && archiveData.doc_ids.length > 0" @click="retrieveMessages" class="btn btn--sm open-details-btn">
            <b>Show Archive Messages</b>
          </button>
          <button v-if="archiveData.media && archiveData.media.length > 0" @click="hideMediaData = !hideMediaData" class="btn btn--sm open-details-btn">
            <b>Show Archive Media</b>
          </button>
        </dd>
      </div>
    </dl>

    <div v-if="!hideMediaData">
        <div class="details-container" @click.self="hideMediaData = !hideMediaData">
          <div class="details-popup">
            <p>Archive Media:</p>
            <button class="popup-close" @click="hideMediaData = !hideMediaData">
              &#x2716;
            </button>
            <div class="popup-details-list">
              <ol class="u-mb-0 u-ml-0">
                <li v-for="media in archiveData.media">
                  <message-result-media-details
                      :media-data="media"
                      :media-search-url="mediaSearchUrl"
                      :message-source="messageSource"
                      :index-type="indexType"
                      :parent-type="'archive'"
                  ></message-result-media-details>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>

    <div v-if="!hideDocIds">
      <div class="details-container" @click.self="hideDocIds = !hideDocIds">
        <div class="details-popup">
          <p>Messages containing this archive:</p>
          <button class="popup-close" @click="hideDocIds = !hideDocIds">
              &#x2716;
            </button>
          <div class="popup-details-list">
            <ol v-if="docIdData && docIdData.length > 0" class="u-mb-0 u-ml-0 doc-id-list">
              <li v-for="data in docIdData">
                <p class="doc-id"> {{buildDocIdText(data)}}</p>
                <span class="doc-id">
                  <a :href="docIdSearchLink(data.doc_id)">
                    See in context
                    <svg
                      class="icon u-fill-current u-fontsize-m"
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 100 100">
                      <path d="M47 2L35 14l27 27-40-1H6v20h16l40-1-27 27 12 12 48-48z"/>
                    </svg>
                  </a>
                </span>
              </li>
            </ol>
            <div v-else-if="!docIdData" class="alert alert--info u-text-center">
              {{ gettext('Loading...')}}
            </div>
            <div v-else class="alert">
              {{ gettext('The database is still processing these messages. Please check back later.')}}
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios'
import Cookies from 'js-cookie'
import MessageResultMediaDetails from "./MessageResultMediaDetails";


export default {
  name: "MessageResultArchiveDetails",
  components: {
    MessageResultMediaDetails,
  },
  props: {
    archiveData: {
      type: Object,
      required: true
    },
    mediaSearchUrl: {
      type: String,
      required: true
    },
    messageSource: {
      type: Object,
    },
    indexType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      hideMediaData: true,
      hideDocIds: true,
      docIdData: null,
      docIdsBtnText: "Show Doc IDs"
    }
  },
  methods: {
    docIdSearchLink(docId) {
      return `/en/message-search/?doc_id=${docId}`
    },
    retrieveMessages() {
      this.hideDocIds = !this.hideDocIds
      if (this.docIdData !== null) {
        return
      }
      const csrftoken = Cookies.get('csrftoken')
      let searchParams = new URLSearchParams()
      this.archiveData.doc_ids.forEach(doc_id => {
        searchParams.append("doc_ids", encodeURIComponent(doc_id))
      })
      axios.get("/en/message-search/batch_doc_id_details/", {params: searchParams}, {'headers': {'X-CSRFToken': csrftoken}}).then(response => {
          if (response.status === 200) {
            this.docIdData = response.data
          }
        }).catch((error) => {
          console.log("something went wrong! %o", error)
        })
    },
    buildDocIdText(docIdObj) {
      if (docIdObj.message_type === 'Chat Site') {
        return `${docIdObj.message_type} > ${docIdObj.site_name} > ${docIdObj.username} > ${docIdObj.posted_date}`
      } else if (docIdObj.message_type === 'Forum Post') {
        return `${docIdObj.message_type} > ${docIdObj.site_name} > ${docIdObj.topic} > ${docIdObj.title} > ${docIdObj.username} > ${docIdObj.posted_date}`
      }
    }
  }
}
</script>

<style>
button.open-details-btn {
  background: #c22c3f;
}
button.open-details-btn:hover {
  background: #a42334;
}
.details-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background: rgba(0,0,0,0.2);
  line-height: 1.5;
}
.details-popup {
  position: fixed;
  top: 200px;
  left: 50%;
  z-index: 11;
  transform: translateX(-50%);
  width: 60vw;
  max-height: 60vh;
  background: #f5f5f4;
  padding: 45px 30px 20px;
  border: 2px solid #cecbca;
  border-radius: 6px;
  box-shadow: 10px 10px 5px 0 rgba(0,0,0,0.2);
  box-sizing: border-box;
}
.popup-close {
  position: absolute;
  top: 5px;
  right: 10px;
}
.popup-details-list {
  overflow-y: scroll;
  max-height: 45vh;
  background: #FFF;
}
.popup-details-list ol li {
  position: relative;
  padding: 10px 10px 0 10px;
}
.popup-details-list ol {
  list-style: none;
}
.popup-details-list ol li:nth-of-type(even) {
  background-color: hsl(11,3%,96%);
}
ol.doc-id-list li {
  padding: 15px 10px 10px 10px;
  margin-top: 0;
  margin-bottom: 0;
}
.details-popup h3 {
  padding-left: 10px;
}
.doc-id {
  display: inline-block;
}
span.doc-id {
  margin-left: 15px;
}
p.doc-id {
  margin-bottom: 0;
}
</style>