<template>
  <h1 class="site-index__heading">
    <span class="u-fontsize-sm">
      {{ 'Topics in' }}
    </span>
    <b class="u-mt-xs u-display-block u-break">
      <a
        class="link--search"
        :href="`${messageSearchUrl}?filters=site_name:${siteName},forum_id:${forumId}`"
      >
        {{ forum }}
      </a>
      <note-buttons
        :watchlistMessage="{
          'url':siteUrl,
          'forum_watchlist': inWatchlist,
          '_source': {
            'site_id': siteId,
            'site_name': siteName,
            'forum': forum
          }
        }"
        :noteId="forumId"
        :noteName="forum"
        :noteItem="'forum'"
        :noteSiteId="siteId"
        :noteSiteName="siteName"
        :hasNotes="Boolean(parseInt(hasForumNotes))"
        :indexType="'forum'"
        :siteLink="getForumLink(siteName, forum)"
      />
    </b>
    <notes-legend />
  </h1>
</template>

<script>
import NoteButtons from '../notes/NoteButtons'
import NotesLegend from '../notes/NotesLegend'

export default {
  name: 'TopicContextSiteHeader',

  components: {
    NoteButtons,
    NotesLegend,
  },

  props: {
    messageSearchUrl: {
      type: String,
      required: true
    },
    siteName: {
      type: String,
      required: true
    },
    siteId: {
      type: String,
      required: true
    },
    forumId: {
      type: String,
      required: true
    },
    forum: {
      type: String,
      required: true
    },
    siteUrl: {
      type: String,
      default: ''
    },
    hasForumNotes: {
      type: String,
      required: true
    },
    inWatchlist: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      note: '',
      noteType: '',
      noteCategory: '',
      topicsData: [],
      topicsWatchlist: ''
    }
  },
  methods: {
    getForumLink(siteName, forum) {
      return new URL('/en/message-search/forum-sites/' + siteName + '/' + forum + '/', document.baseURI).href
    },
  },
}
</script>

<style scoped>

.site-index__heading .note-buttons {
  position: absolute;
  font-weight: normal;
}

</style>
