<template>
  <span>
    <p v-if="lengthError" class="watchlist-page-label-error">The entered SHA1 or PhotoDNA is not of correct length</p>
    <button @click="labelDialog" :disabled="this.data_val == null || this.data_val == ''" class="watchlist-page-btn watchlist-page-btn-add">Add</button>
    <div class="label-dialog" v-if="showLabelDialog">
      <p>
        <label>Label:</label>
        <input type="text" v-model="labelText" name="labelMessage" size="50">
        Note: Do not include victim information or media hashes in labels
      </p>
      <button type="button" class="label-dialog-cancel-btn" @click="showLabelDialog = false; labelText = ''">Cancel</button>
      <div class="label-dialog-btns">
        <button type="button" class="label-dialog-nolabel-btn" @click="add_to_watchlist">{{addLabel ? 'Add' : 'Add without Label'}}</button>
      </div>
    </div>
  </span>
</template>

<script>

export default {
  emits: ['add-data-watchlist'],
  props: ['data_val'],
  data() {
    return {
      showLabelDialog: false,
      labelText: '',
      lengthError: false,
      addLabel: false,
    }
  },
  watch: {
    labelText() {
      if (this.labelText !== '') {
        this.addLabel = true
      } else if (this.labelText === '') {
        this.addLabel = false
      }
    }
  },
  methods: {
    labelDialog() {
      if (this.data_val.trim().length === 288 || this.data_val.trim().length === 32) {
        this.showLabelDialog = true
        this.lengthError = false
      } else {
        this.lengthError = true
      }
    },
    add_to_watchlist() {
      this.$emit('add-data-watchlist', this.labelText)
      this.labelText = ''
      this.showLabelDialog = false
    },

  }
}
</script>

<style>
.watchlist-page-btn {
  background: #c22c3f;
  color: #fff;
  padding: 5px 8px;
  border-radius: 5px;
}
.watchlist-page-btn-add {
  float: right;
  text-transform: uppercase;
}
.watchlist-page-btn-add:disabled {
  background-image: none;
}
.watchlist-page-btn:active {
  background: #a42334;
}
.label-dialog {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  padding: 20px 30px 60px;
  border: 2px solid #9e9b9a;
  width: 50%;
  background: #fff;
  z-index: 100;
  box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.2);
}
.label-dialog p:first-child {
  margin-bottom: 20px;
}
.label-dialog input:disabled {
  background: #eee;
}
.label-dialog label {
  margin-bottom: 8px;
}
.label-dialog-btns {
  position: absolute;
  right: 30px;
}
.label-dialog-nolabel-btn,
.label-dialog-confirm-btn {
  background: #c22c3f;
  color: #fff;
  padding: 5px 8px;
  border-radius: 5px;
}
.label-dialog-cancel-btn {
  background: #fff;
  color: #c22c3f;
  padding: 5px 8px;
  border: 1px solid #c22c3f;
  border-radius: 5px;
}
.label-dialog-cancel-btn {
  position: absolute;
}
.watchlist-page-label-error {
  color: #a42334;
  position: absolute;
  left: 0;
  font-size: 0.85rem;
  width: 245px;
}
</style>
