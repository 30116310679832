<template>
  <div>
    <v-row>
      <v-col
          cols="6"
          sm="6"
      >
        <v-card
            class="mx-auto"
            tile
            v-if="hidePast30dGraph === true"
        >
          <v-card-text>
            <div class="canvas-spinner">
              <v-progress-circular
                  v-if="currentDayDataLoading"
                  indeterminate
                  color="accent"
                  size="20"
              >
              </v-progress-circular>
            </div>
            <canvas id="today-activity"></canvas>
          </v-card-text>
        </v-card>
        <v-card v-else>
          <v-card-text>
            <div class="canvas-spinner">
             No Message Found in past 30 days
            </div>
          </v-card-text>

        </v-card>
      </v-col>
      <v-col
          cols="6"
          sm="6"
      >
        <v-card
            class="mx-auto"
            tile
        >
          <v-card-text>
            <div class="canvas-spinner">
              <v-progress-circular
                  v-if="loading"
                  indeterminate
                  color="accent"
                  size="20"
              >
              </v-progress-circular>
            </div>
            <canvas id="average-time"></canvas>
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import axios from "axios";
import Chart from 'chart.js/auto'

export default {
  name: 'UserActivityAnalysis',
  props: {
    userName: {
      type: String,
      required: true
    },
    siteName: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      todayActivity: [],
      postedTime: [],
      docCount: [],
      dateLabel: [],
      avgDocCount: [],
      avgDateLabel: [],
      loading: false,
      currentDayDataLoading: false,
      hidePast30dGraph: true,
      hideOverallGraph:true
    }
  },
  mounted() {
    this.get_user_post_activity_timeline_data()
    this.get_user_post_average_time()
  },
  methods: {
    get_user_post_activity_timeline_data() {
      this.currentDayDataLoading = true
      const csrftoken = Cookies.get('csrftoken')
      const apiUrl = `/en/user/daily/post/timeline/${this.userName}/${this.siteName}/`
      axios.get(apiUrl, {'headers': {'X-CSRFToken': csrftoken}}).then(response => {
        this.todayActivity = response.data.data
        if (this.todayActivity.length === 0) {
          this.hidePast30dGraph = false
        }
        this.docCount = Object.keys(this.todayActivity)
        this.dateLabel = Object.values(this.todayActivity)
        this.renderChart(this.docCount, this.dateLabel)
        this.currentDayDataLoading = false
      })
    },
    get_user_post_average_time() {
      this.loading = true
      const csrftoken = Cookies.get('csrftoken')
      const apiUrl = `/en/user/post/timeline/${this.userName}/${this.siteName}/`
      axios.get(apiUrl, {'headers': {'X-CSRFToken': csrftoken}}).then(response => {
        this.postedTime = response.data.data
        if (this.postedTime.length === 0) {
          this.hideOverallGraph = false
        }
        this.avgDocCount = Object.keys(this.postedTime)
        this.avgDateLabel = Object.values(this.postedTime)
        this.renderAveragePostChart(this.avgDocCount, this.avgDateLabel)
        this.loading = false
        this.hideOverallGraph = true
      })
    },
    renderChart(date_label, doc_count) {
      const ctx = document.getElementById('today-activity');
      const date_label_list = date_label.map(hour=>hour<10?`0${hour}:00`: `${hour}:00`)
      const past30DayMessagePostTimeGraph = new Chart(ctx, {
        type: 'line',
        data: {
          labels: date_label_list,
          datasets: [
            {
              label: "Number of Messages",
              data: doc_count,
              fill: true,
              borderColor: 'rgb(194, 44, 63)',
              pointStyle: 'circle',
              radius: 5,
              tension: 0.1,
            }
          ]
        },
        options: {
          responsive: true,
          plugins: {
            title: {
              display: true,
              text: 'Message Timeline For Past 30 days'
            },
            legend: {
              labels: {
                usePointStyle: true,
              }
            },
          },
          scales: {
            x: {
              beginAtZero: true,
              title: {
                display: true,
                text: 'Time',
                font: {
                  weight: 'bold'
                }
              }
            },
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: 'Number of Messages',
                font: {
                  weight: 'bold'
                }
              }
            }
          }
        }
      });
    },
    renderAveragePostChart(date_label, doc_count) {
      const date_label_list = date_label.map(hour=>hour<10?`0${hour}:00`: `${hour}:00`)
      const ctx = document.getElementById('average-time');
      const overallMessagePostTimeGraph = new Chart(ctx, {
        type: 'line',
        data: {
          labels: date_label_list,
          datasets: [
            {
              label: "Number of Messages",
              data: doc_count,
              fill: true,
              borderColor: 'rgb(44,119,194)',
              tension: 0.1,
              pointStyle: 'circle',
              radius: 5,
            }
          ]
        },
        options: {
          responsive: true,
          plugins: {
            title: {
              display: true,
              text: 'Overall Message Timeline Summary'
            },
            legend: {
              labels: {
                usePointStyle: true,
              }
            },
          },
          scales: {
            x: {
              beginAtZero: true,
              title: {
                display: true,
                text: 'Time',
                font: {
                  weight: 'bold'
                }
              }
            },
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: 'Number of Messages',
                font: {
                  weight: 'bold'
                }
              }
            }
          }
        }
      });
    }
  }
}
</script>

<style scoped>
.canvas-spinner {
  text-align: center;
}
</style>