var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{ref:"message",staticClass:"message",class:{'hide': _vm.removedBookmark},attrs:{"id":_vm.message._id}},[_c('header',{staticClass:"message__metadata message__details-wrapper"},[_c('div',{staticClass:"message__metadata-info"},[_c('i',[_vm._v("\n        "+_vm._s(_vm.gettext('Forum:'))+"\n        "),_c('button',{staticClass:"fake-link",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.addFilters([
            { type: 'site_name', term: `${_vm.message._source.site_name}` },
            { type: 'forum', term: `${_vm.message._source.forum}` },
          ])}}},[_c('span',[_vm._v(_vm._s(_vm.message._source.forum))])]),_vm._v(" "),_c('note-buttons',{attrs:{"noteId":_vm.message._source.forum_id,"noteName":_vm.message._source.forum,"noteLinked":_vm.message._id,"noteSiteId":_vm.message._source.site_id,"noteSiteName":_vm.message._source.site_name,"noteItem":'forum',"hasNotes":_vm.message._source.has_forum_notes,"indexType":'forum',"siteLink":_vm.forumLink,"watchlistMessage":_vm.message},on:{"refresh":_vm.refresh}}),_vm._v("\n        "+_vm._s(_vm.gettext('on site:'))+"\n        "),_c('button',{staticClass:"fake-link",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.addFilters([
            { type: 'site_name', term: `${_vm.message._source.site_name}` },
          ])}}},[_c('span',[_vm._v(_vm._s(_vm.message._source.site_name))])]),_vm._v(" "),_c('note-buttons',{attrs:{"noteId":_vm.message._source.site_id,"noteName":_vm.message._source.site_name,"noteLinked":_vm.message._id,"noteSiteId":_vm.message._source.site_id,"noteSiteName":_vm.message._source.site_name,"noteItem":'site',"hasNotes":_vm.message._source.has_site_notes,"indexType":'forum',"siteLink":_vm.forumSiteLink,"watchlistMessage":_vm.message},on:{"refresh":_vm.refresh}}),_vm._v(" "),_c('bookmark-button',{attrs:{"message":_vm.message},on:{"bookmarkRemoved":_vm.bookmarkRemoved}})],1),_vm._v(" "),_c('details',{staticClass:"message__details u-mt-sm"},[_c('summary',[_vm._v("\n          "+_vm._s(_vm.gettext('Message details'))+"\n        ")]),_vm._v(" "),_c('dl',{staticClass:"dl-table dl-table--dense u-mt-sm u-mb-0 u-pl-md"},[_c('div',{staticClass:"dl-table__row"},[_c('dt',[_vm._v(_vm._s(_vm.gettext('Score:')))]),_vm._v(" "),_c('dd',[_vm._v(_vm._s(_vm.message._score))])]),_vm._v(" "),_c('div',{staticClass:"dl-table__row"},[_c('dt',[_vm._v(_vm._s(_vm.gettext('Post length:')))]),_vm._v(" "),_c('dd',[_vm._v(_vm._s(_vm.message._source.message.length))])]),_vm._v(" "),(_vm.message._source.forum_id)?_c('div',{staticClass:"dl-table__row"},[_c('dt',[_vm._v(_vm._s(_vm.gettext('Forum ID:')))]),_vm._v(" "),_c('dd',[_c('button',{staticClass:"link",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.addFilters([
                  { type: 'site_name', term: `${_vm.message._source.site_name}` },
                  { type: 'forum_id', term: `${_vm.message._source.forum_id}` },
                ])}}},[_vm._v("\n                "+_vm._s(_vm.message._source.forum_id)+"\n              ")])])]):_vm._e(),_vm._v(" "),(_vm.message._source.topic_id)?_c('div',{staticClass:"dl-table__row"},[_c('dt',[_vm._v(_vm._s(_vm.gettext('Topic ID:')))]),_vm._v(" "),_c('dd',[_c('button',{staticClass:"link",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.addFilters([
                  { type: 'site_name', term: `${_vm.message._source.site_name}` },
                  { type: 'topic_id', term: `${_vm.message._source.topic_id}` },
                ])}}},[_vm._v("\n                "+_vm._s(_vm.message._source.topic_id)+"\n              ")])])]):_vm._e(),_vm._v(" "),(_vm.message._source.post_id)?_c('div',{staticClass:"dl-table__row"},[_c('dt',[_vm._v(_vm._s(_vm.gettext('Post ID:')))]),_vm._v(" "),_c('dd',[_c('button',{staticClass:"link",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.addFilters([
                  { type: 'site_name', term: `${_vm.message._source.site_name}` },
                  { type: 'post_id', term: `${_vm.message._source.post_id}` },
                ])}}},[_vm._v("\n                "+_vm._s(_vm.message._source.post_id)+"\n              ")])])]):_vm._e(),_vm._v(" "),(_vm.message._source.uuid)?_c('div',{staticClass:"dl-table__row"},[_c('dt',[_vm._v(_vm._s(_vm.gettext('Doc ID:')))]),_vm._v(" "),_c('dd',[_vm._v("\n              "+_vm._s(_vm.message._source.uuid)+"\n            ")])]):_vm._e()])])]),_vm._v(" "),(_vm.siteSourceCheck)?_c('div',{staticClass:"message__metadata-onion"},[_c('img',{attrs:{"title":"This is a forum found on the Tor network","width":"50","src":"/static/images/logos/onion.png"}})]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"message__header message__header--forum"},[_c('p',{staticClass:"h4 u-mb-xs"},[_c('button',{staticClass:"fake-link",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.addFilters([
          { type: 'site_name', term: `${_vm.message._source.site_name}` },
          { type: 'topic_id', term: `${_vm.message._source.topic_id}` },
        ])}}},[_c('span',[(_vm.message._source.topic)?[_vm._v(_vm._s(_vm.message._source.topic))]:_c('i',[_vm._v(_vm._s(_vm.pgettext('no post topic available', '(no topic name)')))])],2)]),_vm._v(" "),_c('note-buttons',{attrs:{"noteId":_vm.message._source.topic_id,"noteName":_vm.message._source.topic,"noteLinked":_vm.message._id,"noteItem":'Topic',"hasNotes":_vm.message._source.has_topic_notes,"noteSiteId":_vm.message._source.site_id,"noteSiteName":_vm.message._source.site_name,"indexType":'forum',"watchlistMessage":_vm.message,"siteLink":_vm.topicLink},on:{"refresh":_vm.refresh}})],1),_vm._v(" "),_c('p',{staticClass:"u-m-0"},[_vm._v("\n      "+_vm._s(_vm.pgettext('noting author of post', 'by'))+"\n      "),(_vm.message._source.username)?_c('button',{staticClass:"fake-link",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.addFilters([
          { type: 'username', term: `${_vm.message._source.username}` },
        ])}}},[_c('span',[_c('b',[_vm._v(_vm._s(_vm.message._source.username))])])]):_c('i',[_vm._v(_vm._s(_vm.pgettext('(unknown)')))]),_vm._v(" "),_c('note-buttons',{attrs:{"noteId":_vm.message._source.user_id,"noteName":_vm.message._source.username,"noteLinked":_vm.message._id,"noteSiteId":_vm.message._source.site_id,"noteSiteName":_vm.message._source.site_name,"noteItem":'user',"hasNotes":_vm.message._source.has_user_notes,"indexType":'forum',"siteLink":_vm.userLink,"watchlistMessage":_vm.message},on:{"refresh":_vm.refresh}}),_vm._v("\n      "+_vm._s(_vm.pgettext('noting date of post', 'on'))+"\n      "),_c('time',{attrs:{"datetime":""}},[_vm._v("\n        "+_vm._s(_vm.readableDate(_vm.message._source.posted_date))+"\n      ")]),_vm._v(" "),_c('a',{staticClass:"u-pl-sm",attrs:{"href":`/en/user/detail/${_vm.message._source.username}/${_vm.message._source.site_name}/`}},[_vm._v("\n          View User Details\n          "),_c('svg',{staticClass:"icon u-fill-current u-fontsize-sm",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"14","height":"14","viewBox":"0 0 100 100"}},[_c('path',{attrs:{"d":"M47 2L35 14l27 27-40-1H6v20h16l40-1-27 27 12 12 48-48z"}})])])],1)]),_vm._v(" "),_c('message-result-body',{attrs:{"messageType":"forum","messageSource":_vm.message,"indexType":'forum',"siteLink":_vm.postLink},on:{"toggle-post":_vm.keepMessageInView}}),_vm._v(" "),(_vm.message._source.has_media && _vm.message._source.has_media === true)?_c('message-result-details',{attrs:{"docId":_vm.message._id,"mediaSearchUrl":_vm.mediaSearchUrl,"messageSource":_vm.message,"indexType":'forum'}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }