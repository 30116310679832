var render = function render(){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"site-index__heading"},[_c('span',{staticClass:"u-fontsize-sm"},[_vm._v("\n    Forums on\n  ")]),_vm._v(" "),_c('b',{staticClass:"u-mt-xs u-display-block u-break"},[_c('a',{staticClass:"link--search",attrs:{"href":`${_vm.messageSearchUrl}?filters=site_name:${_vm.siteName}`}},[_vm._v("\n      "+_vm._s(_vm.siteName)+"\n    ")]),_vm._v(" "),_c('note-buttons',{attrs:{"watchlistMessage":{
        'url':_vm.siteUrl,
        'site': {
          'site_name': _vm.siteName,
          'site_id': _vm.siteId
        },
        'site_name': _vm.siteName,
        'site_watchlist': _vm.inWatchlist,
      },"noteId":_vm.siteId,"noteName":_vm.siteName,"noteItem":'site',"noteSiteId":_vm.siteId,"noteSiteName":_vm.siteName,"hasNotes":Boolean(parseInt(_vm.hasSiteNotes)),"indexType":'forum',"siteLink":_vm.getSiteLink(_vm.siteName)}})],1),_vm._v(" "),_c('notes-legend')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }