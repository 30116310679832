<template>
  <div class="l-container--flex">
    <section id="watchlist-view">
      <div class="watchlist-input">
        <input
          id="add_data_list"
          v-model="data_val"
          type="text"
          placeholder="Enter SHA1 or PhotoDNA"
        >
        <watchlist-add-popup
          :data_val="data_val"
          @add-data-watchlist="add_data_watchlist"
        />
        <div
          v-if="dataValEmpty"
          class="watchlist-error-msg"
        >
          Please Enter PhotoDNA or SHA1
        </div>
      </div>
      <p
        v-if="alreadyPresentError"
        class="already-present-error"
      >
        The entered media is already present in your watchlist
      </p>
      <div class="watchlist-items">
        <div
          v-for="val_list in list_data"
          :key="val_list.data_key"
          class="watchlist-item"
        >
          <media-details
            :is_pdna="val_list.is_pdna"
            :entry="val_list.data_key"
            :is_new="val_list.has_new_sighting"
            :label="val_list.label"
            :latest_sighting_date="val_list.latest_sighting_date"
            @update-label="update_label"
            @remove-item="remove_item"
          />
          <other-users-details
            :is_pdna="val_list.is_pdna"
            :entry="val_list.data_key"
            :resultOthersCnt="resultOthersCnt"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios'
import Cookies from 'js-cookie'
import MediaDetails from './MediaDetails'
import OtherUsersDetails from './OtherUsersDetails'
import WatchlistAddPopup from './WatchlistAddPopup'

export default {
  name: 'WatchList',
  components: {
    MediaDetails,
    OtherUsersDetails,
    WatchlistAddPopup,
  },
  props: {
    resultWatchList: {
      type: Array,
      required: true
    },
    resultOthersCnt: {
      type: Object,
      required: true
    },
  },

  data() {
    return {
      isRemoved: false,
      data_val: null,
      list_data: [],
      data_removed: null,
      dataValEmpty: false,
      values_obj: null,
      is_new: [],
      alreadyPresentError: false,
    }
  },

  mounted() {
    this.list_data = this.sortNewSightings(this.resultWatchList)
    for (let val in this.list_data) {
      this.is_new.push(this.list_data[val].has_new_sighting)
    }
  },

  methods: {
    update_label(args) {
      for (const obj in this.list_data) {
        if (this.list_data[obj].data_key === args['hash_val']) {
          this.list_data[obj].label = args['new_label']
        }
      }
    },

    remove_item(dataKey) {
      // remove the media entry from the watchlist by sending the hash and getting a response
      var mediaData = {hash_val: dataKey}
      const csrftoken = Cookies.get('csrftoken')
      axios.post('/en/watchlist/remove/', mediaData, {'headers': {'X-CSRFToken': csrftoken}}).then(
        (response) => this.success(response, dataKey)
      ).catch(
        err => { console.log('Something went wrong! %o', err) }
      )
    },

    success(response, dataKey) {
      // check if data has been removed from watchlist in backend, then remove the item from UI in order to show the
      // update without refreshing
      var dataRemoved = response.data.data_removed
      if (dataRemoved) {
        this.list_data = this.list_data.filter((value) => {
          return value.data_key !== dataKey
        })
      }
    },

    add_data_watchlist(labelInput) {
      // add a media entry to the watchlist and add the hash to local list used in vue to immediately update the data
      // on UI without refreshing
      var mediaData = {}
      var isPdna
      var canAdd = false
      this.dataValEmpty = false

      if (this.data_val != null) {
        if (this.data_val.trim().length === 288) {
          isPdna = true
          canAdd = true
          mediaData = {
            label: labelInput,
            pdna_key: this.data_val.trim()
          }
        } else if (this.data_val.trim().length === 32) {
          isPdna = false
          canAdd = true
          mediaData = {
            label: labelInput,
            sha1_key: this.data_val.trim()
          }
        }

        if (Object.keys(mediaData).length !== 0) {
          const csrftoken = Cookies.get('csrftoken')
          axios.post('/en/watchlist/add/', mediaData, {'headers': {'X-CSRFToken': csrftoken}}).then(
            resp => {
              console.log('got a response! %o', resp)
            }).catch(
            err => { console.log('something went wrong! %o', err) }
          )
        }
        const mediaVals = this.list_data.map(function(mediaVal) {
          return mediaVal.data_key
        })

        if (!mediaVals.includes(this.data_val.trim()) && canAdd) {
          // puts new item at beginning of array
          this.list_data.unshift({
            'data_key': this.data_val.trim(),
            'is_pdna': isPdna,
            'has_new_sighting': false,
            'label': labelInput,
            'latest_sighting_date': new Date().toLocaleString()
          })
        } else {
          this.alreadyPresentError = true
        }
        this.data_val = ''
      } else {
        if (this.data_val === null) {
          this.dataValEmpty = true
        }
      }
    },
    sortNewSightings(list) {
      let sortedList = list.sort((a, b) => a.latest_sighting_date - b.latest_sighting_date)
      return sortedList
    }
  }

}
</script>

<style>
#watchlist-view {
  display: flex;
  width: 100%;
}
.watchlist-input {
  margin-top: 62px;
  padding-right: 50px;
  width: 30%;
}
.watchlist-input select, .watchlist-input input {
  margin-bottom: 20px;
}
.watchlist-page-btn {
  background: #c22c3f;
  color: #fff;
  padding: 5px 8px;
  border-radius: 5px;
}
.watchlist-page-btn-add {
  float: right;
  text-transform: uppercase;
}
.watchlist-page-btn:active {
  background: #a42334;
}
.watchlist-error-msg {
  font-size: 14px;
  color: #a32435;
}
.watchlist-items {
  width: 60%;
  margin-top: 62px;
}
.watchlist-item {
  padding: 20px 20px 20px 20px;
  border: 2px solid #cecbca;
  margin-bottom: 20px;
  position: relative;
}
.watchlist-item summary {
  cursor: pointer;
}
.watchlist-item .result-grid__item.sha1 {
  width: 100%;
}
.already-present-error {
  color: #a42334;
  position: absolute;
  font-size: 0.85rem;
  width: 245px;
}
</style>
