var render = function render(){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"site-index__heading"},[_c('span',{staticClass:"u-fontsize-sm"},[_vm._v("\n    "+_vm._s('Topics in')+"\n  ")]),_vm._v(" "),_c('b',{staticClass:"u-mt-xs u-display-block u-break"},[_c('a',{staticClass:"link--search",attrs:{"href":`${_vm.messageSearchUrl}?filters=site_name:${_vm.siteName},forum_id:${_vm.forumId}`}},[_vm._v("\n      "+_vm._s(_vm.forum)+"\n    ")]),_vm._v(" "),_c('note-buttons',{attrs:{"watchlistMessage":{
        'url':_vm.siteUrl,
        'forum_watchlist': _vm.inWatchlist,
        '_source': {
          'site_id': _vm.siteId,
          'site_name': _vm.siteName,
          'forum': _vm.forum
        }
      },"noteId":_vm.forumId,"noteName":_vm.forum,"noteItem":'forum',"noteSiteId":_vm.siteId,"noteSiteName":_vm.siteName,"hasNotes":Boolean(parseInt(_vm.hasForumNotes)),"indexType":'forum',"siteLink":_vm.getForumLink(_vm.siteName, _vm.forum)}})],1),_vm._v(" "),_c('notes-legend')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }