<template>
  <form
    method="POST"
    enctype="multipart/form-data"
    :action="action"
    novalidate
    autocomplete="off"
    class="u-mt-xl u-mb-lg"
    @submit.prevent="submit"
  >
    <input
      type="hidden"
      name="csrfmiddlewaretoken"
      :value="csrfToken"
    >
    <fieldset class="u-mb-0">
      <legend>Search by Media File(s)</legend>
      <p>Select media type to search with</p>
      <input
        id="media_type_img"
        v-model="media_type"
        type="radio"
        name="media_type"
        value="image"
      >
      <label for="media_type_img">Images</label>
      <input
        id="media_type_vid"
        v-model="media_type"
        type="radio"
        name="media_type"
        value="video"
      >
      <label for="media_type_vid">Videos</label>
      <input
        id="media_type_csv"
        v-model="media_type"
        type="radio"
        name="media_type"
        value="csv"
      >
      <label for="media_type_csv">CSV File</label>
      <dropzone
        id="file-drop"
        :url="action"
        :formState="state"
        :filesVuelidate="$v.totalFiles"
        :maxFilesCnt="maxFiles"
        :maxFilesize="maxFilesize"
        :resetDropzone="resetDropzone"
        :csrfToken="csrfToken"
        :xtraParams="dzParams"
        :acceptedMIME="Array.from(accepted_mime).join(',')"
        :fileTypeDescription="media_type"
        @vue-dz-error="dzError"
        @vue-dz-erroredFiles="state = 'form'"
        @vue-dz-success="queueComplete"
        @vue-dz-totalfiles="updateTotalFiles"
        @vue-dz-queuingfiles="queuingFiles"
        @vue-dz-reset="resetUploader"
        @vue-dz-removedall="removedAll"
        @vue-dz-checkstate="checkState"
      />
    </fieldset>

    <div class="form-group">
      <details>
        <summary class="additional-details">
          Additional Details
        </summary>

        <label for="incident_number">Police Incident Number</label>
        <input
          id="incident_number"
          v-model="police_id_number"
          type="text"
          name="incident_number"
          style="width:300px"
          @change="updateIncidentNumber"
        >

        <label for="victim_age">Victim's Age<small> (at time of production)</small></label>
        <input
          id="victim_age"
          v-model="victim_age"
          type="text"
          name="victim_age"
          style="width:300px"
          @change="updateVictimAge"
        >

        <label for="comments">Comments</label>
        <textarea
          id="comments"
          v-model="comments"
          name="comments"
          rows="4"
          style="width:300px"
          @change="updateComments"
        />
      </details>
    </div>

    <div v-if="media_type !== 'csv'">
      <input
        id="add-to-arachnid"
        v-model="add_to_arachnid"
        type="checkbox"
        @change="updateAddToArachnid"
      >
      <label for="add-to-arachnid">
        <span>Check to upload the media to C3P Database. <br>Adding media to C3P database will allow it to be classified and takedown notices to be sent on subsequent sightings of this specific or similar media. If unchecked, only the search you requested will happen, and your media files will not be uploaded to C3P database.</span>
      </label>
    </div>

    <div v-if="state === 'unacceptable_file_type_error'">
      <div
        v-if="(media_type === 'image') || (media_type === 'video')"
        class="alert alert--error"
      >
        <p>
          Search only accepts media at least 50x50 in dimensions and at most 2GB in filesize.
        </p>
        <p>
          Please <a href="/en/contact/">contact us</a> if you require assistance.
        </p>
      </div>

      <button
        class="btn btn--primary"
        type="button"
        @click.prevent="resetDropzone = true"
      >
        Reset
      </button>
    </div>

    <div v-if="state === 'hash_column_missing'">
      <div class="alert alert--error">
        <p>
          Expected a CSV column for 'hash', 'md5', or 'sha1', but none found
        </p>
        <p>
          Please <a href="/en/contact/">contact us</a> if you require assistance.
        </p>
      </div>
      <button
        class="btn btn--primary"
        type="button"
        @click.prevent="resetDropzone = true"
      >
        Reset
      </button>
    </div>

    <div v-if="state === 'pdna_column_missing'">
      <div class="alert alert--error">
        <p>
          Expected a CSV column for 'photodna' or 'pdna', but none found
        </p>
        <p>
          Please <a href="/en/contact/">contact us</a> if you require assistance.
        </p>
      </div>
      <button
        class="btn btn--primary"
        type="button"
        @click.prevent="resetDropzone = true"
      >
        Reset
      </button>
    </div>

    <div v-if="state === 'image_too_small'">
      <div class="alert alert--error">
        <p>
          Images need to be at least 50x50 in size.
        </p>
        <p>
          Please <a href="/en/contact/">contact us</a> if you require assistance.
        </p>
      </div>
      <!-- the retry button doesn't really do anything since in this case something went sideways with the upload, so
           you'd need to do the upload again anyway to retry.  Just expose the reset button as it makes more sense -->
      <!-- <button @click.prevent="submit" class="btn btn--dark u-mr-sm" type="submit" :disabled="state === 'processing'">
        Retry search
      </button> -->
      <button
        class="btn btn--primary"
        type="button"
        @click.prevent="resetDropzone = true"
      >
        Reset
      </button>
    </div>

    <div v-if="state === 'extra_files_uploaded' || limitReached === true">
      <div class="alert alert--error">
        <p>
          Please include no more than {{ maxFiles }} {{ media_type }}<template v-if="maxFiles > 1">
            s
          </template>.
        </p>
      </div>
    </div>

    <div v-if="state === 'error'">
      <div class="alert alert--error">
        <p>
          An error occurred while attempting the search.
        </p>
        <p>
          Please <a href="/en/contact/">contact us</a> if you continue to experience errors.
        </p>
      </div>
      <!-- the retry button doesn't really do anything since in this case something went sideways with the upload, so
           you'd need to do the upload again anyway to retry.  Just expose the reset button as it makes more sense -->
      <!-- <button @click.prevent="submit" class="btn btn--dark u-mr-sm" type="submit" :disabled="state === 'processing'">
        Retry search
      </button> -->
      <button
        class="btn btn--primary"
        type="button"
        @click.prevent="resetDropzone = true"
      >
        Reset
      </button>
    </div>

    <div v-if="state === 'no_hash_pdna_found'">
      <div class="alert alert--error">
        <p>
          No Hash or PhotoDNA found in the file
        </p>
        <p>
          Please <a href="/en/contact/">contact us</a> if you continue to experience errors.
        </p>
      </div>
    </div>

    <div v-if="state === 'no_data_error'">
      <div class="alert alert--error">
        <p>
          No Data present in file
        </p>
        <p>
          Please <a href="/en/contact/">contact us</a> if you continue to experience errors.
        </p>
      </div>
    </div>

    <div v-if="state === 'image_process_error'">
      <div class="alert alert--error">
        <p>
          The image could not be processed.
        </p>
        <p>
          Please <a href="/en/contact/">contact us</a> if you continue to experience errors.
        </p>
      </div>
    </div>

    <div v-if="state !== 'error' && state !== 'form' && state !== 'extra_files_uploaded'">
      <div
        v-if="state === 'success' || state === 'batch_success'"
        class="alert alert--success"
      >
        <svg
          class="file-drop__icon file-drop__icon--success-sm"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          width="20"
          aria-hidden="true"
        >
          <path d="M38 87L4 54l14-15 20 21 43-44 14 14z" />
        </svg>
        <div v-if="media_type === 'image'">
          Image uploaded successfully
        </div>
        <div v-else-if="media_type === 'csv'">
          CSV File uploaded successfully
        </div>
      </div>
      <div
        v-if="state === 'videosuccess'"
        class="alert alert--success"
      >
        <svg
          class="file-drop__icon file-drop__icon--success-sm"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          width="20"
          aria-hidden="true"
        >
          <path d="M38 87L4 54l14-15 20 21 43-44 14 14z" />
        </svg>
        Video(s) uploaded successfully. You will receive an emailed report of image matches based on frames
        extracted from this video
      </div>
      <div
        v-if="state === 'arachnidexists'"
        class="alert alert--info"
      >
        <svg
          class="file-drop__icon"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 106"
          width="24"
          aria-hidden="true"
        >
          <path d="M50 1C22.9 1 1 22.9 1 50s21.9 49 49 49 49-21.9 49-49S77.1 1 50 1zm8 82c0 1.3-.7 2.4-2 3s-3.1 1-6 1-4.7-.4-6-1-2-1.7-2-3v-9c0-1.3.7-2.4 2-3s3.1-1 6-1 4.7.4 6 1 2 1.7 2 3v9zm1-66l-4 42c-.1.9-1 3-5 3s-4.9-2.1-5-3l-4-42c-.1-1.1 0-2 1-3s1.8-1 3-1h10c1.2 0 2 0 3 1s1.1 1.9 1 3z" />
        </svg>

        <div v-if="media_type === 'image'">
          This image has already been added to C3P Database
        </div>
        <div v-else-if="media_type === 'csv'">
          This CSV File's data has already been added to C3P Database
        </div>
      </div>
      <div
        v-if="state === 'arachnidsuccess'"
        class="alert alert--success"
      >
        <svg
          class="file-drop__icon file-drop__icon--success-sm"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          width="20"
          aria-hidden="true"
        >
          <path d="M38 87L4 54l14-15 20 21 43-44 14 14z" />
        </svg>
        <div v-if="media_type === 'image'">
          Image uploaded and submitted to C3P Database successfully
        </div>
        <div v-else-if="media_type === 'csv'">
          CSV File uploaded and submitted to C3P Database successfully
        </div>
      </div>
      <div
        v-if="state === 'videoarachnidsuccess'"
        class="alert alert--success"
      >
        <svg
          class="file-drop__icon file-drop__icon--success-sm"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          width="20"
          aria-hidden="true"
        >
          <path d="M38 87L4 54l14-15 20 21 43-44 14 14z" />
        </svg>
        Video(s) uploaded and submitted to C3P Database successfully. You will receive an emailed report of image matches based on frames
        extracted from this video
      </div>
      <button
        class="btn btn--primary"
        type="button"
        @click.prevent="resetDropzone = true"
      >
        Search again with different media
      </button>
    </div>

    <div
      v-else-if="totalFiles >= 1 && state !== 'error' && state !== 'unacceptable_file_type_error'"
      class="u-mb-lg"
    >
      <button
        class="btn btn--xl btn--primary"
        type="submit"
        :disabled="state === 'processing'"
        @click.prevent="checkValidity"
      >
        <span v-if="state === 'processing'">Processing…</span>
        <!-- <span v-else-if="state === 'error'">Retry</span> -->
        <!-- <span v-else>Process image</span> -->

        <!-- As long as this form bumps you straight into a PDNA search, make this button tell it like it is: -->
        <span v-else>
          <img
            src="/static/images/icons/icon-search-white.svg"
            class="file-drop__icon"
            width="20"
            alt=""
            aria-hidden="true"
          >
          Search
        </span>
      </button>
    </div>
  </form>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { between } from 'vuelidate/lib/validators'
import Clipboard from 'clipboard'

import Dropzone from './Dropzone'

export default {
  name: 'FileUploadForm',
  components: { Dropzone },
  mixins: [validationMixin],
  props: {
    action: {
      type: String,
      required: true
    },
    referrer: {
      type: String,
      default: ''
    },
    csrfToken: {
      type: String,
      required: true
    }
  },
  emits: ['got-file-type'],
  data() {
    return {
      files: [],
      totalFiles: 0,
      add_to_arachnid: false,
      police_id_number: '',
      victim_age: '',
      comments: '',
      notNumber: false,
      showArachnidInfo: false,
      imageID: '',
      dzParams: {
        'add_to_arachnid': false,
        'acceptedFiles': new Set(['image/*'])
      },
      errors: {},
      newSHA: '',
      newPDNA: '',
      state: 'form',
      resetDropzone: false,
      reset_image_search: false,
      accepted_mime: new Set(['image/*']),
      media_type: 'image',
      maxFiles: 5,
      maxFilesize: 2000,
      limitReached: false,
      clipboard: null
    }
  },
  computed: {
    showForm() { return this.state === 'form' },
    showProcessing() { return this.state === 'processing' },
    showSuccess() { return this.state === 'success' },
    showError() { return this.state === 'error' },
  },
  watch: {
    reset_image_search(value) {
      if (value === true) {
        this.resetDropzone = true
        // this.reset_image_search = false;
      }
    },
    media_type: {
      immediate: true,
      handler(newVal, oldVal) {
        if (oldVal) {
          // makes sure we don't run this on initial load, otherwise we end up
          // with resetDropzone locked to true
          this.resetDropzone = true
          this.accepted_mime.clear() // Clear old entries because new option is selected

          if (newVal === 'video') {
            this.accepted_mime.add('video/*')
            this.maxFiles = 1 // Only 1 video file can be uploaded at a time
          } else if (newVal === 'csv') { // Accept two kinds of files in this case, second is for Windows
            this.accepted_mime.add('text/csv')
            this.accepted_mime.add('application/vnd.ms-excel')
            this.maxFiles = 1
          } else if (newVal === 'image') {
            this.accepted_mime.add('image/*')
            this.maxFiles = 5
          }
          this.$emit('got-file-type', this.media_type)
          this.$set(this.dzParams, 'acceptedFiles', this.accepted_mime)
        }
      }
    },

  },
  validations() {
    let v = {
      totalFiles: {
        required: between(1, parseInt(this.maxFiles))
      },
      add_to_arachnid: {}
    }
    return v
  },

  mounted() {
    // set up copy-to-clipboard listeners for when the eventual SHA-1/PDNA results get rendered
    this.clipboard = new Clipboard('[data-clipboard-target]', {
      target: function(trigger) {
        return document.querySelector(trigger.dataset.clipboardTarget)
      }
    })
    window._paq.push(['FormAnalytics::scanForForms', this.$el])
  },

  methods: {
    checkValidity() {
      this.showArachnidInfo = false

      // console.log('checking form field validity')
      if (this.$v.$invalid) {
        this.$v.$touch()
        this.$nextTick(() => this.scrollToError())
        return
      }
      // console.log('form field validity looks good, now for dropzone')
      // good, now tell the dropzone component to get started uploading:
      this.state = 'processing'
    },

    error(error) {
      this.state = 'error'
      console.log(error)
    },
    matchMIME(mimePattern, mimeType) {
      // Need to check for a wildcard in each of the listed mime types as there are more than one now
      if (mimePattern) {
        if (mimePattern.includes(',')) {
          var arrPatterns = mimePattern.split(',')
          for (var i = 0; i < arrPatterns.length; i++) {
            var item = arrPatterns[i]
            // look for a wildcard to denote all types in the pattern
            if (item.includes('*')) {
              var arrGeneralMatch = item.split('/')
              var generalMatch = arrGeneralMatch[0]
              var arrMatchable = mimeType.split('/')
              var matchable = arrMatchable[0]
              if (matchable === generalMatch) {
                // wildcard match
                return true
              }
            } else {
              if (item === mimeType) {
                // specific match
                return true
              }
            }
          }
        } else if (mimePattern === mimeType) {
          return true
        }
      }
      return false
    },
    dzError(file, message, xhr, totalFiles) {
      // console.log('heard dzError,', file, message, xhr)
      // now that we're filtering accepted files by mime type, I think we should output a more specific
      // message if that's why the error is being called.
      if (totalFiles < this.maxFiles) {
        var uploadedMimeType = file.type
        if (message.includes('50x50')) { // message must include 50x50 to make this work from backend to frontend
          this.state = 'image_too_small'
          return
        } else if (message.includes("Expected a CSV column for 'hash', 'md5', or 'sha1'")) {
          this.state = 'hash_column_missing'
          return
        } else if (message.includes("Expected a CSV column for 'photodna' or 'pdna'")) {
          this.state = 'pdna_column_missing'
          return
        } else if (message.includes('You can not upload any more files')) {
        //  this.state = 'extra_files_uploaded'
          this.limitReached = true
          return
        }
        if (uploadedMimeType) {
          if (!this.matchMIME(Array.from(this.accepted_mime).join(','), uploadedMimeType)) {
            this.state = 'unacceptable_file_type_error'
          } else {
            // this is an error not related to accepted file type (mime type) so pass along the error state
            this.state = 'error'
          }
        } else {
          this.state = 'error'
        }
      }

      if (message.includes('The image could not be processed.')) {
        this.state = 'image_process_error'
        return
      }

    },

    removedAll() {
      this.state = 'form'
    },

    checkState(fileList) {
      let fileListLength = fileList.length
      if (fileListLength > this.maxFiles) {
        this.limitReached = true
      } else {
        this.limitReached = false
        for (let file of fileList) {
          let uploadedMimeType = file.type
          let acceptedMimeType = this.accepted_mime

          // Two cases of media type here. In case of CSV, match whole strings. Otherwise, match first word before /
          if (this.media_type === 'csv') {
            if (acceptedMimeType.has(uploadedMimeType)) { // Set has 2 values here
              this.state = 'form'
            } else {
              this.state = 'unacceptable_file_type_error'
              return
            }
          } else { // If accepted_mime is 'image/*' or 'video/*'
            let acceptedMimeVal = acceptedMimeType.values().next().value // Set has only 1 value here
            let mimeAccepted = acceptedMimeVal.substring(0, acceptedMimeVal.indexOf('/'))
            if (uploadedMimeType.includes(mimeAccepted)) {
              this.state = 'form'
            } else {
              this.state = 'unacceptable_file_type_error'
              return
            }
          }
        } // endfor
      }
    },

    queuingFiles() {
      // console.log('heard vue-dz-queuingfiles')
    },

    queueComplete(file, response) {
      let name = file.name
      let addToArachnid = this.add_to_arachnid
      let sha1 = response.sha1
      let pdna = response.pdna
      this.newSHA = sha1
      this.newPDNA = pdna
      let responseStatus = response.status
      this.state = responseStatus
      this.files.push({
        name,
        addToArachnid,
        sha1,
        pdna
      })

      if ((responseStatus === 'success') || (responseStatus === 'arachnidsuccess') || (responseStatus === 'arachnidexists')) {
        this.searchPDNA(pdna)
      } else if ((responseStatus === 'videosuccess') || (responseStatus === 'videoarachnidsuccess')) {
        this.searchSHA(sha1)
      } else if (responseStatus === 'batch_success') {
        // for multiple uploads, we only want to trigger the
        // parent that things are done until all the files
        // are actually uploaded.
        // TODO: handle things if one or more of the files fails upload...
        if (this.totalFiles === this.files.length) {
          this.resetDropzone = true // this resets the upload form UI.
          this.batchSuccess() // alert the parent that we're done uploading the multiple files.
        }
      } else if (responseStatus === 'batch_partial_fail') {
        this.resetDropzone = true
        this.batchPartialFail(response.message)
      }
    },
    success(response) {
      this.state = 'success'
    },

    updateTotalFiles(files) {
      this.totalFiles = files
    },

    resetUploader() {
      this.resetDropzone = false
      this.state = 'form'
      this.notNumber = false
      this.$v.$reset()
      this.files = []
      this.add_to_arachnid = false
      this.police_id_number = ''
      this.victim_age = ''
      this.comments = ''
      this.$set(this.dzParams, 'add_to_arachnid', false)
      this.$set(this.dzParams, 'police_id_number', '')
      this.$set(this.dzParams, 'victim_age', '')
      this.$set(this.dzParams, 'comments', '')
    },

    getFocusableEls() {
      const focusables = this.$el.querySelectorAll('a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]')
      return Array.prototype.slice.call(focusables)
    },

    scrollToError() {
      const errorEls = [...this.$el.querySelectorAll('.error')]
      const firstError = errorEls.filter(el => el.tagName.toUpperCase() !== 'LEGEND')[0]

      if (firstError) {
        const focusTarget = this.getFocusableEls().filter(formEl => firstError.contains(formEl))
        focusTarget[0].focus()
      }
    },
    updateAddToArachnid(event) {
      this.$set(this.dzParams, 'add_to_arachnid', this.add_to_arachnid)
    },
    updateIncidentNumber(event) {
      this.$set(this.dzParams, 'police_id_number', this.police_id_number)
    },
    updateVictimAge(event) {
      this.$set(this.dzParams, 'victim_age', this.victim_age)
    },
    updateComments(event) {
      this.$set(this.dzParams, 'comments', this.comments)
    },
    searchSHA(value) {
      this.$emit('videoUploadComplete', value)
      // window.location.href = "/en/images/?sha1=" + this.newSHA
    },
    searchPDNA(value) {
      this.$emit('uploadComplete', value)
    },
    batchSuccess() {
      this.$emit('batchSuccess')
    },
    batchPartialFail(data) {
      this.$emit('batchPartialFail', data)
    }
  }
}

</script>

<style scoped>
.additional-details {
  font-weight: bold; cursor: pointer;
}
</style>
