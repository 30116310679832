import Vue from "vue";

/**
 * Contains globally defined filters that may be used in any and all components
 */


Vue.filter("formatDate", (date) => {
    if (parseInt(date) === date) {
        // this is unixEpoch (seconds) need to be in milliseconds
        date = date * 1000;
    }
    let theDate = new Date(date);
    return theDate.toLocaleDateString('en-CA', {year: 'numeric', month: 'short', day: 'numeric'});
});

Vue.filter("shortenString", (stringToShorten, maxLength = 18) => {
    if (stringToShorten.length > maxLength) {
        return stringToShorten.substring(0, maxLength) + '...';
    }
    return stringToShorten;
});
