<template>
  <section
    :id="message._id"
    ref="message"
    :class="{'hide': removedBookmark}"
    class="message"
  >
    <header class="message__metadata message__details-wrapper">
      <div class="message__metadata-info">
        <i>
          {{ gettext('Forum:') }}
          <button
            type="button"
            class="fake-link"
            @click.prevent="addFilters([
              { type: 'site_name', term: `${message._source.site_name}` },
              { type: 'forum', term: `${message._source.forum}` },
            ])"
          >
            <span>{{ message._source.forum }}</span>
          </button>
          <note-buttons
            :noteId="message._source.forum_id"
            :noteName="message._source.forum"
            :noteLinked="message._id"
            :noteSiteId="message._source.site_id"
            :noteSiteName="message._source.site_name"
            :noteItem="'forum'"
            :hasNotes="message._source.has_forum_notes"
            :indexType="'forum'"
            :siteLink="forumLink"
            :watchlistMessage="message"
            @refresh="refresh"
          />
          {{ gettext('on site:') }}
          <button
            type="button"
            class="fake-link"
            @click.prevent="addFilters([
              { type: 'site_name', term: `${message._source.site_name}` },
            ])"
          >
            <span>{{ message._source.site_name }}</span>
          </button>
          <note-buttons
            :noteId="message._source.site_id"
            :noteName="message._source.site_name"
            :noteLinked="message._id"
            :noteSiteId="message._source.site_id"
            :noteSiteName="message._source.site_name"
            :noteItem="'site'"
            :hasNotes="message._source.has_site_notes"
            :indexType="'forum'"
            :siteLink="forumSiteLink"
            :watchlistMessage="message"
            @refresh="refresh"
          />
          <bookmark-button
            :message="message"
            @bookmarkRemoved="bookmarkRemoved"
          />
        </i>
        <details class="message__details u-mt-sm">
          <summary>
            {{ gettext('Message details') }}
          </summary>
          <dl class="dl-table dl-table--dense u-mt-sm u-mb-0 u-pl-md">
            <div class="dl-table__row">
              <dt>{{ gettext('Score:') }}</dt>
              <dd>{{ message._score }}</dd>
            </div>
            <div class="dl-table__row">
              <dt>{{ gettext('Post length:') }}</dt>
              <dd>{{ message._source.message.length }}</dd>
            </div>
            <div
              v-if="message._source.forum_id"
              class="dl-table__row"
            >
              <dt>{{ gettext('Forum ID:') }}</dt>
              <dd>
                <button
                  type="button"
                  class="link"
                  @click.prevent="addFilters([
                    { type: 'site_name', term: `${message._source.site_name}` },
                    { type: 'forum_id', term: `${message._source.forum_id}` },
                  ])"
                >
                  {{ message._source.forum_id }}
                </button>
              </dd>
            </div>
            <div
              v-if="message._source.topic_id"
              class="dl-table__row"
            >
              <dt>{{ gettext('Topic ID:') }}</dt>
              <dd>
                <button
                  type="button"
                  class="link"
                  @click.prevent="addFilters([
                    { type: 'site_name', term: `${message._source.site_name}` },
                    { type: 'topic_id', term: `${message._source.topic_id}` },
                  ])"
                >
                  {{ message._source.topic_id }}
                </button>
              </dd>
            </div>
            <div
              v-if="message._source.post_id"
              class="dl-table__row"
            >
              <dt>{{ gettext('Post ID:') }}</dt>
              <dd>
                <button
                  type="button"
                  class="link"
                  @click.prevent="addFilters([
                    { type: 'site_name', term: `${message._source.site_name}` },
                    { type: 'post_id', term: `${message._source.post_id}` },
                  ])"
                >
                  {{ message._source.post_id }}
                </button>
              </dd>
            </div>
            <div
              v-if="message._source.uuid"
              class="dl-table__row"
            >
              <dt>{{ gettext('Doc ID:') }}</dt>
              <dd>
                {{ message._source.uuid }}
              </dd>
            </div>
          </dl>
        </details>
      </div>
      <div
        v-if="siteSourceCheck"
        class="message__metadata-onion"
      >
        <img
          title="This is a forum found on the Tor network"
          width="50"
          src="/static/images/logos/onion.png"
        >
      </div>
    </header>
    <div class="message__header message__header--forum">
      <p class="h4 u-mb-xs">
        <button
          type="button"
          class="fake-link"
          @click.prevent="addFilters([
            { type: 'site_name', term: `${message._source.site_name}` },
            { type: 'topic_id', term: `${message._source.topic_id}` },
          ])"
        >
          <span>
            <template v-if="message._source.topic">{{ message._source.topic }}</template>
            <i v-else>{{ pgettext('no post topic available', '(no topic name)') }}</i>
          </span>
        </button>
        <note-buttons
          :noteId="message._source.topic_id"
          :noteName="message._source.topic"
          :noteLinked="message._id"
          :noteItem="'Topic'"
          :hasNotes="message._source.has_topic_notes"
          :noteSiteId="message._source.site_id"
          :noteSiteName="message._source.site_name"
          :indexType="'forum'"
          :watchlistMessage="message"
          :siteLink="topicLink"
          @refresh="refresh"
        />
      </p>
      <p class="u-m-0">
        {{ pgettext('noting author of post', 'by') }}
        <button
          v-if="message._source.username"
          type="button"
          class="fake-link"
          @click.prevent="addFilters([
            { type: 'username', term: `${message._source.username}` },
          ])"
        >
          <span><b>{{ message._source.username }}</b></span>
        </button>
        <i v-else>{{ pgettext('(unknown)') }}</i>
        <note-buttons
          :noteId="message._source.user_id"
          :noteName="message._source.username"
          :noteLinked="message._id"
          :noteSiteId="message._source.site_id"
          :noteSiteName="message._source.site_name"
          :noteItem="'user'"
          :hasNotes="message._source.has_user_notes"
          :indexType="'forum'"
          :siteLink="userLink"
          :watchlistMessage="message"
          @refresh="refresh"
        />
        {{ pgettext('noting date of post', 'on') }}
        <time datetime="">
          {{ readableDate(message._source.posted_date) }}
        </time>
        <a
            class="u-pl-sm"
            :href="`/en/user/detail/${message._source.username}/${message._source.site_name}/`"
          >
            View User Details
            <svg
              class="icon u-fill-current u-fontsize-sm"
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 100 100"
            >
              <path d="M47 2L35 14l27 27-40-1H6v20h16l40-1-27 27 12 12 48-48z" />
            </svg>
          </a>
      </p>
    </div>
    <message-result-body
      messageType="forum"
      :messageSource="message"
      :indexType="'forum'"
      :siteLink="postLink"
      @toggle-post="keepMessageInView"
    />
    <message-result-details
      v-if="message._source.has_media && message._source.has_media === true"
      :docId="message._id"
      :mediaSearchUrl="mediaSearchUrl"
      :messageSource="message"
      :indexType="'forum'"
    />
  </section>
</template>
<script>
import MessageResultBody from './MessageResultBody'
import MessageResultDetails from './MessageResultDetails'
import NoteButtons from '../notes/NoteButtons'
import BookmarkButton from './BookmarkButton.vue'

export default {
  name: 'MessageResultForum',
  components: {
    BookmarkButton,
    MessageResultBody,
    MessageResultDetails,
    NoteButtons
  },
  props: {
    message: {
      type: Object,
      required: true
    },
    mediaSearchUrl: {
      type: String,
      default: ''
    },

  },
  data() {
    return {
      note: '',
      noteType: '',
      noteCategory: '',
      forumSiteLink: null,
      forumLink: null,
      topicLink: null,
      userLink: null,
      postLink: null,
      removedBookmark: false
    }
  },

  computed: {
    siteSourceCheck() {
      if (this.message._source.url) {
        if (this.message._source.url.includes('.onion')) {
          return true
        }
      } else if (this.message._source.site) {
        if (this.message._source.site.includes('.onion')) {
          return true
        }
      }
      return false
    },
  },

  mounted() {
    this.forumSiteLink = new URL('/en/message-search/forum-sites/' + this.message._source.site_name + '/', document.baseURI).href

    this.forumLink = new URL('/en/message-search/forum-sites/' + this.message._source.site_name + '/' +
        this.message._source.forum + '/', document.baseURI).href

    this.topicLink = new URL(this.getTopicLink(this.message._source), document.baseURI).href

    this.userLink = new URL('/en/message-search/?filters=site_name:' + this.message._source.site_name + ',username:' +
        this.message._source.username, document.baseURI).href

    this.postLink = new URL('/en/message-search/?filters=site_name:' + this.message._source.site_name + ',post_id:' +
        this.message._source.post_id, document.baseURI).href
  },

  methods: {
    encodeURL(topic) {
      return encodeURIComponent(topic)
    },
    getTopicLink(topic) {
      let siteName = (topic.site_name) ? 'site_name:' + topic.site_name : ''
      let topicName = (topic.topic) ? ',topic:' + this.encodeURL(topic.topic) : ''
      let topicId = (topic.topic_id) ? ',topic_id:' + topic.topic_id : ''
      return '/en/message-search/?filters=' + siteName + topicName + topicId
    },
    readableDate(timestamp) {
      let date = new Date(timestamp * 1000)
      return date.toLocaleDateString('en-GB', {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      })
    },

    addFilters(filters) {
      this.$store.commit('addFilters', filters)
    },

    refresh() {
      this.$emit('refreshed')
    },

    keepMessageInView(isShowing) {
      let messageBounds = this.$el.getBoundingClientRect()

      if (isShowing && messageBounds.top < 0) {
        let y = (window.pageYOffset + messageBounds.top) - 10
        let x = 0

        // Keep the message in view on the page when collapsing it:
        window.scrollTo({
          top: y,
          left: x,
          behavior: 'smooth'
        })
      }
    },
    bookmarkRemoved() {
      let filters = this.$store.state.filters
      if (filters.length > 0) {
        filters.forEach(filter => {
          if (filter.term === 'chat' || filter.term === 'forum') {
            this.removedBookmark = true
            this.$refs.message.addEventListener('animationend', () => {
              this.$emit('bookmarkRemoved')
            })
          }
        })
      }
    }
  },
}
</script>
<style scoped>
.hide {
  animation-name: fadeOutBookmark;
  animation-duration: 1s;
  animation-delay: 0.5s;
}

@keyframes fadeOutBookmark {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
